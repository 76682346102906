import { rejects } from "assert";

// import { read } from "fs";

var firebase = require("firebase/app");
// Add additional services that you want to use
require("firebase/firebase-auth");
require("firebase/firebase-database");
require("firebase/firebase-firestore");
require("firebase/firebase-messaging");
require("firebase/firebase-storage");
require("firebase/firebase-functions");


// core
const coreFirebaseConfig = {
    apiKey: "AIzaSyAdY57utxCBYgZGQZwJZLn2URFGB3FHSoA",
    authDomain: "tibles-core-dev.firebaseapp.com",
    projectId: "tibles-core-dev",
    storageBucket: "tibles-core-dev.appspot.com",
    messagingSenderId: "109013846370",
    appId: "1:109013846370:web:649d82c6bd32728afb043b",
    measurementId: "G-W3V0RKHXSH"
};


// blank
const blankFirebaseConfig = {
    apiKey: "AIzaSyApAtaXTgccb9Ryil9K-c-catJkDU4cVK8",
    authDomain: "blank-b168a.firebaseapp.com",
    projectId: "blank-b168a",
    storageBucket: "blank-b168a.appspot.com",
    messagingSenderId: "467402710266",
    appId: "1:467402710266:web:11b696c53041db95313d43",
    measurementId: "G-F4T4V9FMS4"
};


// Dr Seuss Dev
const drSeussFirebaseConfig = {
    apiKey: "AIzaSyAughVjMjcyP00B7OtnKsqSiL7S9Ol8dz0",
    authDomain: "tibles-dr-seuss.firebaseapp.com",
    projectId: "tibles-dr-seuss",
    storageBucket: "tibles-dr-seuss.appspot.com",
    messagingSenderId: "1077244414403",
    appId: "1:1077244414403:web:e71093cc7eb1bb32245576",
    measurementId: "G-94MGBP1QPC"
};

// Valiant Dev
const valiantDevFirebaseConfig = {
    apiKey: "AIzaSyAQLW37IoUSqOzFzXPdh7K_4DkaejYwp14",
    authDomain: "tibles-valiant-dev.firebaseapp.com",
    projectId: "tibles-valiant-dev",
    storageBucket: "tibles-valiant-dev.appspot.com",
    messagingSenderId: "777822611676",
    appId: "1:777822611676:web:3e28cec0fbb3fa958c2c19",
    measurementId: "G-T13C8G2G0G"
};


  const drSeussTestingFirebaseConfig = {
    apiKey: "AIzaSyCFjt-irspsJwJwgasIUxHOfxhQfvd6eV8",
    authDomain: "drseuss-testing.firebaseapp.com",
    projectId: "drseuss-testing",
    storageBucket: "drseuss-testing.appspot.com",
    messagingSenderId: "639858087673",
    appId: "1:639858087673:web:801b564d37f2923dfcb428"
};


export default function service(app, appId) {

    // Initialize Firebase
    if (appId ==  "com.tibles.drseuss.dev") {
        firebase.initializeApp(drSeussFirebaseConfig);
    } else if (appId ==  "com.tibles.drseuss.testing") {
        firebase.initializeApp(drSeussTestingFirebaseConfig);
    } else if (appId ==  "com.tibles.valiant.dev") {
        firebase.initializeApp(valiatnDevFirebaseConfig);
    } else if (appId ==  "com.tibles.blank") {
        firebase.initializeApp(blankFirebaseConfig);
    } else {
        firebase.initializeApp(blankFirebaseConfig);
    }
    
    var coreApp = firebase.initializeApp(coreFirebaseConfig, "core");

    var db = firebase.firestore();
    var storage = firebase.storage();
    var storageRef = storage.ref();


    // AUTH
    // core
    coreApp.auth().onAuthStateChanged( (user) => {
        if (user) {
            // User is signed in.
            console.log("Core signed in")

            let customAppToken = firebase.functions().httpsCallable('customAppToken');

            return coreApp.auth().currentUser.getIdToken()
            .then((idToken) => {
                // console.log('core token', idToken)
                return customAppToken({ token: idToken, appId : appId})
            })
            .then( (result) =>{
                // console.log("app token", result)
                return firebase.auth().signInWithCustomToken(result.data.token)
            })
            .catch(error => {
                app.ports.onAuthStateChanged.send({})
                console.log(error); 
            });

            
        } else {
            console.log("Core not signed in")
        }
    });

    firebase.auth().onAuthStateChanged( (user) => {
        if (user) {
            // User is signed in.
            console.log("App signed in")
            
            let userData = {uid: user.uid};
            
            return firebase.auth().currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                // console.log('app idTokenResult',idTokenResult)
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.admin) {
                    userData.isAdmin = true
                }

                if (!!idTokenResult.claims.editor) {
                    userData.isEditor = true
                } 

                if (!!idTokenResult.claims.moderator) {
                    userData.isModerator = true
                }
                if (!!idTokenResult.claims.email) {
                    userData.email = idTokenResult.claims.email
                }

                return userData;
                // return firebase.auth().currentUser.getIdToken()
            }).then( (userData) =>{
                // console.log("user", userData)

                app.ports.onAuthStateChanged.send(userData);
                onProfileUpdate(userData.uid);
                onAccountUpdate(userData.uid);
                onInitAppData(userData.uid)
            }).catch(error => {
                app.ports.onAuthStateChanged.send({})
                console.log(error); 
            });

            
        } else {
            unsubscribeToListeners()
            app.ports.onAuthStateChanged.send({disconnected : true});
            console.log("App not signed in.")
        }
    });


    // USER

    function onProfileUpdate(uid){
        let profileRef = db.collection('profiles').doc(uid);
        let now = firebase.firestore.Timestamp.now();


        profileRef.onSnapshot(function(change){
            
            if (change.exists) {
                let profile = change.data();
                profile.id = change.id;
                if (profile['dateCreated']) {
                    profile['dateCreated'] = Math.floor(profile.dateCreated.toMillis());
                }
                if (profile['dateLastLogIn']) {
                    profile['dateLastLogIn'] = Math.floor(profile.dateLastLogIn.toMillis());
                }
                
                console.log("Profile update");

                app.ports.onProfileChanged.send(profile);
            } else {
                console.log("No profile document! Let's create one.");
                profileRef.set({dateLastLogin : now, dateCreated : now})
            }
        })
    };


    function onAccountUpdate(uid){
        let accountRef = db.collection('accounts').doc(uid);
        let now = firebase.firestore.Timestamp.now();
        
        accountRef.onSnapshot(function(change){
            
            if (change.exists) {
                console.log('account exists')
                let account = change.data();
                account.id = change.id;
                if (account['dateCreated']) {
                    account['dateCreated'] = Math.floor(account.dateCreated.toMillis());
                }
                
                console.log("Account update");

                app.ports.onAccountChanged.send(account);
            } else {
                console.log("No account document! Let's create one.");
                accountRef.set({credits: 0, dateCreated : now})
            }
        })
    };


    var unsubSetsUpdate;
    var unsubAnnouncementUpdate;
    var unsubPrintsUpdate;
    var unsubPacksUpdate;
    var unsubStacksUpdate;
    var unsubWallUpdate;
    var unsubVariantsUpdate;


    function unsubscribeToListeners() {
        if (unsubSetsUpdate) { unsubSetsUpdate() }
        if (unsubAnnouncementUpdate) { unsubAnnouncementUpdate() }
        if (unsubPacksUpdate) { unsubPacksUpdate() }
        if (unsubPrintsUpdate) { unsubPrintsUpdate() }
        if (unsubStacksUpdate) { unsubStacksUpdate() }
        if (unsubWallUpdate) { unsubWallUpdate() }
        if (unsubVariantsUpdate) { unsubVariantsUpdate() }
    }   

    function onInitAppData(userId) {
        unsubscribeToListeners();

        unsubSetsUpdate = onSetsUpdate();
        // unsubPacksUpdate = onPacksUpdate();
        unsubPrintsUpdate = onPrintsUpdate(userId)
        unsubStacksUpdate = onStacksUpdate(userId)
        // unsubAnnouncementUpdate = onAnnouncementUpdate(appId);
        unsubWallUpdate = onWallUpdate(userId);
        console.log("Signed with proper credentials, listening to data");
    }


    app.ports.fetchSignInMethodsForEmail.subscribe(function(email){
        coreApp.auth()
            .fetchSignInMethodsForEmail(email)
            .then(function(providers){
                app.ports.gotSignInMethods.send({methods : providers});
                // console.log(providers)
            })
    });


    app.ports.signInWithEmailAndPassword.subscribe(function(data){
        var email = data.email
        var password = data.password

        coreApp.auth()
        .signInWithEmailAndPassword(email, password)
        .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            var now = firebase.firestore.Timestamp.now().toMillis();
            if (errorCode === 'auth/wrong-password') {
                app.ports.onGotAlert.send({ message : errorMessage, kind: 'error', inputField: "password", dateCreated: now});
            } else {
                app.ports.onGotAlert.send({ message :errorMessage, kind: 'error', inputField: "", dateCreated: now});
            }
            console.log(error);
        });
    });

    app.ports.sendPasswordResetEmail.subscribe(function(email){
        let now = firebase.firestore.Timestamp.now().toMillis();

        coreApp.auth().sendPasswordResetEmail(
            email)
            .then(function() {
                app.ports.onGotAlert.send({message :"Password reset email sent", kind: 'info', dateCreated: now});
              // Password reset email sent.
            })
            .catch(function(error) {
                app.ports.onGotAlert.send({message : error.message, kind: 'error', dateCreated: now});
                console.log(error);
            });
    });
   
    app.ports.createUserWithEmailAndPassword.subscribe(function(data){
        var email = data.email
        var password = data.password
        
        coreApp.auth().createUserWithEmailAndPassword(email, password)
            .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        var now = firebase.firestore.Timestamp.now().toMillis();
        
        if (errorCode === 'auth/weak-password') {
            app.ports.onGotAlert.send({ message :errorMessage, kind: 'error', inputField: "password", dateCreated: now});
        } else {
            app.ports.onGotAlert.send({ message :errorMessage, kind: 'error', inputField: "", dateCreated: now});
        }
        console.log(error);
        });
    });

    app.ports.signOut.subscribe(function(email) {
        coreApp.auth().signOut().then(function() {
            // localStorage.removeItem(storageKey);
            console.log('Successfully logout of core!')
        })
        firebase.auth().signOut().then(function() {
            console.log('Successfully logout!')
        })
    });

    
    
    // SET / ITEM / PRINT


    app.ports.buy.subscribe( (data) => {

        data.appId = appId;

        var buy = firebase.functions().httpsCallable('buy');

        buy(data)
            .then( (result) => {
                let prints = []
                result.data.prints.forEach( print => {
                    // Must do this so comply with the encoder,
                    // some non-standard data being received, luckily nothing important, I think
                    print.id = print.printId;
                    print.userId = "";
                    print.variantRef = { id : "", path : ""}
                    print.dateAcquired = 0;
                    print.dateCreated = 0;
                    prints.push(print)
                })
                app.ports.onNewPrints.send(prints);
                // console.log(prints)
            })
            .catch( (error) => {
                // Getting the Error details.
                var code = error.code;
                var errorMessage = error.message;
                // var details = error.details;
                var msg = code + ": " + errorMessage 
                console.log(msg);
                var now = firebase.firestore.Timestamp.now().toMillis();
                app.ports.onGotAlert.send({ message : msg, kind: 'error', inputField: "", dateCreated: now});
        });
    });

    app.ports.getRoomMessages.subscribe( (data) => {
        
        db.collection('chatRooms')
            .doc(data.id)
            .collection("messages")
            .orderBy("dateCreated", "desc").limit(data.limit)
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    // console.log(change.doc.data())
                    let doc = change.doc;
                    let message = doc.data()
                    message.id = doc.id;
                    message.createdAt = Math.round(message.dateCreated.toMillis());
                    let result = { chatId : data.id, message : message };
                    // console.log(result);
                    app.ports.onGotChatMessage.send(result);
                })
            });
    });


    function onPrintsUpdate( uid ){
        console.log("onPrintsUpdate");

        return db.collection('prints')
            .where('ownerId', '==', uid)
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {

                    let doc = change.doc;

                    if (change.type == "removed") {
                        console.log("Remove print ", doc.id);
                        app.ports.onPrintRemoved.send(doc.id);
                    } else {
                        let print = change.doc.data();
                        print['id'] = change.doc.id;
                    

                        if (print['dateAcquired']) {
                            print['dateAcquired'] = Math.floor(print.dateAcquired.toMillis());
                        }
                        if (print['dateCreated']) {
                            print['dateCreated'] = Math.floor(print.dateCreated.toMillis());
                        }
                        
                        app.ports.onGotPrint.send(print);
                        }
                })
                
            })
    }


    function getRooms(){
        console.log("onGetRooms");
        
        db.collection('chatRooms')
            .get().then( (querySnapshot) => {
                querySnapshot.forEach( (doc) => {
                    let room = doc.data();
                    room['id'] = doc.id;
                    app.ports.onGotRoom.send(room);
                })
            })
    };
    
    

    function onSetsUpdate( ){
        console.log("onSetsUpdate");

        return db.collection('sets')
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {

                    let doc = change.doc;

                    if (change.type == "removed") {
                        console.log("Removed set ", doc.id);
                        app.ports.onSetRemoved.send(doc.id);
                    } else {
                        let set = doc.data();
                        set.id = doc.id;
                        set.path = doc.ref.path;
                        if (set['datePublished']) {
                            set['datePublished'] = Math.floor(set.datePublished.toMillis());
                        }
                        console.log("set updated", set.title)
                        app.ports.onSetChanged.send(set);
                        }
                })
                
            })
    }


    function onPacksUpdate(){
        console.log("onPackUpdate");

        return db.collection('packs')
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    let doc = change.doc;
                    let pack = doc.data();
                    pack['id'] = doc.id;
                    if (pack['dateCreated']) {
                        pack['dateCreated'] = Math.floor(pack['dateCreated'].toMillis())
                    }
                    if (pack['datePublished']) {
                        pack['datePublished'] = Math.floor(pack['datePublished'].toMillis())
                    }
                    app.ports.onGotPack.send(pack);
                    

                })
            });
    };


    function onAnnouncementUpdate(appId){
        console.log("onAnnouncementUpdate");
  
        return db.collection('announcements')
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    // console.log(change)
                    let doc = change.doc;
                    if (change.type == "removed") {
                        console.log("Remove announcement", doc.id);
                        app.ports.onAnnouncementRemoved.send(doc.id);
                    } else {
                        doc = change.doc;
                        let announcement = doc.data();
                        announcement['id'] = doc.id;
                        if (announcement['datePublished']) {
                            announcement['datePublished'] = Math.floor(announcement['datePublished'].toMillis());
                        }
                        // console.log(announcement);
                        app.ports.onAnnouncementUpdated.send(announcement);
                    }
                    
                })
            })
    };




    // function onSummaryUpdate(uid){
        
        
    //     db.collection('profiles/'+ uid + '/collections/').doc(appId)
    //         .onSnapshot(function(change){
    //             if (change.exists) {
    //                 console.log("Summary updated!");
    //                 app.ports.onGotSummary.send(change.data());
    //                 // console.log("summaryData", change.data());
    //             } else {
    //                 console.log("No profiles/collections document!");
    //                 // app.ports.onProfileChanged.send({error: "Error"});
    //             }
    //         });
    // };

    app.ports.removeStack.subscribe( (id) =>{
        
        if (id != "") {
            db.collection("stacks").doc(id).delete()
            .then(function(){
                console.log("Document successfully deleted!");
            })
            .catch(function(){
                console.error("Error removing document: ", error);
            })
        }
    });

    app.ports.updateStack.subscribe( (data) => {
        
        
        var printRefs = [];
        data.printRefs.forEach(function(ref){
            let printRef = db.doc(ref.path);
            printRefs.push(printRef);
        }); 

        if (data.id !== "") {
            db.collection("stacks")
            .doc(data.id)
            .update({ printRefs : printRefs,
                      title : data.title,
                      order : data.order
            })
            .then(function(){
                console.log("stacks update: ", data.id);
            })
            .catch(function(error){
                console.error("Error updating document: ", error);
            }); 
        } else {
            let userId = firebase.auth().currentUser.uid;
            let newStack = 
                { printRefs : printRefs,
                    title : data.title,
                    order : data.order,
                    userId : userId
                }

            db.collection("stacks")
              .add(newStack)
              .then(function(){
                console.log("created stack ", data.title);
              })
              .catch(function(error){
                console.error("Error creating stack: ", error);
            }); 
        }
        

    });

    app.ports.updateWallVisiblePrintNumber.subscribe((data) => {
        
        console.log(data)
        db.collection("walls")
            .doc(data.id)
            .update({ idsOfVisiblePrintNumbers : data.visibleIds})
            .then(function(){
                console.log("document update: ", data.id);
            })
            .catch(function(error){
                console.error("Error updating document: ", error);
            });
        
    });

    app.ports.updateWall.subscribe((data) => {
        
        
        var printRefs = [];
        data.printRefs.forEach(function(ref){
            printRefs.push(firebase.firestore().doc(ref));
        });

        db.collection("walls")
            .doc(data.id)
            .update({ printRefs : printRefs})
            .then(function(){
                console.log("document update: ", data.id);
            })
            .catch(function(error){
                console.error("Error updating document: ", error);
            });
        // 
    });

    // app.ports.validatePurchase.subscribe( (data) => {
    //     var validateIAP = firebase.functions().httpsCallable('validateIAP');

    //     console.log(data);

    //     validateIAP(data)
    //         .then( (result) => {
    //             // Read result of the Cloud Function.
    //             console.log(result);
    //             console.log(result.data)
                    // let message = "Thank you for your purchase. Check your coins"
                    // app.ports.onGotAlert.send({ message : message, kind: 'info', inputField: ""})
    //             // app.ports.onPurchaseValidated.send({success :'ok', msg: 'Valid package'});
                
    //         })
    //         .catch( (error) => {
    //             // Getting the Error details.
    //             var code = error.code;
    //             var message = error.message;
    //             // var details = error.details;
    //             var msg = code + ": " + message 
    //             console.log(msg);
                    // app.ports.onGotAlert.send({ message : message, kind: 'info', inputField: ""})
    //     });
        
    // });
    
    function onStacksUpdate(uid){
        
        
        db.collection("stacks")
            .where('userId', '==', uid)
            .onSnapshot(function(snapshot){
                if (!snapshot.size) {
                    console.log("No stacks document!");
                } else {
                    
                    var stacks = [];
                    snapshot.docChanges().forEach(function(change) {
                        var promises = [];
                        console.log(change.type);
                        if (change.type === 'removed') {
                            console.log('removed');
                            app.ports.onRemoveStack.send(change.doc.id);
                          } else {
                            let stack = change.doc.data();
                            stack.id = change.doc.id;
                            app.ports.onStacksChanged.send(stack);

                          }

                    });
                    
                }
            });
    };

// THIS NEEDS REFACT
    function onWallUpdate(uid){
        

        db.collection('walls')
            .where('userId', '==', uid)
            .onSnapshot(function(snapshot){
                if (!snapshot.size) {
                    console.log("No walls document!");
                } else {
                    // var promises = [];
                    snapshot.docChanges().forEach(function(change) {
                        
                        if (change.type === 'removed') {
                            console.log('removed');
                            // TODO remove all

                          } else {


                            let wall = change.doc.data();
                            wall.id = change.doc.id;
                            // console.log("wall", wall)
                            app.ports.onGotWall.send(wall);
                            
                          }
                    });
                }
            })
    }

    // function getItemPrintByRef(printRef, promises){
    //     let print = {};
    //     let itemId = printRef.parent.parent.id;
    //     let setId = printRef.parent.parent.parent.parent.id;
        
    //     getPrintData(printRef, print, promises);
    //     print['setId'] = setId;
    //     print['itemId'] = itemId;

    //     return print;
    // }

    // function getPrintItemByRef(printRef, promises){
    //     let print = {};
    //     getPrintData(printRef, print, promises);
    //     getItemData(printRef, print, promises)
    //     return print;
    // }

    // function getPrintData(printRef, print, promises){
    //     let p = printRef.get();
    //     p.then( function(snapshot) {
    //             let doc = snapshot.data();
    //             print.id = snapshot.id;
    //             print.printNumber = doc.printNumber;
    //             print.path = printRef.path;
    //             print.userId = doc.userId;
    //             print.acquiredAt = Math.round(doc.dateAcquired.toMillis());
    //     });
    //     promises.push(p);
    // }

    // function getItemData(printRef, print, promises){
    //     let itemRef = printRef.parent.parent;
    //     let p = itemRef.get();
    //     p.then( function(doc) {
    //             let itemData = doc.data();
    //             print.image = itemData.imageUrlCDN;
    //             print.title = itemData.title;
    //     });
    //     promises.push(p);
    // }


    function buy(packId){
        let buyUsingRedis = firebase.functions().httpsCallable('buyUsingRedis');

        buyUsingRedis({packId: packId})
        .then( data =>{
            console.log("redis buyUsingRedis ", data)
        })
        .catch( (error) => {
            console.log("Error:", "buyUsingRedis", error)
        })
    }

    

    function helloHTTP(name){
        let helloHTTP = firebase.functions().httpsCallable('HelloHTTP');
        let data = {name: name};

        helloHTTP(data)
        .then( results =>{
            console.log("helloHTTP ", results)
        })
        .catch( (error) => {
            console.log("Error:", "helloHTTP", error)
        })
    }
    

}