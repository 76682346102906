(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bt.as === region.bN.as)
	{
		return 'on line ' + region.bt.as;
	}
	return 'on lines ' + region.bt.as + ' through ' + region.bN.as;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dn,
		impl.eh,
		impl.ed,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		b4: func(record.b4),
		eb: record.eb,
		dT: record.dT
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.b4;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.eb;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dT) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dn,
		impl.eh,
		impl.ed,
		function(sendToApp, initialModel) {
			var view = impl.el;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dn,
		impl.eh,
		impl.ed,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.br && impl.br(sendToApp)
			var view = impl.el;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.a1);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ef) && (_VirtualDom_doc.title = title = doc.ef);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dL;
	var onUrlRequest = impl.dM;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		br: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ci === next.ci
							&& curr.bW === next.bW
							&& curr.cc.a === next.cc.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dn: function(flags)
		{
			return A3(impl.dn, flags, _Browser_getUrl(), key);
		},
		el: impl.el,
		eh: impl.eh,
		ed: impl.ed
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { df: 'hidden', cV: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { df: 'mozHidden', cV: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { df: 'msHidden', cV: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { df: 'webkitHidden', cV: 'webkitvisibilitychange' }
		: { df: 'hidden', cV: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cr: _Browser_getScene(),
		em: {
			cK: _Browser_window.pageXOffset,
			eo: _Browser_window.pageYOffset,
			bz: _Browser_doc.documentElement.clientWidth,
			a9: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		bz: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		a9: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cr: {
				bz: node.scrollWidth,
				a9: node.scrollHeight
			},
			em: {
				cK: node.scrollLeft,
				eo: node.scrollTop,
				bz: node.clientWidth,
				a9: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cr: _Browser_getScene(),
			em: {
				cK: x,
				eo: y,
				bz: _Browser_doc.documentElement.clientWidth,
				a9: _Browser_doc.documentElement.clientHeight
			},
			c9: {
				cK: x + rect.left,
				eo: y + rect.top,
				bz: rect.width,
				a9: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.dG) { flags += 'm'; }
	if (options.cU) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}var $author$project$Msg$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Msg$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.i) : builder.i;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{i: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bR: fragment, bW: host, ca: path, cc: port_, ci: protocol, cj: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Msg$AdjustTimeZone = function (a) {
	return {$: 2, a: a};
};
var $author$project$Msg$GotViewport = function (a) {
	return {$: 28, a: a};
};
var $author$project$Type$Session$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Page$LoadingPage = {$: 10};
var $author$project$Route$NotFound = {$: 12};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Type$Flags$initSeedStorage = F3(
	function (num, appId, storage) {
		return {bC: appId, aK: storage, d3: num};
	});
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Type$Flags$LocalStorage = function (email) {
	return {da: email};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Type$Flags$localStorageDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'email',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Type$Flags$LocalStorage));
var $author$project$Type$Flags$decodeMaybeStorage = function (maybeStorage) {
	if (!maybeStorage.$) {
		var storage = maybeStorage.a;
		var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$Type$Flags$localStorageDecoder, storage);
		if (!_v1.$) {
			var result = _v1.a;
			return $elm$json$Json$Decode$succeed(
				$elm$core$Maybe$Just(result));
		} else {
			return $elm$json$Json$Decode$fail('It failed');
		}
	} else {
		return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
	}
};
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Type$Flags$convertFlagsToSeedStorage = function (flags) {
	return A4(
		$elm$json$Json$Decode$map3,
		$author$project$Type$Flags$initSeedStorage,
		$elm$json$Json$Decode$succeed(flags.d3),
		$elm$json$Json$Decode$succeed(flags.bC),
		$author$project$Type$Flags$decodeMaybeStorage(flags.cA));
};
var $author$project$Type$Flags$Flags = F3(
	function (seed, storage, appId) {
		return {bC: appId, d3: seed, cA: storage};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Type$Flags$flagsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'appId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'storage',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'seed',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Type$Flags$Flags))));
var $author$project$Type$Flags$seedStoreDecoder = A2($elm$json$Json$Decode$andThen, $author$project$Type$Flags$convertFlagsToSeedStorage, $author$project$Type$Flags$flagsDecoder);
var $author$project$Type$Flags$decodeSeedStore = function (data) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Flags$seedStoreDecoder, data);
	if (!_v0.$) {
		var result = _v0.a;
		return result;
	} else {
		return A3($author$project$Type$Flags$initSeedStorage, 0, '', $elm$core$Maybe$Nothing);
	}
};
var $author$project$Type$Screen$Small = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Type$Screen$defaultSize = A2(
	$author$project$Type$Screen$Small,
	_Utils_Tuple2(320, 568),
	0);
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$Route$Clubhouse = {$: 6};
var $author$project$Msg$ClubhouseMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Type$Page$ClubhousePage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Route$Collection = {$: 5};
var $author$project$Msg$CollectionMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Type$Page$CollectionPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Type$Page$Error404Page = {$: 11};
var $author$project$Route$GetStarted = {$: 1};
var $author$project$Msg$GetStartedMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Type$Page$GetStartedPage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Session$Guest = function (a) {
	return {$: 1, a: a};
};
var $author$project$Route$Home = {$: 0};
var $author$project$Msg$HomeMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Type$Page$HomePage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Route$Market = {$: 8};
var $author$project$Msg$MarketMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Type$Page$MarketPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Route$Message = {$: 10};
var $author$project$Msg$MessageMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Type$Page$MessagePage = function (a) {
	return {$: 8, a: a};
};
var $author$project$Route$Profile = {$: 3};
var $author$project$Msg$ProfileMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Type$Page$ProfilePage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Room = function (a) {
	return {$: 7, a: a};
};
var $author$project$Msg$RoomMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Type$Page$RoomPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Route$SetPacks = function (a) {
	return {$: 11, a: a};
};
var $author$project$Msg$SetPacksMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Type$Page$SetPacksPage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Route$Stack = function (a) {
	return {$: 4, a: a};
};
var $author$project$Msg$StackMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Type$Page$StackPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Clubhouse$Room = F5(
	function (id, title, description, order, messages) {
		return {c5: description, di: id, dD: messages, bl: order, ef: title};
	});
var $author$project$Type$Clubhouse$emptyRoom = A5($author$project$Type$Clubhouse$Room, '', '', '', 0, _List_Nil);
var $author$project$Type$Stack$Stack = F4(
	function (id, order, printRefs, title) {
		return {di: id, bl: order, dX: printRefs, ef: title};
	});
var $author$project$Type$Stack$emptyStack = A4($author$project$Type$Stack$Stack, '', 0, _List_Nil, 'New Stack');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Type$Stack$fromList = F2(
	function (id, stacks) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Type$Stack$emptyStack,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (d) {
						return _Utils_eq(d.di, id);
					},
					stacks)));
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Type$Session$params = function (session) {
	switch (session.$) {
		case 3:
			var parameters = session.a;
			return parameters;
		case 2:
			var parameters = session.a;
			return parameters;
		case 1:
			var parameters = session.a;
			return parameters;
		default:
			var parameters = session.a;
			return parameters;
	}
};
var $author$project$Type$Session$screenSize = function (session) {
	return $author$project$Type$Session$params(session).cs;
};
var $author$project$Page$Clubhouse$init = F2(
	function (session, rooms) {
		var model = {
			dR: false,
			bo: rooms,
			cs: $author$project$Type$Session$screenSize(session),
			d5: session,
			ef: 'Clubhouse'
		};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Type$Filters$SetsView = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Filters$ByDateAcquired = 4;
var $author$project$Type$Filters$GridView = 0;
var $author$project$Type$Filters$defaultItemOptions = {aS: 4, aZ: 0};
var $author$project$Type$Filters$ByPublishDate = 0;
var $author$project$Type$Filters$defaultSetOptions = {cw: false, aS: 0, aZ: 0};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $author$project$Type$Tible$Tible = F4(
	function (print, variant, item, setId) {
		return {aq: item, dU: print, d7: setId, by: variant};
	});
var $author$project$Type$Tible$fromPrint = F2(
	function (print, set) {
		var maybeVariant = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (v) {
					return _Utils_eq(v.cI, print.cI);
				},
				set.ek));
		var maybeItem = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (i) {
					return _Utils_eq(i.du, print.du);
				},
				set.bd));
		var _v0 = _Utils_Tuple2(maybeItem, maybeVariant);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var item = _v0.a.a;
			var variant = _v0.b.a;
			return $elm$core$Maybe$Just(
				A4($author$project$Type$Tible$Tible, print, variant, item, set.di));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Type$Tible$toDict = F2(
	function (printDict, setDict) {
		return $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (x) {
					return _Utils_Tuple2(x.dU.di, x);
				},
				A2(
					$elm$core$List$filterMap,
					function (x) {
						return x;
					},
					A2(
						$elm$core$List$map,
						function (_v0) {
							var maybeSet = _v0.a;
							var print = _v0.b;
							return A2(
								$elm$core$Maybe$andThen,
								$author$project$Type$Tible$fromPrint(print),
								maybeSet);
						},
						A2(
							$elm$core$List$map,
							function (p) {
								return _Utils_Tuple2(
									A2($elm$core$Dict$get, p.d7, setDict),
									p);
							},
							$elm$core$Dict$values(printDict))))));
	});
var $author$project$View$Collection$init = F3(
	function (session, setDict, printDict) {
		var variantFilters = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (x) {
					return _Utils_Tuple2(x, '');
				},
				$elm$core$Dict$keys(setDict)));
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var model = {
			bH: _List_Nil,
			b$: $author$project$Type$Filters$defaultItemOptions,
			dV: printDict,
			d2: '',
			d5: session,
			d6: setDict,
			cv: $author$project$Type$Filters$defaultSetOptions,
			bv: tibleDict,
			ah: variantFilters,
			cJ: $author$project$Type$Filters$SetsView($author$project$Type$Filters$defaultSetOptions)
		};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$View$Filters$init = function () {
	var model = {
		b$: $author$project$Type$Filters$defaultItemOptions,
		cv: $author$project$Type$Filters$defaultSetOptions,
		cJ: $author$project$Type$Filters$SetsView($author$project$Type$Filters$defaultSetOptions)
	};
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
}();
var $author$project$Page$Collection$init = F3(
	function (session, setDict, printDict) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var _v0 = A3($author$project$View$Collection$init, session, setDict, printDict);
		var collectionViewModel = _v0.a;
		var model = {
			g: collectionViewModel,
			ao: $author$project$View$Filters$init.a,
			Z: false,
			_: false,
			z: $elm$core$Maybe$Nothing,
			dV: printDict,
			cs: $author$project$Type$Session$screenSize(session),
			F: '',
			d5: session,
			d6: setDict,
			bv: tibleDict,
			ef: 'My Collection'
		};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$GetStarted$EnterEmail = 0;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dm: index, dv: match, dJ: number, ec: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$never = _Regex_never;
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{cU: true, dG: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $author$project$Type$Session$maybeLocalStorage = function (session) {
	switch (session.$) {
		case 3:
			var parameters = session.a;
			return parameters.aK;
		case 2:
			var parameters = session.a;
			return parameters.aK;
		case 1:
			var parameters = session.a;
			return parameters.aK;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$Session$localStorageEmail = function (session) {
	var _v0 = $author$project$Type$Session$maybeLocalStorage(session);
	if (!_v0.$) {
		var localStorage = _v0.a;
		return localStorage.da;
	} else {
		return '';
	}
};
var $elm$core$Basics$not = _Basics_not;
var $author$project$Page$GetStarted$init = function (session) {
	var screenSize = $author$project$Type$Session$screenSize(session);
	var email = $author$project$Type$Session$localStorageEmail(session);
	return _Utils_Tuple2(
		{
			cQ: _List_Nil,
			da: email,
			R: $elm$core$Maybe$Nothing,
			q: '',
			cs: screenSize,
			ct: !$rtfeldman$elm_validate$Validate$isValidEmail(email),
			d5: session,
			aT: 0,
			ef: 'Get Started | tĭbles'
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Home$Stacks$updateStacksContent = F2(
	function (stacks, model) {
		return _Utils_update(
			model,
			{d8: stacks});
	});
var $author$project$Page$Home$Stacks$init = F4(
	function (session, setDict, printDict, stacks) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var initModel = {
			am: $elm$core$Maybe$Nothing,
			at: 4,
			t: $elm$core$Maybe$Nothing,
			dV: printDict,
			cs: $author$project$Type$Session$screenSize(session),
			d5: session,
			d6: setDict,
			cy: _List_Nil,
			d8: _List_Nil,
			bv: tibleDict,
			ef: 'Stacks'
		};
		var model = A2($author$project$Page$Home$Stacks$updateStacksContent, stacks, initModel);
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Home$Wall$AdjustTimeZone = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ox$Theme$backgroundWallImage = 'https://firebasestorage.googleapis.com/v0/b/collecting-club.appspot.com/o/apps%2Fdrseuss%2Fwall-background-1.png?alt=media&token=b69014d6-51d5-44c7-b88f-d14d4ee066d5';
var $norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging = {$: 0};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$init = $norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging;
var $author$project$Type$Sortable$Tible = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Home$Wall$updateWallContent = F2(
	function (wall, model) {
		var wallTibles = A2(
			$elm$core$List$filterMap,
			function (p) {
				return p;
			},
			A2(
				$elm$core$List$map,
				function (p) {
					return A2($elm$core$Dict$get, p.di, model.bv);
				},
				wall.dX));
		var newSortables = A2(
			$elm$core$List$map,
			function (t) {
				return $author$project$Type$Sortable$Tible(t);
			},
			wallTibles);
		return _Utils_update(
			model,
			{
				w: wall.w,
				ab: $elm$core$Maybe$Just(wall.di),
				dX: wall.dX,
				cy: newSortables,
				aD: wallTibles
			});
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Home$Wall$init = F4(
	function (session, setDict, printDict, maybeWall) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var initModel = {
			an: $norpan$elm_html5_drag_drop$Html5$DragDrop$init,
			w: _List_Nil,
			z: $elm$core$Maybe$Nothing,
			ab: $elm$core$Maybe$Nothing,
			ad: $elm$core$Maybe$Nothing,
			dV: printDict,
			dX: _List_Nil,
			az: 0,
			cs: $author$project$Type$Session$screenSize(session),
			d5: session,
			d6: setDict,
			cy: _List_Nil,
			bv: tibleDict,
			aD: $elm$core$Dict$values(tibleDict),
			ef: 'Wall',
			aH: 'url(' + ($author$project$Ox$Theme$backgroundWallImage + ')'),
			cO: $elm$time$Time$utc
		};
		var model = function () {
			if (!maybeWall.$) {
				var wall = maybeWall.a;
				return A2($author$project$Page$Home$Wall$updateWallContent, wall, initModel);
			} else {
				return initModel;
			}
		}();
		return _Utils_Tuple2(
			model,
			A2($elm$core$Task$perform, $author$project$Page$Home$Wall$AdjustTimeZone, $elm$time$Time$here));
	});
var $author$project$Page$Home$init = F5(
	function (session, setDict, maybeWall, stacks, printDict) {
		var pageModel = false;
		var _v0 = A4($author$project$Page$Home$Wall$init, session, setDict, printDict, maybeWall);
		var wallSectionModel = _v0.a;
		var _v1 = A4($author$project$Page$Home$Stacks$init, session, setDict, printDict, stacks);
		var stackSectionModel = _v1.a;
		return _Utils_Tuple2(
			{
				z: $elm$core$Maybe$Nothing,
				dR: pageModel,
				dV: printDict,
				cs: $author$project$Type$Session$screenSize(session),
				d5: session,
				d6: setDict,
				G: stackSectionModel,
				ef: 'My Room',
				C: wallSectionModel
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Market$init = F4(
	function (session, packs, setDict, printDict) {
		var model = {
			dP: packs,
			dR: false,
			dV: printDict,
			cs: $author$project$Type$Session$screenSize(session),
			d5: session,
			d6: setDict,
			ef: 'Shop'
		};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Message$init = F2(
	function (session, chats) {
		var model = {a3: chats, dR: false, d5: session, ef: 'Messages'};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Profile$init = function (session) {
	var maybeProfile = function () {
		if (session.$ === 3) {
			var user = session.b;
			return user.dz;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	return _Utils_Tuple2(
		{
			dz: maybeProfile,
			dR: false,
			cs: $author$project$Type$Session$screenSize(session),
			d5: session,
			ef: 'Profile'
		},
		$elm$core$Platform$Cmd$none);
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Type$Clubhouse$getRoomEncoder = F2(
	function (chatId, limit) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(chatId)),
					_Utils_Tuple2(
					'limit',
					$elm$json$Json$Encode$int(limit))
				]));
	});
var $author$project$Firebase$getRoomMessages = _Platform_outgoingPort('getRoomMessages', $elm$core$Basics$identity);
var $author$project$Page$Room$init = F3(
	function (session, id, room) {
		var pageModel = false;
		var model = {di: id, dR: pageModel, bn: room, d5: session, ef: 'Room'};
		return _Utils_Tuple2(
			model,
			$author$project$Firebase$getRoomMessages(
				A2($author$project$Type$Clubhouse$getRoomEncoder, id, 3)));
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Type$Pack$isInSet = F2(
	function (setId, pack) {
		return A2(
			$elm$core$List$any,
			function (sr) {
				return _Utils_eq(sr.di, setId);
			},
			pack.aR);
	});
var $author$project$Page$SetPacks$init = F6(
	function (session, setId, maybeSet, packs, setDict, printDict) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var setPacks = A2(
			$elm$core$List$filter,
			$author$project$Type$Pack$isInSet(setId),
			packs);
		var model = {
			D: $elm$core$Maybe$Nothing,
			bg: maybeSet,
			dP: packs,
			dR: false,
			dV: printDict,
			cs: $author$project$Type$Session$screenSize(session),
			d5: session,
			d6: setDict,
			d7: setId,
			bp: setPacks,
			bv: tibleDict,
			ef: 'Lucky Packs'
		};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$EditableField = F3(
	function (text, originalText, isInEditMode) {
		return {ba: isInEditMode, bm: originalText, H: text};
	});
var $author$project$Page$Stack$init = F4(
	function (session, setDict, printDict, stack) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var tibles = A2(
			$elm$core$List$filterMap,
			function (i) {
				return i;
			},
			A2(
				$elm$core$List$map,
				function (pr) {
					return A2($elm$core$Dict$get, pr.di, tibleDict);
				},
				stack.dX));
		var newSortables = A2(
			$elm$core$List$map,
			function (t) {
				return $author$project$Type$Sortable$Tible(t);
			},
			tibles);
		var newEditableTitle = A3($author$project$Page$EditableField, stack.ef, stack.ef, false);
		return _Utils_Tuple2(
			{
				an: $norpan$elm_html5_drag_drop$Html5$DragDrop$init,
				aI: newEditableTitle,
				di: stack.di,
				x: $elm$core$Maybe$Nothing,
				aa: $elm$core$Maybe$Nothing,
				aL: $elm$core$Maybe$Nothing,
				bl: stack.bl,
				dR: false,
				dV: printDict,
				cs: $author$project$Type$Session$screenSize(session),
				u: _List_Nil,
				d5: session,
				d6: setDict,
				cy: newSortables,
				v: stack,
				aB: stack.ef,
				bv: tibleDict,
				aD: tibles,
				ef: 'Stack'
			},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Type$Session$navKey = function (session) {
	return $author$project$Type$Session$params(session).b6;
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$routeToString = function (page) {
	var pieces = function () {
		switch (page.$) {
			case 0:
				return _List_Nil;
			case 1:
				return _List_fromArray(
					['get-started']);
			case 2:
				return _List_fromArray(
					['signout']);
			case 3:
				return _List_fromArray(
					['profile']);
			case 4:
				var id = page.a;
				return _List_fromArray(
					['stack', id]);
			case 5:
				return _List_fromArray(
					['collection']);
			case 6:
				return _List_fromArray(
					['clubhouse']);
			case 8:
				return _List_fromArray(
					['shop']);
			case 7:
				var id = page.a;
				return _List_fromArray(
					['room', id]);
			case 9:
				var id = page.a;
				return _List_fromArray(
					['chat', id]);
			case 10:
				return _List_fromArray(
					['messages']);
			case 11:
				var id = page.a;
				return _List_fromArray(
					['set', id, 'packs']);
			default:
				return _List_fromArray(
					['404']);
		}
	}();
	return '/' + A2($elm$core$String$join, '/', pieces);
};
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Firebase$signOut = _Platform_outgoingPort('signOut', $elm$json$Json$Encode$string);
var $author$project$Firebase$signout = $elm$core$Platform$Cmd$batch(
	_List_fromArray(
		[
			$author$project$Firebase$signOut('')
		]));
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Type$Session$Partial = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Type$Session$SignedIn = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Type$Session$updateParams = F2(
	function (session, parameters) {
		switch (session.$) {
			case 3:
				var user = session.b;
				return A2($author$project$Type$Session$SignedIn, parameters, user);
			case 2:
				var user = session.b;
				return A2($author$project$Type$Session$Partial, parameters, user);
			case 1:
				return $author$project$Type$Session$Guest(parameters);
			default:
				return $author$project$Type$Session$Loading(parameters);
		}
	});
var $author$project$Update$pageForRoute = F2(
	function (model, maybeRoute) {
		var params = $author$project$Type$Session$params(model.d5);
		var updateGenericPage = F4(
			function (init, route, page, msg) {
				var newParams = _Utils_update(
					params,
					{d0: route});
				var newSession = A2($author$project$Type$Session$updateParams, model.d5, newParams);
				var _v18 = init(newSession);
				var subModel = _v18.a;
				var subCmd = _v18.b;
				var newModel = _Utils_update(
					model,
					{
						dQ: page(subModel),
						d5: newSession
					});
				return _Utils_Tuple2(
					newModel,
					A2($elm$core$Platform$Cmd$map, msg, subCmd));
			});
		var updateSession = function (route) {
			return A2(
				$author$project$Type$Session$updateParams,
				model.d5,
				_Utils_update(
					params,
					{d0: route}));
		};
		_v0$11:
		while (true) {
			if (!maybeRoute.$) {
				switch (maybeRoute.a.$) {
					case 0:
						var _v1 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$Home);
						var _v2 = A5($author$project$Page$Home$init, newSession, model.d6, model.dC, model.d8, model.dV);
						var subModel = _v2.a;
						var subCmd = _v2.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$HomePage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$HomeMsg, subCmd));
					case 4:
						var id = maybeRoute.a.a;
						var stack = A2($author$project$Type$Stack$fromList, id, model.d8);
						var newSession = updateSession(
							$author$project$Route$Stack(id));
						var _v3 = A4($author$project$Page$Stack$init, newSession, model.d6, model.dV, stack);
						var subModel = _v3.a;
						var subCmd = _v3.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$StackPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$StackMsg, subCmd));
					case 5:
						var _v4 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$Collection);
						var _v5 = A3($author$project$Page$Collection$init, newSession, model.d6, model.dV);
						var subModel = _v5.a;
						var subCmd = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$CollectionPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$CollectionMsg, subCmd));
					case 6:
						var _v6 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$Clubhouse);
						var chatRooms = A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.bl;
							},
							$elm$core$Dict$values(model.d$));
						var _v7 = A2($author$project$Page$Clubhouse$init, newSession, chatRooms);
						var subModel = _v7.a;
						var subCmd = _v7.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$ClubhousePage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$ClubhouseMsg, subCmd));
					case 7:
						var id = maybeRoute.a.a;
						var room = A2(
							$elm$core$Maybe$withDefault,
							$author$project$Type$Clubhouse$emptyRoom,
							A2($elm$core$Dict$get, id, model.d$));
						var newSession = updateSession(
							$author$project$Route$Room(id));
						var _v8 = A3($author$project$Page$Room$init, newSession, id, room);
						var subModel = _v8.a;
						var subCmd = _v8.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$RoomPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$RoomMsg, subCmd));
					case 8:
						var _v9 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$Market);
						var _v10 = A4($author$project$Page$Market$init, newSession, model.dP, model.d6, model.dV);
						var subModel = _v10.a;
						var subCmd = _v10.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$MarketPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$MarketMsg, subCmd));
					case 11:
						var id = maybeRoute.a.a;
						var newSession = updateSession(
							$author$project$Route$SetPacks(id));
						var maybeSet = A2($elm$core$Dict$get, id, model.d6);
						var _v11 = A6($author$project$Page$SetPacks$init, newSession, id, maybeSet, model.dP, model.d6, model.dV);
						var subModel = _v11.a;
						var subCmd = _v11.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$SetPacksPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetPacksMsg, subCmd));
					case 1:
						var _v12 = maybeRoute.a;
						return A4(updateGenericPage, $author$project$Page$GetStarted$init, $author$project$Route$GetStarted, $author$project$Type$Page$GetStartedPage, $author$project$Msg$GetStartedMsg);
					case 3:
						var _v13 = maybeRoute.a;
						return A4(updateGenericPage, $author$project$Page$Profile$init, $author$project$Route$Profile, $author$project$Type$Page$ProfilePage, $author$project$Msg$ProfileMsg);
					case 10:
						var _v14 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$Message);
						var _v15 = A2($author$project$Page$Message$init, newSession, _List_Nil);
						var subModel = _v15.a;
						var subCmd = _v15.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: $author$project$Type$Page$MessagePage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$MessageMsg, subCmd));
					case 2:
						var _v16 = maybeRoute.a;
						var newSession = $author$project$Type$Session$Guest(
							$author$project$Type$Session$params(model.d5));
						var emptyModel = _Utils_update(
							model,
							{cQ: _List_Nil, aK: $elm$core$Maybe$Nothing, dC: $elm$core$Maybe$Nothing, dV: $elm$core$Dict$empty, d5: newSession, d8: _List_Nil});
						var _v17 = A5($author$project$Page$Home$init, newSession, $elm$core$Dict$empty, $elm$core$Maybe$Nothing, _List_Nil, $elm$core$Dict$empty);
						var subModel = _v17.a;
						return _Utils_Tuple2(
							_Utils_update(
								emptyModel,
								{
									dQ: $author$project$Type$Page$HomePage(subModel)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Firebase$signout,
										A2(
										$author$project$Route$replaceUrl,
										$author$project$Type$Session$navKey(newSession),
										$author$project$Route$Home)
									])));
					default:
						break _v0$11;
				}
			} else {
				break _v0$11;
			}
		}
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dQ: $author$project$Type$Page$Error404Page}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Update$initPageForRoute = F2(
	function (model, maybeRoute) {
		return A2($author$project$Update$pageForRoute, model, maybeRoute);
	});
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {S: frag, T: params, P: unvisited, J: value, X: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.P;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.J);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.J);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ca),
					$elm$url$Url$Parser$prepareQuery(url.cj),
					url.bR,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Chat = function (a) {
	return {$: 9, a: a};
};
var $author$project$Route$SignOut = {$: 2};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.X;
		var unvisited = _v0.P;
		var params = _v0.T;
		var frag = _v0.S;
		var value = _v0.J;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.X;
			var unvisited = _v1.P;
			var params = _v1.T;
			var frag = _v1.S;
			var value = _v1.J;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.X;
		var unvisited = _v0.P;
		var params = _v0.T;
		var frag = _v0.S;
		var value = _v0.J;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.X;
			var unvisited = _v0.P;
			var params = _v0.T;
			var frag = _v0.S;
			var value = _v0.J;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Home,
			$elm$url$Url$Parser$s('home')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$GetStarted,
			$elm$url$Url$Parser$s('get-started')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SignOut,
			$elm$url$Url$Parser$s('signout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Profile,
			$elm$url$Url$Parser$s('profile')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Stack,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('stack'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Collection,
			$elm$url$Url$Parser$s('collection')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Market,
			$elm$url$Url$Parser$s('shop')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Clubhouse,
			$elm$url$Url$Parser$s('clubhouse')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Room,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('room'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Chat,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('chat'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Message,
			$elm$url$Url$Parser$s('messages')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SetPacks,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('set'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$s('packs'))))
		]));
var $author$project$Init$init = F3(
	function (flags, url, navKey) {
		var seedStore = $author$project$Type$Flags$decodeSeedStore(flags);
		var maybeRoute = A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url);
		var appId = seedStore.bC;
		var params = {
			bC: appId,
			c1: $elm$random$Random$initialSeed(seedStore.d3),
			aK: seedStore.aK,
			b6: navKey,
			d0: A2($elm$core$Maybe$withDefault, $author$project$Route$NotFound, maybeRoute),
			cs: $author$project$Type$Screen$defaultSize,
			cD: $elm$time$Time$utc,
			cH: url
		};
		var session = $author$project$Type$Session$Loading(params);
		var model = {cQ: _List_Nil, aK: seedStore.aK, dA: maybeRoute, dB: $elm$core$Maybe$Nothing, dC: $elm$core$Maybe$Nothing, b6: navKey, dI: 0, dP: _List_Nil, dQ: $author$project$Type$Page$LoadingPage, dR: false, dV: $elm$core$Dict$empty, d$: $elm$core$Dict$empty, cs: $author$project$Type$Screen$defaultSize, d5: session, d6: $elm$core$Dict$empty, d8: _List_Nil, cD: $elm$time$Time$utc, cH: url};
		var _v0 = A2($author$project$Update$initPageForRoute, model, maybeRoute);
		var newModel = _v0.a;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Msg$AdjustTimeZone, $elm$time$Time$here),
						A2($elm$core$Task$perform, $author$project$Msg$GotViewport, $elm$browser$Browser$Dom$getViewport)
					])));
	});
var $author$project$Msg$AccountChanged = function (a) {
	return {$: 29, a: a};
};
var $author$project$Msg$AuthStateChanged = function (a) {
	return {$: 14, a: a};
};
var $author$project$Msg$GotAlert = function (a) {
	return {$: 15, a: a};
};
var $author$project$Msg$GotChatMessage = function (a) {
	return {$: 30, a: a};
};
var $author$project$Msg$GotNewPrints = function (a) {
	return {$: 32, a: a};
};
var $author$project$Msg$GotPack = function (a) {
	return {$: 21, a: a};
};
var $author$project$Msg$GotPrint = function (a) {
	return {$: 22, a: a};
};
var $author$project$Msg$GotRoom = function (a) {
	return {$: 25, a: a};
};
var $author$project$Msg$LocalCacheChanged = function (a) {
	return {$: 31, a: a};
};
var $author$project$Msg$PrintRemoved = function (a) {
	return {$: 23, a: a};
};
var $author$project$Msg$ProfileChanged = function (a) {
	return {$: 16, a: a};
};
var $author$project$Msg$RemoveStack = function (a) {
	return {$: 24, a: a};
};
var $author$project$Msg$SetChanged = function (a) {
	return {$: 19, a: a};
};
var $author$project$Msg$SetRemoved = function (a) {
	return {$: 20, a: a};
};
var $author$project$Msg$StacksChanged = function (a) {
	return {$: 18, a: a};
};
var $author$project$Msg$Tick = function (a) {
	return {$: 34, a: a};
};
var $author$project$Msg$WallChanged = function (a) {
	return {$: 17, a: a};
};
var $author$project$Msg$WindowResized = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$Msg$WindowScrolled = function (a) {
	return {$: 27, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {ch: processes, cC: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.ch;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.cC);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Firebase$onAccountChanged = _Platform_incomingPort('onAccountChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onAuthStateChanged = _Platform_incomingPort('onAuthStateChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotAlert = _Platform_incomingPort('onGotAlert', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotChatMessage = _Platform_incomingPort('onGotChatMessage', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotPack = _Platform_incomingPort('onGotPack', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotPrint = _Platform_incomingPort('onGotPrint', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotRoom = _Platform_incomingPort('onGotRoom', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotWall = _Platform_incomingPort('onGotWall', $elm$json$Json$Decode$value);
var $author$project$LocalService$onLocalCacheChanged = _Platform_incomingPort('onLocalCacheChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onNewPrints = _Platform_incomingPort('onNewPrints', $elm$json$Json$Decode$value);
var $author$project$Firebase$onPrintRemoved = _Platform_incomingPort('onPrintRemoved', $elm$json$Json$Decode$string);
var $author$project$Firebase$onProfileChanged = _Platform_incomingPort('onProfileChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onRemoveStack = _Platform_incomingPort('onRemoveStack', $elm$json$Json$Decode$string);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {cb: pids, cB: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {bO: event, b0: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.cb,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.b0;
		var event = _v0.bO;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.cB);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$LocalService$onScroll = _Platform_incomingPort('onScroll', $elm$json$Json$Decode$int);
var $author$project$Firebase$onSetChanged = _Platform_incomingPort('onSetChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onSetRemoved = _Platform_incomingPort('onSetRemoved', $elm$json$Json$Decode$string);
var $author$project$Firebase$onStacksChanged = _Platform_incomingPort('onStacksChanged', $elm$json$Json$Decode$value);
var $author$project$Page$GetStarted$GotSignInMethods = function (a) {
	return {$: 7, a: a};
};
var $author$project$Firebase$gotSignInMethods = _Platform_incomingPort('gotSignInMethods', $elm$json$Json$Decode$value);
var $author$project$Page$GetStarted$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Firebase$gotSignInMethods($author$project$Page$GetStarted$GotSignInMethods)
			]));
};
var $author$project$Page$Home$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Subscriptions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Firebase$onAuthStateChanged($author$project$Msg$AuthStateChanged),
				$author$project$Firebase$onGotAlert($author$project$Msg$GotAlert),
				$author$project$Firebase$onProfileChanged($author$project$Msg$ProfileChanged),
				$author$project$Firebase$onGotWall($author$project$Msg$WallChanged),
				$author$project$Firebase$onStacksChanged($author$project$Msg$StacksChanged),
				$author$project$Firebase$onSetChanged($author$project$Msg$SetChanged),
				$author$project$Firebase$onSetRemoved($author$project$Msg$SetRemoved),
				$author$project$Firebase$onPrintRemoved($author$project$Msg$PrintRemoved),
				$author$project$Firebase$onRemoveStack($author$project$Msg$RemoveStack),
				$author$project$Firebase$onGotRoom($author$project$Msg$GotRoom),
				$author$project$Firebase$onAccountChanged($author$project$Msg$AccountChanged),
				$author$project$Firebase$onGotPack($author$project$Msg$GotPack),
				$author$project$Firebase$onGotChatMessage($author$project$Msg$GotChatMessage),
				$author$project$Firebase$onGotPrint($author$project$Msg$GotPrint),
				$author$project$Firebase$onNewPrints($author$project$Msg$GotNewPrints),
				$author$project$LocalService$onScroll($author$project$Msg$WindowScrolled),
				$author$project$LocalService$onLocalCacheChanged($author$project$Msg$LocalCacheChanged),
				$elm$browser$Browser$Events$onResize($author$project$Msg$WindowResized),
				A2($elm$time$Time$every, 1000, $author$project$Msg$Tick),
				function () {
				var _v0 = model.dQ;
				switch (_v0.$) {
					case 2:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$GetStartedMsg,
							$author$project$Page$GetStarted$subscriptions(subModel));
					case 0:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$HomeMsg,
							$author$project$Page$Home$subscriptions(subModel));
					default:
						return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Ox$Theme$alertTimeOut = 3000;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Type$User$Account = F5(
	function (badgeCount, credits, dateCreated, language, locale) {
		return {a0: badgeCount, c0: credits, c3: dateCreated, be: language, bf: locale};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Type$User$accountDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'locale',
	$elm$json$Json$Decode$string,
	'en_US',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'language',
		$elm$json$Json$Decode$string,
		'en-US',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'dateCreated',
			$elm$json$Json$Decode$int,
			0,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'credits',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'badgeCount',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$Type$User$Account))))));
var $author$project$Type$Session$updateAccount = F2(
	function (session, account) {
		if (session.$ === 3) {
			var par = session.a;
			var user = session.b;
			return A2(
				$author$project$Type$Session$SignedIn,
				par,
				_Utils_update(
					user,
					{
						dw: $elm$core$Maybe$Just(account)
					}));
		} else {
			return session;
		}
	});
var $author$project$Update$updateAccountChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$User$accountDecoder, data);
		if (!_v0.$) {
			var account = _v0.a;
			var newSession = A2($author$project$Type$Session$updateAccount, model.d5, account);
			var newModel = _Utils_update(
				model,
				{d5: newSession});
			var updateAccountInPage = F2(
				function (page, newSubModel) {
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: page(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 4:
					var subModel = _v1.a;
					return A2(
						updateAccountInPage,
						$author$project$Type$Page$MarketPage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				case 7:
					var subModel = _v1.a;
					return A2(
						updateAccountInPage,
						$author$project$Type$Page$ProfilePage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				case 9:
					var subModel = _v1.a;
					return A2(
						updateAccountInPage,
						$author$project$Type$Page$SetPacksPage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $author$project$Update$updateAlertView = F2(
	function (model, subMsg) {
		var index = subMsg;
		var newAlerts = A2($elm_community$list_extra$List$Extra$removeAt, index, model.cQ);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cQ: newAlerts}),
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$LocalService$storeCache = _Platform_outgoingPort(
	'storeCache',
	function ($) {
		return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$core$Basics$identity, $);
	});
var $author$project$Type$Flags$saveLocalStore = function (localStorage) {
	var data = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(localStorage.da))
			]));
	return $author$project$LocalService$storeCache(
		$elm$core$Maybe$Just(data));
};
var $author$project$Type$User$User = F7(
	function (uid, email, isAdmin, isEditor, isModerator, maybeProfile, maybeAccount) {
		return {da: email, dq: isAdmin, dr: isEditor, ds: isModerator, dw: maybeAccount, dz: maybeProfile, eg: uid};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Type$User$Profile = function (id) {
	return function (badgeCount) {
		return function (blurb) {
			return function (closedAnnouncementIds) {
				return function (dateCreated) {
					return function (dateLastLogin) {
						return function (img) {
							return function (language) {
								return function (likedMessages) {
									return function (locale) {
										return function (setsCollecting) {
											return function (username) {
												return {a0: badgeCount, cT: blurb, bF: closedAnnouncementIds, c3: dateCreated, bK: dateLastLogin, di: id, bX: img, be: language, b3: likedMessages, bf: locale, bq: setsCollecting, ej: username};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$DocRef$DocRef = F2(
	function (id, path) {
		return {di: id, ca: path};
	});
var $author$project$Type$DocRef$docRefDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'path',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Type$DocRef$DocRef)));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Type$User$profileDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'username',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'setsCollecting',
		$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
		_List_Nil,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'locale',
			$elm$json$Json$Decode$string,
			'en_US',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'likedMessages',
				$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
				_List_Nil,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'language',
					$elm$json$Json$Decode$string,
					'en-US',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'img',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'dateLastLogIn',
							$elm$json$Json$Decode$int,
							0,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'dateCreated',
								$elm$json$Json$Decode$int,
								0,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'closedAnnouncementIds',
									$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
									_List_Nil,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'blurb',
										$elm$json$Json$Decode$string,
										'',
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'badgeCount',
											$elm$json$Json$Decode$int,
											0,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$elm$json$Json$Decode$string,
												$elm$json$Json$Decode$succeed($author$project$Type$User$Profile)))))))))))));
var $author$project$Type$User$userDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'maybeAccount',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Type$User$accountDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maybeProfile',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Type$User$profileDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'isModerator',
			$elm$json$Json$Decode$bool,
			false,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'isEditor',
				$elm$json$Json$Decode$bool,
				false,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'isAdmin',
					$elm$json$Json$Decode$bool,
					false,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'email',
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'uid',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Type$User$User))))))));
var $author$project$Update$updateAuthStateChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$User$userDecoder, data);
		if (!_v0.$) {
			var user = _v0.a;
			var params = $author$project$Type$Session$params(model.d5);
			var newSession = A2($author$project$Type$Session$SignedIn, params, user);
			var localStorage = $author$project$Type$Flags$LocalStorage(user.da);
			var cmdBatchList = _List_fromArray(
				[
					$author$project$Type$Flags$saveLocalStore(localStorage),
					function () {
					var _v1 = model.dQ;
					if (_v1.$ === 2) {
						return A2(
							$author$project$Route$replaceUrl,
							$author$project$Type$Session$navKey(newSession),
							$author$project$Route$Home);
					} else {
						return A2(
							$author$project$Route$replaceUrl,
							$author$project$Type$Session$navKey(newSession),
							A2($elm$core$Maybe$withDefault, $author$project$Route$Home, model.dA));
					}
				}()
				]);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{d5: newSession}),
				$elm$core$Platform$Cmd$batch(cmdBatchList));
		} else {
			var params = $author$project$Type$Session$params(model.d5);
			var newSession = $author$project$Type$Session$Guest(params);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{d5: newSession}),
				A2(
					$author$project$Route$replaceUrl,
					$author$project$Type$Session$navKey(newSession),
					$author$project$Route$Home));
		}
	});
var $author$project$Page$Clubhouse$PageMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var bool = msg.a;
				return _Utils_Tuple2(bool, $elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(!model, $elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Clubhouse$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.dR);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dR: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Clubhouse$PageMsg, pageCmd));
	});
var $author$project$Page$Clubhouse$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var id = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Type$Session$navKey(model.d5),
						$author$project$Route$Room(id)));
			case 2:
				var subMsg = msg.a;
				return A2($author$project$Page$Clubhouse$updatePageMsg, subMsg, model);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateClubhouseMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Clubhouse$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$ClubhousePage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$ClubhouseMsg, subCmd));
	});
var $author$project$Page$Collection$NoOp = {$: 0};
var $author$project$Page$Collection$OptionSheet = {$: 2};
var $author$project$Page$Collection$ProfileSheet = {$: 1};
var $author$project$Page$Collection$TibleModal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Modal$Tible$Sticker = 4;
var $elm$core$Basics$round = _Basics_round;
var $author$project$Type$Modal$calcCardSize = F2(
	function (width, _v0) {
		var w = width - 20;
		var h = w * 1.5;
		return _Utils_Tuple2(
			$elm$core$Basics$round(w),
			$elm$core$Basics$round(h));
	});
var $author$project$Type$Modal$cardSize = _Utils_Tuple2(400, 600);
var $author$project$Type$Modal$toCardWidthHeight = function (screenSize) {
	if (!screenSize.$) {
		var _v1 = screenSize.a;
		var width = _v1.a;
		var height = _v1.b;
		return A2($author$project$Type$Modal$calcCardSize, width, height);
	} else {
		return $author$project$Type$Modal$cardSize;
	}
};
var $author$project$Modal$Tible$init = F4(
	function (session, otherTibles, title, tible) {
		var screenSize = $author$project$Type$Session$screenSize(session);
		var _v0 = $author$project$Type$Modal$toCardWidthHeight(screenSize);
		var modalWidth = _v0.a;
		var modalHeight = _v0.b;
		var radius = $elm$core$Basics$round(tible.aq.a6.c_ * modalWidth);
		return _Utils_Tuple2(
			{ak: 4, a2: modalWidth / 400, ap: 0, a9: modalHeight, b9: otherTibles, N: radius, cs: screenSize, aA: true, I: tible, ef: title, bz: modalWidth, cO: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Firebase$setOverflow = _Platform_outgoingPort('setOverflow', $elm$json$Json$Encode$string);
var $author$project$Page$Collection$updateCloseOptions = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{_: false, z: $elm$core$Maybe$Nothing}),
		$author$project$Firebase$setOverflow('auto'));
};
var $author$project$Page$Collection$CollectionMsg = function (a) {
	return {$: 11, a: a};
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$View$Collection$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var setId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bH: A2($elm$core$List$cons, setId, model.bH)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var setId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bH: A2(
								$elm$core$List$filter,
								function (id) {
									return !_Utils_eq(id, setId);
								},
								model.bH)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var setId = msg.a;
				var variant = msg.b;
				var newVariantFilters = A3($elm$core$Dict$insert, setId, variant, model.ah);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ah: newVariantFilters}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Collection$updateCollectionMsg = F2(
	function (subMsg, model) {
		var _v0 = A2($author$project$View$Collection$update, subMsg, model.g);
		var newSubModel = _v0.a;
		var newSubCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{g: newSubModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Collection$CollectionMsg, newSubCmd));
	});
var $author$project$Page$Collection$FiltersMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$View$Filters$update = F2(
	function (msg, model) {
		if (msg.$ === 2) {
			var viewOption = msg.a;
			if (!viewOption.$) {
				var options = viewOption.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cv: options, cJ: viewOption}),
					$elm$core$Platform$Cmd$none);
			} else {
				var options = viewOption.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b$: options, cJ: viewOption}),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Collection$updateFiltersMsg = F2(
	function (subMsg, model) {
		var collectionViewModel = model.g;
		var _v0 = A2($author$project$View$Filters$update, subMsg, model.ao);
		var filtersSubModel = _v0.a;
		var newSubCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					g: _Utils_update(
						collectionViewModel,
						{b$: filtersSubModel.b$, cv: filtersSubModel.cv, cJ: filtersSubModel.cJ}),
					ao: filtersSubModel
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Collection$FiltersMsg, newSubCmd));
	});
var $author$project$Page$Collection$TibleModalMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$Tible$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var cardBack = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ak: cardBack}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: index}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: !model.aA}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Collection$updatePrintModalMsg = F2(
	function (subMsg, model) {
		if (subMsg.$ === 1) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{z: $elm$core$Maybe$Nothing}),
				$author$project$Firebase$setOverflow('auto'));
		} else {
			var _v1 = model.z;
			if ((!_v1.$) && (!_v1.a.$)) {
				var subModel = _v1.a.a;
				var _v2 = A2($author$project$Modal$Tible$update, subMsg, subModel);
				var newPrintModalModel = _v2.a;
				var newSubCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just(
								$author$project$Page$Collection$TibleModal(newPrintModalModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Collection$TibleModalMsg, newSubCmd));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Page$Collection$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just($author$project$Page$Collection$OptionSheet)
						}),
					$author$project$Firebase$setOverflow('hidden'));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: true}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return $author$project$Page$Collection$updateCloseOptions(model);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just($author$project$Page$Collection$ProfileSheet)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var tible = msg.a;
				var index = msg.b;
				var numOfPrints = $elm$core$String$fromInt(
					A2(
						$elm$core$Maybe$withDefault,
						0,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.dt;
							},
							A2($elm$core$Dict$get, tible.d7, model.d6))));
				var title = $elm$core$String$fromInt(1 + index) + (' of ' + numOfPrints);
				var _v1 = A4($author$project$Modal$Tible$init, model.d5, _List_Nil, title, tible);
				var printModalModel = _v1.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							_: false,
							z: $elm$core$Maybe$Just(
								$author$project$Page$Collection$TibleModal(printModalModel))
						}),
					$author$project$Firebase$setOverflow('hidden'));
			case 1:
				var subMsg = msg.a;
				return A2($author$project$Page$Collection$updatePrintModalMsg, subMsg, model);
			case 11:
				var subMsg = msg.a;
				return A2($author$project$Page$Collection$updateCollectionMsg, subMsg, model);
			case 12:
				var subMsg = msg.a;
				switch (subMsg.$) {
					case 5:
						return $author$project$Page$Collection$updateCloseOptions(model);
					case 3:
						var collectionViewModel = model.g;
						var newCollectionViewModel = _Utils_update(
							collectionViewModel,
							{
								bH: $elm$core$Dict$keys(model.d6)
							});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{g: newCollectionViewModel}),
							$elm$core$Platform$Cmd$none);
					case 4:
						var collectionViewModel = model.g;
						var newCollectionViewModel = _Utils_update(
							collectionViewModel,
							{bH: _List_Nil});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{g: newCollectionViewModel}),
							$elm$core$Platform$Cmd$none);
					default:
						return A2($author$project$Page$Collection$updateFiltersMsg, subMsg, model);
				}
			case 7:
				var term = msg.a;
				var collectionViewModel = model.g;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: _Utils_update(
								collectionViewModel,
								{d2: term}),
							F: term
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var val = msg.a;
				return val ? _Utils_Tuple2(
					_Utils_update(
						model,
						{Z: true}),
					A2(
						$elm$core$Task$attempt,
						function (_v3) {
							return $author$project$Page$Collection$NoOp;
						},
						$elm$browser$Browser$Dom$focus('search-field'))) : _Utils_Tuple2(
					_Utils_update(
						model,
						{Z: false, F: ''}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateCollectionMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Collection$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$CollectionPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$CollectionMsg, subCmd));
	});
var $author$project$Page$GetStarted$Email = 0;
var $author$project$Page$GetStarted$NoOp = {$: 0};
var $author$project$Page$GetStarted$Password = 1;
var $author$project$Page$GetStarted$SignIn = 1;
var $author$project$Page$GetStarted$SignUp = 2;
var $author$project$Firebase$fetchSignInMethodsForEmail = _Platform_outgoingPort('fetchSignInMethodsForEmail', $elm$json$Json$Encode$string);
var $author$project$Firebase$sendPasswordResetEmail = _Platform_outgoingPort('sendPasswordResetEmail', $elm$json$Json$Encode$string);
var $author$project$Page$GetStarted$signInMethodsDecoder = A2(
	$elm$json$Json$Decode$field,
	'methods',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Firebase$signInWithEmailAndPassword = _Platform_outgoingPort('signInWithEmailAndPassword', $elm$core$Basics$identity);
var $author$project$Firebase$signinEncoder = F2(
	function (email, password) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(email)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
	});
var $author$project$Firebase$signin = F2(
	function (email, password) {
		return $author$project$Firebase$signInWithEmailAndPassword(
			A2($author$project$Firebase$signinEncoder, email, password));
	});
var $author$project$Firebase$createUserWithEmailAndPassword = _Platform_outgoingPort('createUserWithEmailAndPassword', $elm$core$Basics$identity);
var $author$project$Firebase$signup = F2(
	function (email, password) {
		return $author$project$Firebase$createUserWithEmailAndPassword(
			A2($author$project$Firebase$signinEncoder, email, password));
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Page$GetStarted$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				if (!msg.a) {
					var _v1 = msg.a;
					var email = msg.b;
					var newModel = _Utils_update(
						model,
						{da: email});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var _v2 = msg.a;
					var password = msg.b;
					var newModel = _Utils_update(
						model,
						{q: password});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var field = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: $elm$core$Maybe$Just(field)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var field = msg.a;
				return _Utils_eq(
					model.R,
					$elm$core$Maybe$Just(field)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{R: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$fetchSignInMethodsForEmail(model.da));
			case 7:
				var val = msg.a;
				var methods = A2(
					$elm$core$Result$withDefault,
					_List_Nil,
					A2($elm$json$Json$Decode$decodeValue, $author$project$Page$GetStarted$signInMethodsDecoder, val));
				var _v3 = (!$elm$core$List$length(methods)) ? _Utils_Tuple3(2, 0, 'emailField') : _Utils_Tuple3(1, 1, 'passwordField');
				var stage = _v3.a;
				var formField = _v3.b;
				var fieldName = _v3.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: $elm$core$Maybe$Just(formField),
							aT: stage
						}),
					A2(
						$elm$core$Task$attempt,
						function (_v4) {
							return $author$project$Page$GetStarted$NoOp;
						},
						$elm$browser$Browser$Dom$focus(fieldName)));
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cQ: _List_Nil, q: ''}),
					A2($author$project$Firebase$signin, model.da, model.q));
			case 6:
				return _Utils_Tuple2(
					model,
					A2($author$project$Firebase$signup, model.da, model.q));
			case 8:
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$sendPasswordResetEmail(model.da));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateGetStarted = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$GetStarted$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$GetStartedPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$GetStartedMsg, subCmd));
	});
var $author$project$Page$GetStarted$CheckEmail = {$: 1};
var $author$project$Update$updateGetStartedForCheckEmail = F2(
	function (model, subModel) {
		var _v0 = A2($author$project$Page$GetStarted$update, $author$project$Page$GetStarted$CheckEmail, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		var localStorage = $author$project$Type$Flags$LocalStorage(newSubModel.da);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$GetStartedPage(newSubModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Type$Flags$saveLocalStore(localStorage),
						A2($elm$core$Platform$Cmd$map, $author$project$Msg$GetStartedMsg, subCmd)
					])));
	});
var $author$project$Type$Alert$Alert = F4(
	function (kind, message, inputField, dateCreated) {
		return {c3: dateCreated, $7: inputField, b1: kind, b4: message};
	});
var $author$project$Type$Alert$alertDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'dateCreated',
	$elm$json$Json$Decode$int,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'inputField',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'message',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'kind',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Type$Alert$Alert)))));
var $author$project$Update$updateGotAlert = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Alert$alertDecoder, data);
		if (!_v0.$) {
			var alert = _v0.a;
			if (alert.$7 === '') {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cQ: A2($elm$core$List$cons, alert, model.cQ)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				var _v1 = model.dQ;
				if (_v1.$ === 2) {
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{
							cQ: A2($elm$core$List$cons, alert, subModel.cQ)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dQ: $author$project$Type$Page$GetStartedPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cQ: A2($elm$core$List$cons, alert, model.cQ)
							}),
						$elm$core$Platform$Cmd$none);
				}
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$Clubhouse$RoomIdAndMessage = F2(
	function (chatId, message) {
		return {cW: chatId, b4: message};
	});
var $author$project$Type$Clubhouse$RoomMessage = function (id) {
	return function (username) {
		return function (userId) {
			return function (hiddenByReports) {
				return function (hiddenByUser) {
					return function (createdAt) {
						return function (text) {
							return function (colorBackground) {
								return function (colorShelf) {
									return function (printRefs) {
										return {cX: colorBackground, cY: colorShelf, c$: createdAt, dg: hiddenByReports, dh: hiddenByUser, di: id, dX: printRefs, H: text, ei: userId, ej: username};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Clubhouse$chatMessageDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'printRefs',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	_List_Nil,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'colorShelf',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'colorBackground',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'text',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'createdAt',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'hiddenByUser',
						$elm$json$Json$Decode$bool,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'hiddenByReports',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'userId',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'username',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Type$Clubhouse$RoomMessage)))))))))));
var $author$project$Type$Clubhouse$roomIdAndMessageDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$author$project$Type$Clubhouse$chatMessageDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'chatId',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Type$Clubhouse$RoomIdAndMessage)));
var $author$project$Update$updateRoomChats = F2(
	function (result, roomsDict) {
		var _v0 = A2($elm$core$Dict$get, result.cW, roomsDict);
		if (!_v0.$) {
			var room = _v0.a;
			var messages = A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.c$;
				},
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[result.b4]),
					room.dD));
			var newRoom = _Utils_update(
				room,
				{dD: messages});
			return newRoom;
		} else {
			return A5(
				$author$project$Type$Clubhouse$Room,
				result.cW,
				'',
				'',
				0,
				_List_fromArray(
					[result.b4]));
		}
	});
var $author$project$Update$updateGotChatMessage = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Clubhouse$roomIdAndMessageDecoder, data);
		if (!_v0.$) {
			var result = _v0.a;
			var newRoom = A2($author$project$Update$updateRoomChats, result, model.d$);
			var newRoomsDict = A3($elm$core$Dict$insert, result.cW, newRoom, model.d$);
			var _v1 = model.dQ;
			if (_v1.$ === 6) {
				var subModel = _v1.a;
				var newSubModel = _Utils_update(
					subModel,
					{bn: newRoom});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dQ: $author$project$Type$Page$RoomPage(newSubModel),
							d$: newRoomsDict
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d$: newRoomsDict}),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cQ: _List_fromArray(
							[alert])
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$Print$Print = F9(
	function (dateAcquired, dateCreated, id, itemId, ownerId, printNumber, score, setId, variantId) {
		return {c2: dateAcquired, c3: dateCreated, di: id, du: itemId, dO: ownerId, dW: printNumber, d1: score, d7: setId, cI: variantId};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Type$Print$printDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'variantId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'setId',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'score',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'printNumber',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'ownerId',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'itemId',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'dateCreated',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'dateAcquired',
									$elm$json$Json$Decode$int,
									$elm$json$Json$Decode$succeed($author$project$Type$Print$Print))))))))));
var $author$project$Type$Print$printListDecoder = $elm$json$Json$Decode$list($author$project$Type$Print$printDecoder);
var $author$project$Page$SetPacks$BuyModalMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Modal$Buy$Close = {$: 3};
var $author$project$Modal$Buy$Showing = 2;
var $author$project$Modal$Buy$updateShow = F2(
	function (tibles, model) {
		var newTibles = A2($elm$core$List$drop, 1, tibles);
		var maybeTibles = $elm$core$List$head(tibles);
		var _v0 = function () {
			if (!maybeTibles.$) {
				var tible = maybeTibles.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						A4($author$project$Modal$Tible$init, model.d5, _List_Nil, '', tible).a),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed($author$project$Modal$Buy$Close)));
			}
		}();
		var maybeTibleModalModel = _v0.a;
		var cmdMsg = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aa: maybeTibleModalModel, aC: 2, aD: newTibles}),
			cmdMsg);
	});
var $author$project$Page$SetPacks$updateNewPrints = F2(
	function (prints, model) {
		var _v0 = model.D;
		if (!_v0.$) {
			var subModel = _v0.a;
			var tibles = A2(
				$elm$core$List$filterMap,
				function (x) {
					return x;
				},
				A2(
					$elm$core$List$map,
					function (p) {
						return A2($elm$core$Dict$get, p.di, model.bv);
					},
					prints));
			var _v1 = A2($author$project$Modal$Buy$updateShow, tibles, subModel);
			var newSubModel = _v1.a;
			var newCmdMsg = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						D: $elm$core$Maybe$Just(newSubModel)
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$SetPacks$BuyModalMsg, newCmdMsg));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateGotNewPrints = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Print$printListDecoder, data);
		if (!_v0.$) {
			var prints = _v0.a;
			var _v1 = model.dQ;
			if (_v1.$ === 9) {
				var subModel = _v1.a;
				var _v2 = A2($author$project$Page$SetPacks$updateNewPrints, prints, subModel);
				var newSubModel = _v2.a;
				var newCmdMsg = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dQ: $author$project$Type$Page$SetPacksPage(newSubModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetPacksMsg, newCmdMsg));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var _v3 = model.dQ;
			if (_v3.$ === 9) {
				var subModel = _v3.a;
				var newSubModel = _Utils_update(
					subModel,
					{D: $elm$core$Maybe$Nothing});
				var msg = $elm$json$Json$Decode$errorToString(error);
				var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cQ: _List_fromArray(
								[alert]),
							dQ: $author$project$Type$Page$SetPacksPage(newSubModel)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Type$Pack$Pack = function (id) {
	return function (count) {
		return function (credits) {
			return function (dateCreated) {
				return function (datePublished) {
					return function (description) {
						return function (itemRefs) {
							return function (oddsDescription) {
								return function (setRefs) {
									return function (title) {
										return {bI: count, c0: credits, c3: dateCreated, c4: datePublished, c5: description, di: id, bc: itemRefs, dK: oddsDescription, aR: setRefs, ef: title};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Pack$packDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'setRefs',
		$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'oddsDescription',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'itemRefs',
				$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'description',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'datePublished',
						$elm$json$Json$Decode$int,
						0,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'dateCreated',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'credits',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'count',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Type$Pack$Pack)))))))))));
var $author$project$Update$updateErrorAlert = F2(
	function (error, model) {
		var msg = $elm$json$Json$Decode$errorToString(error);
		var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					cQ: _List_fromArray(
						[alert])
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Update$updateGotPack = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Pack$packDecoder, data);
		if (!_v0.$) {
			var pack = _v0.a;
			var newPacks = A2($elm$core$List$cons, pack, model.dP);
			var newModel = _Utils_update(
				model,
				{dP: newPacks});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 4:
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{dP: newPacks});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: $author$project$Type$Page$MarketPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				case 9:
					var subModel = _v1.a;
					var setPacks = A2(
						$elm$core$List$filter,
						$author$project$Type$Pack$isInSet(subModel.d7),
						newPacks);
					var newSubModel = _Utils_update(
						subModel,
						{dP: newPacks, bp: setPacks});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: $author$project$Type$Page$SetPacksPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $author$project$Page$Collection$gotPrintDict = F2(
	function (model, printDict) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, model.d6);
		var newModel = _Utils_update(
			model,
			{dV: printDict, bv: tibleDict});
		var collectionViewModel = model.g;
		return _Utils_update(
			newModel,
			{
				g: _Utils_update(
					collectionViewModel,
					{dV: printDict, bv: tibleDict})
			});
	});
var $author$project$Page$Stack$updatePrints = F2(
	function (printDict, model) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, model.d6);
		var tibles = A2(
			$elm$core$List$filterMap,
			function (i) {
				return i;
			},
			A2(
				$elm$core$List$map,
				function (pr) {
					return A2($elm$core$Dict$get, pr.di, tibleDict);
				},
				model.v.dX));
		var newSortables = A2($elm$core$List$map, $author$project$Type$Sortable$Tible, tibles);
		return _Utils_update(
			model,
			{dV: printDict, cy: newSortables, bv: tibleDict, aD: tibles});
	});
var $author$project$Page$Home$Stacks$updateSetsAndPrints = F3(
	function (setDict, printDict, model) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var newMaybeEditModel = A2(
			$elm$core$Maybe$map,
			function (editModel) {
				return _Utils_update(
					editModel,
					{dV: printDict});
			},
			model.t);
		return _Utils_update(
			model,
			{t: newMaybeEditModel, dV: printDict, d6: setDict, bv: tibleDict});
	});
var $author$project$Page$Home$Wall$countPrintsPerRow = function (count) {
	switch (count) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 3;
		case 6:
			return 3;
		default:
			return 4;
	}
};
var $author$project$Page$Home$Wall$updateSetsAndPrints = F3(
	function (setDict, printDict, model) {
		var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
		var tibles = A2(
			$elm$core$List$filterMap,
			function (p) {
				return p;
			},
			A2(
				$elm$core$List$map,
				function (p) {
					return A2($elm$core$Dict$get, p.di, tibleDict);
				},
				model.dX));
		var newSortables = A2(
			$elm$core$List$map,
			function (t) {
				return $author$project$Type$Sortable$Tible(t);
			},
			tibles);
		return _Utils_update(
			model,
			{
				dV: printDict,
				az: $author$project$Page$Home$Wall$countPrintsPerRow(
					$elm$core$List$length(newSortables)),
				d6: setDict,
				cy: newSortables,
				bv: tibleDict,
				aD: tibles
			});
	});
var $author$project$Page$Home$updateSetsAndPrints = F3(
	function (setDict, printDict, model) {
		var wallModel = A3($author$project$Page$Home$Wall$updateSetsAndPrints, setDict, printDict, model.C);
		var stacksModel = A3($author$project$Page$Home$Stacks$updateSetsAndPrints, setDict, printDict, model.G);
		return _Utils_update(
			model,
			{dV: printDict, d6: setDict, G: stacksModel, C: wallModel});
	});
var $author$project$Update$updateGotPrint = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Print$printDecoder, data);
		if (!_v0.$) {
			var print = _v0.a;
			var newPrintDict = A3($elm$core$Dict$insert, print.di, print, model.dV);
			var newModel = _Utils_update(
				model,
				{dV: newPrintDict});
			var updateGotPrintInPage = F2(
				function (page, newSubModel) {
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: page(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 0:
					var subModel = _v1.a;
					return A2(
						updateGotPrintInPage,
						$author$project$Type$Page$HomePage,
						A3($author$project$Page$Home$updateSetsAndPrints, model.d6, newPrintDict, subModel));
				case 3:
					var subModel = _v1.a;
					return A2(
						updateGotPrintInPage,
						$author$project$Type$Page$CollectionPage,
						A2($author$project$Page$Collection$gotPrintDict, subModel, newPrintDict));
				case 1:
					var subModel = _v1.a;
					var newSubModel = A2($author$project$Page$Stack$updatePrints, newPrintDict, subModel);
					return A2(
						updateGotPrintInPage,
						$author$project$Type$Page$StackPage,
						_Utils_update(
							newSubModel,
							{dV: newPrintDict}));
				case 4:
					var subModel = _v1.a;
					return A2(
						updateGotPrintInPage,
						$author$project$Type$Page$MarketPage,
						_Utils_update(
							subModel,
							{dV: newPrintDict}));
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $author$project$Type$Clubhouse$roomDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'messages',
	$elm$json$Json$Decode$list($author$project$Type$Clubhouse$chatMessageDecoder),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'order',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'description',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'title',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Type$Clubhouse$Room))))));
var $author$project$Update$updateGotRoom = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Clubhouse$roomDecoder, data);
		if (!_v0.$) {
			var room = _v0.a;
			var newRoomsDict = A3($elm$core$Dict$insert, room.di, room, model.d$);
			var newModel = _Utils_update(
				model,
				{d$: newRoomsDict});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 5:
					var subModel = _v1.a;
					var rooms = A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.bl;
						},
						$elm$core$Dict$values(newRoomsDict));
					var newSubModel = _Utils_update(
						subModel,
						{bo: rooms});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: $author$project$Type$Page$ClubhousePage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var subModel = _v1.a;
					if (_Utils_eq(room.di, subModel.di)) {
						var newSubModel = _Utils_update(
							subModel,
							{bn: room});
						return _Utils_Tuple2(
							_Utils_update(
								newModel,
								{
									dQ: $author$project$Type$Page$RoomPage(newSubModel)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					}
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cQ: _List_fromArray(
							[alert]),
						d8: _List_Nil
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$Screen$ExtraLarge = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Type$Screen$Large = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Type$Screen$Medium = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Type$Screen$toSize = F2(
	function (_v0, scrollTop) {
		var width = _v0.a;
		var height = _v0.b;
		return (width > 991) ? A2(
			$author$project$Type$Screen$ExtraLarge,
			_Utils_Tuple2(width, height),
			scrollTop) : ((width > 767) ? A2(
			$author$project$Type$Screen$Large,
			_Utils_Tuple2(width, height),
			scrollTop) : ((width > 479) ? A2(
			$author$project$Type$Screen$Medium,
			_Utils_Tuple2(width, height),
			scrollTop) : A2(
			$author$project$Type$Screen$Small,
			_Utils_Tuple2(width, height),
			scrollTop)));
	});
var $author$project$Page$Home$updateSession = F2(
	function (session, model) {
		var wallModel = model.C;
		var stackModel = model.G;
		var screenSize = $author$project$Type$Session$screenSize(session);
		var newWallModel = _Utils_update(
			wallModel,
			{cs: screenSize, d5: session});
		var newStackModel = _Utils_update(
			stackModel,
			{cs: screenSize, d5: session});
		return _Utils_update(
			model,
			{cs: screenSize, d5: session, G: newStackModel, C: newWallModel});
	});
var $author$project$Update$updateGotViewport = F2(
	function (model, viewport) {
		var screenSize = A2(
			$author$project$Type$Screen$toSize,
			_Utils_Tuple2(viewport.cr.bz, viewport.cr.a9),
			viewport.em.eo);
		var params = $author$project$Type$Session$params(model.d5);
		var newParams = _Utils_update(
			params,
			{cs: screenSize});
		var newSession = A2($author$project$Type$Session$updateParams, model.d5, newParams);
		var newModel = _Utils_update(
			model,
			{cs: screenSize, d5: newSession});
		var updateScreenSizeInPage = F2(
			function (page, newSubModel) {
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{
							dQ: page(newSubModel)
						}),
					$elm$core$Platform$Cmd$none);
			});
		var _v0 = model.dQ;
		switch (_v0.$) {
			case 0:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$HomePage,
					A2($author$project$Page$Home$updateSession, newSession, subModel));
			case 1:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$StackPage,
					_Utils_update(
						subModel,
						{cs: screenSize, d5: newSession}));
			case 3:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$CollectionPage,
					_Utils_update(
						subModel,
						{cs: screenSize, d5: newSession}));
			case 5:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$ClubhousePage,
					_Utils_update(
						subModel,
						{cs: screenSize, d5: newSession}));
			case 4:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$MarketPage,
					_Utils_update(
						subModel,
						{cs: screenSize, d5: newSession}));
			case 9:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$SetPacksPage,
					_Utils_update(
						subModel,
						{cs: screenSize, d5: newSession}));
			case 7:
				var subModel = _v0.a;
				return A2(
					updateScreenSizeInPage,
					$author$project$Type$Page$ProfilePage,
					_Utils_update(
						subModel,
						{cs: screenSize, d5: newSession}));
			default:
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Home$ProfileSheet = $elm$core$Basics$identity;
var $author$project$View$Profile$init = F2(
	function (session, user) {
		return _Utils_Tuple2(
			{
				z: $elm$core$Maybe$Nothing,
				cs: $author$project$Type$Session$screenSize(session),
				d5: session,
				ef: 'Profile',
				aY: user
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Home$PageMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Home$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.dR);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dR: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$PageMsg, pageCmd));
	});
var $author$project$Page$Home$ProfileMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$View$Profile$ActionSheet = 0;
var $author$project$View$Profile$update = F2(
	function (msg, model) {
		if (msg === 1) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						z: $elm$core$Maybe$Just(0)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Home$updateProfileMsg = F3(
	function (childMsg, subModel, model) {
		var _v0 = A2($author$project$View$Profile$update, childMsg, subModel);
		var newSubModel = _v0.a;
		var newSubCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					z: $elm$core$Maybe$Just(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$ProfileMsg, newSubCmd));
	});
var $author$project$Page$Home$StackSectionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Modal$StackModal = function (a) {
	return {$: 1, a: a};
};
var $elm_community$list_extra$List$Extra$filterNot = F2(
	function (pred, list) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, pred),
			list);
	});
var $author$project$Modal$Picker$GotViewportOf = function (a) {
	return {$: 10, a: a};
};
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $author$project$Modal$Picker$init = F4(
	function (session, setDict, printDict, modal) {
		if (modal.$ === 1) {
			var stack = modal.a;
			var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
			var selectedIds = A2(
				$elm$core$List$map,
				function (x) {
					return x.di;
				},
				stack.dX);
			var newEditableTitle = A3($author$project$Page$EditableField, stack.ef, stack.ef, false);
			return _Utils_Tuple2(
				{
					di: stack.di,
					Z: false,
					at: 100,
					y: $elm$core$Maybe$Just(newEditableTitle),
					dB: $elm$core$Maybe$Nothing,
					ac: 'animate__animated animate__slideInUp animate__faster',
					bi: modal,
					dV: printDict,
					af: _List_Nil,
					F: '',
					u: selectedIds,
					d5: session,
					d6: setDict,
					bv: tibleDict,
					ef: stack.ef,
					W: false
				},
				A2(
					$elm$core$Task$attempt,
					$author$project$Modal$Picker$GotViewportOf,
					$elm$browser$Browser$Dom$getViewportOf('print-grid')));
		} else {
			var tibles = modal.a;
			var wallId = modal.b;
			var tibleDict = A2($author$project$Type$Tible$toDict, printDict, setDict);
			var selectedIds = A2(
				$elm$core$List$map,
				function (x) {
					return x.dU.di;
				},
				tibles);
			return _Utils_Tuple2(
				{di: wallId, Z: false, at: 20, y: $elm$core$Maybe$Nothing, dB: $elm$core$Maybe$Nothing, ac: '', bi: modal, dV: printDict, af: _List_Nil, F: '', u: selectedIds, d5: session, d6: setDict, bv: tibleDict, ef: 'Wall', W: false},
				A2(
					$elm$core$Task$attempt,
					$author$project$Modal$Picker$GotViewportOf,
					$elm$browser$Browser$Dom$getViewportOf('print-grid')));
		}
	});
var $author$project$Firebase$removeStack = _Platform_outgoingPort('removeStack', $elm$json$Json$Encode$string);
var $author$project$Page$Home$Stacks$EditStackModalMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Modal$Picker$Close = {$: 1};
var $author$project$Modal$Picker$NoOp = {$: 0};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Process$sleep = _Process_sleep;
var $elm$core$String$toLower = _String_toLower;
var $author$project$Modal$Picker$updateEditTitle = F2(
	function (model, title) {
		var _v0 = model.y;
		if (!_v0.$) {
			var editableTitle = _v0.a;
			var newEditableTitle = _Utils_update(
				editableTitle,
				{bm: '', H: title});
			var newModel = _Utils_update(
				model,
				{
					y: $elm$core$Maybe$Just(newEditableTitle)
				});
			return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Modal$Picker$updateEditTitleStart = F3(
	function (model, title, id) {
		var _v0 = model.y;
		if (!_v0.$) {
			var editableTitle = _v0.a;
			var newEditableTitle = _Utils_update(
				editableTitle,
				{ba: true, bm: title, H: title});
			var newModel = _Utils_update(
				model,
				{
					y: $elm$core$Maybe$Just(newEditableTitle)
				});
			return _Utils_Tuple2(
				newModel,
				A2(
					$elm$core$Task$attempt,
					function (_v1) {
						return $author$project$Modal$Picker$NoOp;
					},
					$elm$browser$Browser$Dom$focus(id)));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Modal$Picker$updateSelect = F2(
	function (model, id) {
		var newSelectedIds = A2($elm$core$List$member, id, model.u) ? A2(
			$elm$core$List$filter,
			function (kid) {
				return !_Utils_eq(kid, id);
			},
			model.u) : ((_Utils_cmp(
			$elm$core$List$length(model.u),
			model.at) < 0) ? _Utils_ap(
			model.u,
			_List_fromArray(
				[id])) : model.u);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{u: newSelectedIds, W: true}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Modal$Picker$updateTitleEnd = function (model) {
	var _v0 = model.y;
	if (!_v0.$) {
		var editableTitle = _v0.a;
		var newTitle = (editableTitle.H === '') ? 'Untitled' : editableTitle.H;
		var newEditableTitle = _Utils_update(
			editableTitle,
			{ba: false, H: newTitle});
		var newModel = _Utils_update(
			model,
			{
				y: $elm$core$Maybe$Just(newEditableTitle),
				W: true
			});
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	} else {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$Modal$Picker$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 7:
				var id = msg.a;
				return A2($author$project$Modal$Picker$updateSelect, model, id);
			case 4:
				var title = msg.a;
				var id = msg.b;
				return A3($author$project$Modal$Picker$updateEditTitleStart, model, title, id);
			case 5:
				var title = msg.a;
				return A2($author$project$Modal$Picker$updateEditTitle, model, title);
			case 6:
				return $author$project$Modal$Picker$updateTitleEnd(model);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: 'animate__animated animate__slideOutDown animate__faster'}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Modal$Picker$Close),
						$elm$core$Process$sleep(100)));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: 'animate__animated animate__slideOutDown animate__faster'}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Modal$Picker$Close),
						$elm$core$Process$sleep(100)));
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 8:
				var term = msg.a;
				var tlc = $elm$core$String$toLower(term);
				var printsFound = _List_Nil;
				var prints = $elm$core$Dict$values(model.dV);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{af: printsFound, F: term}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var bool = msg.a;
				return bool ? _Utils_Tuple2(
					_Utils_update(
						model,
						{Z: true}),
					A2(
						$elm$core$Task$attempt,
						function (_v1) {
							return $author$project$Modal$Picker$NoOp;
						},
						$elm$browser$Browser$Dom$focus('search-field'))) : _Utils_Tuple2(
					_Utils_update(
						model,
						{Z: false, af: _List_Nil, F: ''}),
					$elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				if (!result.$) {
					var viewport = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dB: $elm$core$Maybe$Just(viewport)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dB: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Home$Stacks$passThruToEditStackModalMsg = F2(
	function (subMsg, model) {
		var _v0 = model.t;
		if (!_v0.$) {
			var editModel = _v0.a;
			var _v1 = A2($author$project$Modal$Picker$update, subMsg, editModel);
			var newEditModel = _v1.a;
			var cmdMsg = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						t: $elm$core$Maybe$Just(newEditModel)
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$Stacks$EditStackModalMsg, cmdMsg));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$Print$toDict = function (prints) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (p) {
				return _Utils_Tuple2(p.di, p);
			},
			prints));
};
var $author$project$Type$Print$fromIds = F2(
	function (ids, prints) {
		return A2(
			$elm$core$List$filterMap,
			function (p) {
				return p;
			},
			A2(
				$elm$core$List$map,
				function (id) {
					return A2(
						$elm$core$Dict$get,
						id,
						$author$project$Type$Print$toDict(prints));
				},
				ids));
	});
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Type$DocRef$fromList = function (pathList) {
	var _v0 = $elm_community$list_extra$List$Extra$last(pathList);
	if (!_v0.$) {
		var id = _v0.a;
		return $elm$core$Maybe$Just(
			A2(
				$author$project$Type$DocRef$DocRef,
				id,
				A2($elm$core$String$join, '/', pathList)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$DocRef$fromPath = function (str) {
	return $author$project$Type$DocRef$fromList(
		A2($elm$core$String$split, '/', str));
};
var $author$project$Page$Home$Stacks$saveStack = F2(
	function (model, editModel) {
		var title = function () {
			var _v0 = editModel.y;
			if (!_v0.$) {
				var editableTitle = _v0.a;
				return editableTitle.H;
			} else {
				return model.ef;
			}
		}();
		var stack = $author$project$Type$Stack$emptyStack;
		var printRefs = A2(
			$elm$core$List$filterMap,
			function (p) {
				return p;
			},
			A2(
				$elm$core$List$map,
				function (p) {
					return $author$project$Type$DocRef$fromPath(p);
				},
				A2(
					$elm$core$List$map,
					function (k) {
						return 'prints/' + k.di;
					},
					A2(
						$author$project$Type$Print$fromIds,
						editModel.u,
						$elm$core$Dict$values(model.dV)))));
		var order = 1 + A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$andThen,
				function (s) {
					return $elm$core$Maybe$Just(s.bl);
				},
				$elm$core$List$head(
					$elm$core$List$reverse(
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.bl;
							},
							model.d8)))));
		var newStack = _Utils_update(
			stack,
			{bl: order, dX: printRefs, ef: title});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{t: $elm$core$Maybe$Nothing}),
			newStack);
	});
var $author$project$Type$DocRef$docRefEncoder = function (docRef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(docRef.di)),
				_Utils_Tuple2(
				'path',
				$elm$json$Json$Encode$string(docRef.ca))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Type$Stack$stackEncoder = function (stack) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(stack.di)),
				_Utils_Tuple2(
				'printRefs',
				A2($elm$json$Json$Encode$list, $author$project$Type$DocRef$docRefEncoder, stack.dX)),
				_Utils_Tuple2(
				'order',
				$elm$json$Json$Encode$int(stack.bl)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(stack.ef))
			]));
};
var $author$project$Firebase$updateStack = _Platform_outgoingPort('updateStack', $elm$core$Basics$identity);
var $author$project$Page$Home$Stacks$updateEditStackModalMsg = F2(
	function (subMsg, model) {
		switch (subMsg.$) {
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{t: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var _v1 = model.t;
				if (!_v1.$) {
					var editModel = _v1.a;
					var _v2 = A2($author$project$Page$Home$Stacks$saveStack, model, editModel);
					var newModel = _v2.a;
					var newStack = _v2.b;
					return _Utils_Tuple2(
						newModel,
						$author$project$Firebase$updateStack(
							$author$project$Type$Stack$stackEncoder(newStack)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return A2($author$project$Page$Home$Stacks$passThruToEditStackModalMsg, subMsg, model);
		}
	});
var $author$project$Page$Home$Stacks$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var stack = $author$project$Type$Stack$emptyStack;
				var _v1 = A4(
					$author$project$Modal$Picker$init,
					model.d5,
					model.d6,
					model.dV,
					$author$project$Type$Modal$StackModal(stack));
				var subModel = _v1.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							t: $elm$core$Maybe$Just(subModel)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							am: $elm$core$Maybe$Just(id)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var id = msg.a;
				var newStacks = A2(
					$elm_community$list_extra$List$Extra$filterNot,
					function (d) {
						return _Utils_eq(d.di, id);
					},
					model.d8);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d8: newStacks}),
					$author$project$Firebase$removeStack(id));
			default:
				var subMsg = msg.a;
				return A2($author$project$Page$Home$Stacks$updateEditStackModalMsg, subMsg, model);
		}
	});
var $author$project$Page$Home$updateStackMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$Home$Stacks$update, childMsg, model.G);
		var stackModel = _v0.a;
		var stackCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{G: stackModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$StackSectionMsg, stackCmd));
	});
var $author$project$Page$Home$WallSectionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$Wall$EditWallModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$Wall$EditWallModalMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Home$Wall$TibleModal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Modal$WallModal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$getDragId = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var dragId = model.a;
			return $elm$core$Maybe$Just(dragId);
		default:
			var dragId = model.a;
			var dropId = model.b;
			return $elm$core$Maybe$Just(dragId);
	}
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$getDropId = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var dragId = model.a;
			return $elm$core$Maybe$Nothing;
		default:
			var dragId = model.a;
			var dropId = model.b;
			return $elm$core$Maybe$Just(dropId);
	}
};
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $author$project$Reorder$reorderTibles = F3(
	function (dragId, dropId, tibles) {
		var shift = function (draggedItem) {
			var _v0 = A2(
				$elm_community$list_extra$List$Extra$splitAt,
				dropId,
				A2($elm_community$list_extra$List$Extra$removeAt, dragId, tibles));
			var itemsA = _v0.a;
			var itemsB = _v0.b;
			return $elm$core$List$concat(
				_List_fromArray(
					[
						itemsA,
						_List_fromArray(
						[draggedItem]),
						itemsB
					]));
		};
		var newItems = A2(
			$elm$core$Maybe$withDefault,
			tibles,
			A2(
				$elm$core$Maybe$map,
				shift,
				A2($elm_community$list_extra$List$Extra$getAt, dragId, tibles)));
		return newItems;
	});
var $author$project$Type$Sortable$Placeholder = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{i: nodeList, e: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $author$project$Reorder$sortablesWithPlaceholder = F3(
	function (dragIndex, dropIndex, tibles) {
		var theArray = $elm$core$Array$fromList(
			A2(
				$elm$core$List$map,
				function (t) {
					return $author$project$Type$Sortable$Tible(t);
				},
				tibles));
		var newArray = function () {
			var _v0 = _Utils_Tuple2(dropIndex, dragIndex);
			if ((!_v0.a.$) && (!_v0.b.$)) {
				var dropId = _v0.a.a;
				var dragId = _v0.b.a;
				return _Utils_eq(dropId, dragId) ? A3(
					$elm$core$Array$set,
					dropId,
					$author$project$Type$Sortable$Placeholder(dropId),
					theArray) : A3(
					$elm$core$Array$set,
					dropId,
					$author$project$Type$Sortable$Placeholder(dropId),
					$elm$core$Array$fromList(
						A2(
							$elm$core$List$map,
							function (t) {
								return $author$project$Type$Sortable$Tible(t);
							},
							A3($author$project$Reorder$reorderTibles, dragId, dropId, tibles))));
			} else {
				return theArray;
			}
		}();
		return $elm$core$Array$toList(newArray);
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$Dragging = function (a) {
	return {$: 1, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$updateCommon = F3(
	function (sticky, msg, model) {
		var _v0 = _Utils_Tuple3(msg, model, sticky);
		_v0$9:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					var dragId = _v1.a;
					return _Utils_Tuple2(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$Dragging(dragId),
						$elm$core$Maybe$Nothing);
				case 1:
					var _v2 = _v0.a;
					return _Utils_Tuple2($norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging, $elm$core$Maybe$Nothing);
				case 2:
					switch (_v0.b.$) {
						case 1:
							var dropId = _v0.a.a;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								A4($norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver, dragId, dropId, 0, $elm$core$Maybe$Nothing),
								$elm$core$Maybe$Nothing);
						case 2:
							var dropId = _v0.a.a;
							var _v3 = _v0.b;
							var dragId = _v3.a;
							var pos = _v3.d;
							return _Utils_Tuple2(
								A4($norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver, dragId, dropId, 0, pos),
								$elm$core$Maybe$Nothing);
						default:
							break _v0$9;
					}
				case 3:
					if ((_v0.b.$ === 2) && (!_v0.c)) {
						var dropId_ = _v0.a.a;
						var _v4 = _v0.b;
						var dragId = _v4.a;
						var dropId = _v4.b;
						return _Utils_eq(dropId_, dropId) ? _Utils_Tuple2(
							$norpan$elm_html5_drag_drop$Html5$DragDrop$Dragging(dragId),
							$elm$core$Maybe$Nothing) : _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
					} else {
						break _v0$9;
					}
				case 4:
					switch (_v0.b.$) {
						case 1:
							var _v5 = _v0.a;
							var dropId = _v5.a;
							var timeStamp = _v5.b;
							var pos = _v5.c;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								A4(
									$norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver,
									dragId,
									dropId,
									timeStamp,
									$elm$core$Maybe$Just(pos)),
								$elm$core$Maybe$Nothing);
						case 2:
							var _v6 = _v0.a;
							var dropId = _v6.a;
							var timeStamp = _v6.b;
							var pos = _v6.c;
							var _v7 = _v0.b;
							var dragId = _v7.a;
							var currentDropId = _v7.b;
							var currentTimeStamp = _v7.c;
							var currentPos = _v7.d;
							return _Utils_eq(timeStamp, currentTimeStamp) ? _Utils_Tuple2(model, $elm$core$Maybe$Nothing) : _Utils_Tuple2(
								A4(
									$norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver,
									dragId,
									dropId,
									timeStamp,
									$elm$core$Maybe$Just(pos)),
								$elm$core$Maybe$Nothing);
						default:
							break _v0$9;
					}
				default:
					switch (_v0.b.$) {
						case 1:
							var _v8 = _v0.a;
							var dropId = _v8.a;
							var pos = _v8.b;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								$norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging,
								$elm$core$Maybe$Just(
									_Utils_Tuple3(dragId, dropId, pos)));
						case 2:
							var _v9 = _v0.a;
							var dropId = _v9.a;
							var pos = _v9.b;
							var _v10 = _v0.b;
							var dragId = _v10.a;
							return _Utils_Tuple2(
								$norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging,
								$elm$core$Maybe$Just(
									_Utils_Tuple3(dragId, dropId, pos)));
						default:
							break _v0$9;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$update = $norpan$elm_html5_drag_drop$Html5$DragDrop$updateCommon(false);
var $author$project$Firebase$updateWall = _Platform_outgoingPort('updateWall', $elm$core$Basics$identity);
var $author$project$Type$Wall$wallPrintsEncoder = F2(
	function (id, prints) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(id)),
					_Utils_Tuple2(
					'printRefs',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, prints))
				]));
	});
var $author$project$Firebase$updateWallPrints = F2(
	function (printRefs, wallId) {
		return $author$project$Firebase$updateWall(
			A2($author$project$Type$Wall$wallPrintsEncoder, wallId, printRefs));
	});
var $author$project$Page$Home$Wall$updatePrintRefCmd = F2(
	function (model, tibles) {
		var printRefs = A2(
			$elm$core$List$map,
			function (t) {
				return 'prints/' + t.dU.di;
			},
			tibles);
		var _v0 = model.ab;
		if (!_v0.$) {
			var wallId = _v0.a;
			return A2($author$project$Firebase$updateWallPrints, printRefs, wallId);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Page$Home$Wall$updateDragDropMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($norpan$elm_html5_drag_drop$Html5$DragDrop$update, childMsg, model.an);
		var childModel = _v0.a;
		var result = _v0.b;
		var dragIndex = $norpan$elm_html5_drag_drop$Html5$DragDrop$getDragId(childModel);
		var dropIndex = $norpan$elm_html5_drag_drop$Html5$DragDrop$getDropId(childModel);
		var _v1 = function () {
			if (result.$ === 1) {
				return _Utils_Tuple3(
					A3($author$project$Reorder$sortablesWithPlaceholder, dragIndex, dropIndex, model.aD),
					model.aD,
					$elm$core$Platform$Cmd$none);
			} else {
				var _v3 = result.a;
				var dragId = _v3.a;
				var dropId = _v3.b;
				var reorderedTibles = A3($author$project$Reorder$reorderTibles, dragId, dropId, model.aD);
				var newCmdMsg = A2($author$project$Page$Home$Wall$updatePrintRefCmd, model, reorderedTibles);
				return _Utils_Tuple3(
					A2($elm$core$List$map, $author$project$Type$Sortable$Tible, reorderedTibles),
					reorderedTibles,
					newCmdMsg);
			}
		}();
		var newSortables = _v1.a;
		var newTibles = _v1.b;
		var cmdMsg = _v1.c;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{an: childModel, cy: newSortables, aD: newTibles}),
			cmdMsg);
	});
var $author$project$Page$Home$Wall$passThruToEditWallModalMsg = F3(
	function (subMsg, subModel, model) {
		var _v0 = A2($author$project$Modal$Picker$update, subMsg, subModel);
		var neweditModalModel = _v0.a;
		var cmdMsg = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					z: $elm$core$Maybe$Just(
						$author$project$Page$Home$Wall$EditWallModal(neweditModalModel))
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$Wall$EditWallModalMsg, cmdMsg));
	});
var $author$project$Page$Home$Wall$saveWall = F2(
	function (model, editModalModel) {
		var tibles = $elm$core$Dict$values(model.bv);
		var newTibles = A2(
			$elm$core$List$filter,
			function (t) {
				return A2($elm$core$List$member, t.dU.di, editModalModel.u);
			},
			tibles);
		var newSortables = A2($elm$core$List$map, $author$project$Type$Sortable$Tible, tibles);
		return _Utils_update(
			model,
			{z: $elm$core$Maybe$Nothing, cy: newSortables, aD: newTibles});
	});
var $author$project$Page$Home$Wall$updateEditWallModalMsg = F3(
	function (subMsg, subModel, model) {
		switch (subMsg.$) {
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{z: $elm$core$Maybe$Nothing}),
					$author$project$Firebase$setOverflow('auto'));
			case 3:
				var newModel = A2($author$project$Page$Home$Wall$saveWall, model, subModel);
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Page$Home$Wall$updatePrintRefCmd, model, newModel.aD),
								$author$project$Firebase$setOverflow('auto')
							])));
			case 10:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return A3($author$project$Page$Home$Wall$passThruToEditWallModalMsg, subMsg, subModel, model);
		}
	});
var $author$project$Page$Home$Wall$TibleModalMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Home$Wall$updateTibleModalMsg = F3(
	function (subMsg, subModel, model) {
		var _v0 = A2($author$project$Modal$Tible$update, subMsg, subModel);
		var newTibleModalModel = _v0.a;
		var newSubCmd = _v0.b;
		if (subMsg.$ === 1) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{z: $elm$core$Maybe$Nothing}),
				$author$project$Firebase$setOverflow('auto'));
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						z: $elm$core$Maybe$Just(
							$author$project$Page$Home$Wall$TibleModal(newTibleModalModel))
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$Wall$TibleModalMsg, newSubCmd));
		}
	});
var $author$project$Firebase$updateWallVisiblePrintNumber = _Platform_outgoingPort('updateWallVisiblePrintNumber', $elm$core$Basics$identity);
var $author$project$Type$Wall$wallVisibleIdsEncoder = F2(
	function (id, visibleIds) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(id)),
					_Utils_Tuple2(
					'visibleIds',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, visibleIds))
				]));
	});
var $author$project$Firebase$updateWallVisibleIds = F2(
	function (visibleIds, wallId) {
		return $author$project$Firebase$updateWallVisiblePrintNumber(
			A2($author$project$Type$Wall$wallVisibleIdsEncoder, wallId, visibleIds));
	});
var $author$project$Page$Home$Wall$updateVisibleIdsCmd = function (model) {
	var _v0 = model.ab;
	if (!_v0.$) {
		var wallId = _v0.a;
		return A2($author$project$Firebase$updateWallVisibleIds, model.w, wallId);
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Page$Home$Wall$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{z: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var newZone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cO: newZone}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var subMsg = msg.a;
				var _v1 = model.z;
				if ((!_v1.$) && (_v1.a.$ === 1)) {
					var subModel = _v1.a.a;
					return A3($author$project$Page$Home$Wall$updateEditWallModalMsg, subMsg, subModel, model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var subMsg = msg.a;
				var _v2 = model.z;
				if ((!_v2.$) && (!_v2.a.$)) {
					var subModel = _v2.a.a;
					return A3($author$project$Page$Home$Wall$updateTibleModalMsg, subMsg, subModel, model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var wallId = A2($elm$core$Maybe$withDefault, '', model.ab);
				var _v3 = A4(
					$author$project$Modal$Picker$init,
					model.d5,
					model.d6,
					model.dV,
					A2($author$project$Type$Modal$WallModal, model.aD, wallId));
				var subModel = _v3.a;
				var subMsg = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just(
								$author$project$Page$Home$Wall$EditWallModal(subModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Firebase$setOverflow('hidden'),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$Wall$EditWallModalMsg, subMsg)
							])));
			case 4:
				var tible = msg.a;
				var index = msg.b;
				var numOfTibles = $elm$core$String$fromInt(
					$elm$core$List$length(model.aD));
				var title = $elm$core$String$fromInt(1 + index) + (' of ' + numOfTibles);
				var _v4 = A4($author$project$Modal$Tible$init, model.d5, model.aD, title, tible);
				var tibleModalModel = _v4.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just(
								$author$project$Page$Home$Wall$TibleModal(tibleModalModel))
						}),
					$author$project$Firebase$setOverflow('hidden'));
			case 2:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ad: $elm$core$Maybe$Just(index)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ad: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var id = msg.a;
				var newTibles = A2(
					$elm$core$List$filter,
					function (p) {
						return !_Utils_eq(p.dU.di, id);
					},
					model.aD);
				var newSortables = A2(
					$elm$core$List$map,
					function (t) {
						return $author$project$Type$Sortable$Tible(t);
					},
					newTibles);
				var cmdMsg = A2($author$project$Page$Home$Wall$updatePrintRefCmd, model, newTibles);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ad: $elm$core$Maybe$Nothing,
							az: $author$project$Page$Home$Wall$countPrintsPerRow(
								$elm$core$List$length(newSortables)),
							cy: newSortables,
							aD: newTibles
						}),
					cmdMsg);
			case 6:
				var id = msg.a;
				var newVisibleIds = A2($elm$core$List$member, id, model.w) ? A2(
					$elm$core$List$filter,
					function (i) {
						return !_Utils_eq(i, id);
					},
					model.w) : _Utils_ap(
					model.w,
					_List_fromArray(
						[id]));
				var newModel = _Utils_update(
					model,
					{w: newVisibleIds});
				return _Utils_Tuple2(
					newModel,
					$author$project$Page$Home$Wall$updateVisibleIdsCmd(newModel));
			default:
				var dragMsg = msg.a;
				return A2($author$project$Page$Home$Wall$updateDragDropMsg, dragMsg, model);
		}
	});
var $author$project$Page$Home$updateWallMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$Home$Wall$update, childMsg, model.C);
		var wallModel = _v0.a;
		var wallCmd = _v0.b;
		if (childMsg.$ === 1) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{C: wallModel}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$WallSectionMsg, wallCmd));
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{C: wallModel}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$WallSectionMsg, wallCmd));
		}
	});
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var subMsg = msg.a;
				return A2($author$project$Page$Home$updateWallMsg, subMsg, model);
			case 2:
				var subMsg = msg.a;
				return A2($author$project$Page$Home$updateStackMsg, subMsg, model);
			case 3:
				var subMsg = msg.a;
				return A2($author$project$Page$Home$updatePageMsg, subMsg, model);
			case 4:
				var subMsg = msg.a;
				if (subMsg === 2) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{z: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				} else {
					var _v2 = model.z;
					if (!_v2.$) {
						var subModel = _v2.a;
						return A3($author$project$Page$Home$updateProfileMsg, subMsg, subModel, model);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 5:
				var _v3 = model.d5;
				if (_v3.$ === 3) {
					var user = _v3.b;
					var _v4 = A2($author$project$View$Profile$init, model.d5, user);
					var subModel = _v4.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								z: $elm$core$Maybe$Just(subModel)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{z: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateHomeMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Home$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$HomePage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$HomeMsg, subCmd));
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.ci;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.bR,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.cj,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.cc,
					_Utils_ap(http, url.bW)),
				url.ca)));
};
var $author$project$Update$updateLinkClicked = F2(
	function (urlRequest, model) {
		var navKey = $author$project$Type$Session$navKey(model.d5);
		if (!urlRequest.$) {
			var url = urlRequest.a;
			return _Utils_Tuple2(
				model,
				A2(
					$elm$browser$Browser$Navigation$pushUrl,
					navKey,
					$elm$url$Url$toString(url)));
		} else {
			var href = urlRequest.a;
			return _Utils_Tuple2(
				model,
				$elm$browser$Browser$Navigation$load(href));
		}
	});
var $author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Page$Market$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Market$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.dR);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dR: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Market$PageMsg, pageCmd));
	});
var $author$project$Page$Market$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var setId = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Route$pushUrl,
						$author$project$Type$Session$navKey(model.d5),
						$author$project$Route$SetPacks(setId)));
			case 1:
				var subMsg = msg.a;
				return A2($author$project$Page$Market$updatePageMsg, subMsg, model);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateMarketMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Market$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$MarketPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$MarketMsg, subCmd));
	});
var $author$project$Msg$PageMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Update$updatePageMsg = F2(
	function (model, subMsg) {
		var _v0 = function () {
			if (!subMsg.$) {
				var index = subMsg.a;
				var newAlerts = A2($elm_community$list_extra$List$Extra$removeAt, index, model.cQ);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cQ: newAlerts}),
					$elm$core$Platform$Cmd$none);
			} else {
				var _v2 = A2($author$project$Page$update, subMsg, model.dR);
				var newPageModel = _v2.a;
				var newPageSubMsg = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dR: newPageModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Msg$PageMsg, newPageSubMsg));
			}
		}();
		var newModel = _v0.a;
		var newSubMsg = _v0.b;
		return _Utils_Tuple2(newModel, newSubMsg);
	});
var $author$project$Update$updatePageSubModel = F2(
	function (model, page) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dQ: page}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Update$updatePrintRemoved = F2(
	function (model, printId) {
		var newPrintDict = A2($elm$core$Dict$remove, printId, model.dV);
		var newModel = _Utils_update(
			model,
			{dV: newPrintDict});
		var _v0 = model.dQ;
		switch (_v0.$) {
			case 0:
				var subModel = _v0.a;
				var newSubModel = A3($author$project$Page$Home$updateSetsAndPrints, model.d6, newPrintDict, subModel);
				return A2(
					$author$project$Update$updatePageSubModel,
					model,
					$author$project$Type$Page$HomePage(newSubModel));
			case 1:
				var subModel = _v0.a;
				return A2(
					$author$project$Update$updatePageSubModel,
					model,
					$author$project$Type$Page$StackPage(
						_Utils_update(
							subModel,
							{dV: newPrintDict})));
			default:
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$Session$updateProfile = F2(
	function (session, profile) {
		if (session.$ === 3) {
			var par = session.a;
			var user = session.b;
			return A2(
				$author$project$Type$Session$SignedIn,
				par,
				_Utils_update(
					user,
					{
						dz: $elm$core$Maybe$Just(profile)
					}));
		} else {
			return session;
		}
	});
var $author$project$Update$updateProfileChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$User$profileDecoder, data);
		if (!_v0.$) {
			var profile = _v0.a;
			var newSession = A2($author$project$Type$Session$updateProfile, model.d5, profile);
			var newModel = _Utils_update(
				model,
				{d5: newSession});
			var updateProfileInPage = F2(
				function (page, newSubModel) {
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: page(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 0:
					var subModel = _v1.a;
					return A2(
						updateProfileInPage,
						$author$project$Type$Page$HomePage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				case 7:
					var subModel = _v1.a;
					return A2(
						updateProfileInPage,
						$author$project$Type$Page$ProfilePage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				case 3:
					var subModel = _v1.a;
					return A2(
						updateProfileInPage,
						$author$project$Type$Page$CollectionPage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				case 4:
					var subModel = _v1.a;
					return A2(
						updateProfileInPage,
						$author$project$Type$Page$MarketPage,
						_Utils_update(
							subModel,
							{d5: newSession}));
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cQ: _List_fromArray(
							[alert])
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Profile$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Profile$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.dR);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dR: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Profile$PageMsg, pageCmd));
	});
var $author$project$Page$Profile$update = F2(
	function (msg, model) {
		if (msg.$ === 1) {
			var subMsg = msg.a;
			return A2($author$project$Page$Profile$updatePageMsg, subMsg, model);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateProfileMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Profile$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$ProfilePage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$ProfileMsg, subCmd));
	});
var $author$project$Update$updateStacksAtHome = F2(
	function (model, homeModel) {
		var newHomeModel = _Utils_update(
			homeModel,
			{
				G: A2($author$project$Page$Home$Stacks$updateStacksContent, model.d8, homeModel.G)
			});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$HomePage(newHomeModel)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Update$updateStacksAtStack = function (model) {
	var id = function () {
		var _v1 = model.dA;
		if ((!_v1.$) && (_v1.a.$ === 4)) {
			var pageId = _v1.a.a;
			return pageId;
		} else {
			return '';
		}
	}();
	var selDrawer = A2($author$project$Type$Stack$fromList, id, model.d8);
	var _v0 = A4($author$project$Page$Stack$init, model.d5, model.d6, model.dV, selDrawer);
	var newDrawerModel = _v0.a;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				dQ: $author$project$Type$Page$StackPage(newDrawerModel)
			}),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Update$updateRemoveStack = F2(
	function (model, stackId) {
		var newStacks = A2(
			$elm$core$List$filter,
			function (d) {
				return !_Utils_eq(d.di, stackId);
			},
			model.d8);
		var newModel = _Utils_update(
			model,
			{d8: newStacks});
		var _v0 = model.dQ;
		switch (_v0.$) {
			case 0:
				var homeModel = _v0.a;
				return A2($author$project$Update$updateStacksAtHome, newModel, homeModel);
			case 1:
				return $author$project$Update$updateStacksAtStack(newModel);
			default:
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$CSet$CSet = function (datePublished) {
	return function (description) {
		return function (editionType) {
			return function (id) {
				return function (imageUrl) {
					return function (itemCount) {
						return function (itemTemplate) {
							return function (items) {
								return function (status) {
									return function (tags) {
										return function (title) {
											return function (variants) {
												return {c4: datePublished, c5: description, c8: editionType, di: id, dj: imageUrl, dt: itemCount, aJ: itemTemplate, bd: items, aC: status, aV: tags, ef: title, ek: variants};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Item$Item = function (content) {
	return function (countMax) {
		return function (images) {
			return function (itemId) {
				return function (itemNumber) {
					return function (ratio) {
						return function (status) {
							return function (tags) {
								return function (template) {
									return function (title) {
										return {a6: content, bJ: countMax, dk: images, du: itemId, bb: itemNumber, aP: ratio, aC: status, aV: tags, bu: template, ef: title};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Item$Content = F3(
	function (maybeBackImageUrl, maybeBackgroundImageUrl, cornerRadius) {
		return {c_: cornerRadius, dx: maybeBackImageUrl, dy: maybeBackgroundImageUrl};
	});
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Type$Item$contentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'cornerRadius',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'backgroundImageUrl',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'backImageUrl',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Type$Item$Content))));
var $author$project$Type$Item$VariantImage = F2(
	function (imageUrl, variantId) {
		return {dj: imageUrl, cI: variantId};
	});
var $author$project$Type$Item$variantImageDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'variantId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'imageUrl',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Type$Item$VariantImage)));
var $author$project$Type$Item$itemDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'template',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'ratio',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'itemNumber',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'itemId',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'images',
								$elm$json$Json$Decode$list($author$project$Type$Item$variantImageDecoder),
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'countMax',
									$elm$json$Json$Decode$int,
									0,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'content',
										$author$project$Type$Item$contentDecoder,
										$elm$json$Json$Decode$succeed($author$project$Type$Item$Item)))))))))));
var $author$project$Type$Variant$Variant = F9(
	function (content, maybeCountMax, maybeBatchSize, imageUrl, rarityFactor, title, titlePlural, variantId, count) {
		return {a6: content, bI: count, dj: imageUrl, au: maybeBatchSize, av: maybeCountMax, ae: rarityFactor, ef: title, aX: titlePlural, cI: variantId};
	});
var $author$project$Type$Variant$Content = F2(
	function (backgroundColor, maybeBackgroundImageUrl) {
		return {a$: backgroundColor, dy: maybeBackgroundImageUrl};
	});
var $author$project$Type$Variant$contentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'backgroundImageUrl',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'backgroundColor',
		$elm$json$Json$Decode$string,
		'#ffffff',
		$elm$json$Json$Decode$succeed($author$project$Type$Variant$Content)));
var $author$project$Type$Variant$variantDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'count',
	$elm$json$Json$Decode$int,
	0,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'variantId',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'titlePlural',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'title',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'rarityFactor',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'imageUrl',
						$elm$json$Json$Decode$string,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'batchSize',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'countMax',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'content',
									$author$project$Type$Variant$contentDecoder,
									$elm$json$Json$Decode$succeed($author$project$Type$Variant$Variant))))))))));
var $author$project$Type$CSet$csetDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'variants',
	$elm$json$Json$Decode$list($author$project$Type$Variant$variantDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'items',
					$elm$json$Json$Decode$list($author$project$Type$Item$itemDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'itemTemplate',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'itemCount',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'imageUrl',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'editionType',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'description',
											$elm$json$Json$Decode$string,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'datePublished',
												$elm$json$Json$Decode$int,
												0,
												$elm$json$Json$Decode$succeed($author$project$Type$CSet$CSet)))))))))))));
var $author$project$Page$Collection$gotSetDict = F2(
	function (model, setDict) {
		var tibleDict = A2($author$project$Type$Tible$toDict, model.dV, setDict);
		var newModel = _Utils_update(
			model,
			{d6: setDict, bv: tibleDict});
		var collectionViewModel = model.g;
		return _Utils_update(
			newModel,
			{
				g: _Utils_update(
					collectionViewModel,
					{d6: setDict, bv: tibleDict})
			});
	});
var $author$project$Update$updateSetChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$CSet$csetDecoder, data);
		if (!_v0.$) {
			var set = _v0.a;
			var newSetDict = A3($elm$core$Dict$insert, set.di, set, model.d6);
			var newModel = _Utils_update(
				model,
				{d6: newSetDict});
			var updateGotSetInPage = F2(
				function (page, newSubModel) {
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								dQ: page(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 4:
					var subModel = _v1.a;
					return A2(
						$author$project$Update$updatePageSubModel,
						newModel,
						$author$project$Type$Page$MarketPage(
							_Utils_update(
								subModel,
								{d6: newSetDict})));
				case 3:
					var subModel = _v1.a;
					return A2(
						updateGotSetInPage,
						$author$project$Type$Page$CollectionPage,
						A2($author$project$Page$Collection$gotSetDict, subModel, newSetDict));
				case 9:
					var subModel = _v1.a;
					var newSubModel = _Utils_eq(subModel.d7, set.di) ? _Utils_update(
						subModel,
						{
							bg: $elm$core$Maybe$Just(set),
							d6: newSetDict
						}) : _Utils_update(
						subModel,
						{d6: newSetDict});
					return A2(
						$author$project$Update$updatePageSubModel,
						newModel,
						$author$project$Type$Page$SetPacksPage(newSubModel));
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cQ: _List_fromArray(
							[alert]),
						d6: model.d6
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Modal$Buy$Open = 0;
var $author$project$Modal$Buy$init = F4(
	function (session, setDict, account, pack) {
		return _Utils_Tuple2(
			{
				a_: account,
				aa: $elm$core$Maybe$Nothing,
				ay: pack,
				cs: $author$project$Type$Session$screenSize(session),
				d5: session,
				d6: setDict,
				aC: 0,
				aD: _List_Nil
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Modal$Buy$Buying = 1;
var $author$project$Firebase$buy = _Platform_outgoingPort('buy', $elm$core$Basics$identity);
var $author$project$Type$Pack$buyEncoder = F2(
	function (uid, packId) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'uid',
					$elm$json$Json$Encode$string(uid)),
					_Utils_Tuple2(
					'packId',
					$elm$json$Json$Encode$string(packId))
				]));
	});
var $author$project$Modal$Buy$TibleModalMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Modal$Buy$updateTibleModalMsg = F2(
	function (subMsg, model) {
		if (subMsg.$ === 1) {
			return A2($author$project$Modal$Buy$updateShow, model.aD, model);
		} else {
			var _v1 = function () {
				var _v2 = model.aa;
				if (!_v2.$) {
					var printModalModel = _v2.a;
					var _v3 = A2($author$project$Modal$Tible$update, subMsg, printModalModel);
					var newPrintModalModel = _v3.a;
					var subCmd = _v3.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(newPrintModalModel),
						subCmd);
				} else {
					return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				}
			}();
			var maybeTibleModalModel = _v1.a;
			var newSubCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{aa: maybeTibleModalModel}),
				A2($elm$core$Platform$Cmd$map, $author$project$Modal$Buy$TibleModalMsg, newSubCmd));
		}
	});
var $author$project$Type$Session$userId = function (session) {
	switch (session.$) {
		case 3:
			var user = session.b;
			return user.eg;
		case 2:
			var user = session.b;
			return user.eg;
		default:
			return '';
	}
};
var $author$project$Modal$Buy$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var prodId = msg.a;
				var userId = $author$project$Type$Session$userId(model.d5);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aC: 1}),
					$author$project$Firebase$buy(
						A2($author$project$Type$Pack$buyEncoder, userId, prodId)));
			case 1:
				var data = msg.a;
				var _v1 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Print$printListDecoder, data);
				if (!_v1.$) {
					var prints = _v1.a;
					var tibles = A2(
						$elm$core$List$filterMap,
						function (t) {
							return t;
						},
						A2(
							$elm$core$List$map,
							function (p) {
								return A2(
									$elm$core$Maybe$andThen,
									$author$project$Type$Tible$fromPrint(p),
									A2($elm$core$Dict$get, p.d7, model.d6));
							},
							prints));
					return A2($author$project$Modal$Buy$updateShow, tibles, model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var tibles = msg.a;
				return A2($author$project$Modal$Buy$updateShow, tibles, model);
			case 4:
				var subMsg = msg.a;
				return A2($author$project$Modal$Buy$updateTibleModalMsg, subMsg, model);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$SetPacks$passThruToBuyModalMsg = F2(
	function (subMsg, model) {
		var _v0 = model.D;
		if (!_v0.$) {
			var subModel = _v0.a;
			var _v1 = A2($author$project$Modal$Buy$update, subMsg, subModel);
			var newSubModel = _v1.a;
			var newCmdMsg = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						D: $elm$core$Maybe$Just(newSubModel)
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$SetPacks$BuyModalMsg, newCmdMsg));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$SetPacks$updateBuyModalMsg = F2(
	function (subMsg, model) {
		if (subMsg.$ === 3) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{D: $elm$core$Maybe$Nothing}),
				$author$project$Firebase$setOverflow('auto'));
		} else {
			return A2($author$project$Page$SetPacks$passThruToBuyModalMsg, subMsg, model);
		}
	});
var $author$project$Page$SetPacks$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var pack = msg.a;
				var account = msg.b;
				var _v1 = A4($author$project$Modal$Buy$init, model.d5, model.d6, account, pack);
				var subModel = _v1.a;
				var subMsg = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: $elm$core$Maybe$Just(subModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$SetPacks$BuyModalMsg, subMsg));
			case 2:
				var subMsg = msg.a;
				return A2($author$project$Page$SetPacks$updateBuyModalMsg, subMsg, model);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateSetPacksMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$SetPacks$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$SetPacksPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetPacksMsg, subCmd));
	});
var $author$project$Update$updateSetRemoved = F2(
	function (model, setId) {
		var newSetDict = A2($elm$core$Dict$remove, setId, model.d6);
		var newModel = _Utils_update(
			model,
			{d6: newSetDict});
		var _v0 = model.dQ;
		switch (_v0.$) {
			case 4:
				var subModel = _v0.a;
				return A2(
					$author$project$Update$updatePageSubModel,
					model,
					$author$project$Type$Page$MarketPage(
						_Utils_update(
							subModel,
							{d6: newSetDict})));
			case 3:
				var subModel = _v0.a;
				return A2(
					$author$project$Update$updatePageSubModel,
					model,
					$author$project$Type$Page$CollectionPage(
						_Utils_update(
							subModel,
							{d6: newSetDict})));
			case 9:
				var subModel = _v0.a;
				var newSubModel = _Utils_eq(subModel.d7, setId) ? _Utils_update(
					subModel,
					{bg: $elm$core$Maybe$Nothing, d6: newSetDict}) : _Utils_update(
					subModel,
					{d6: newSetDict});
				return A2(
					$author$project$Update$updatePageSubModel,
					model,
					$author$project$Type$Page$SetPacksPage(newSubModel));
			default:
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Stack$EditStackModalMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Reorder$reorderSortables = F3(
	function (dragId, dropId, items) {
		var shift = function (draggedItem) {
			var _v0 = A2(
				$elm_community$list_extra$List$Extra$splitAt,
				dropId,
				A2($elm_community$list_extra$List$Extra$removeAt, dragId, items));
			var itemsA = _v0.a;
			var itemsB = _v0.b;
			return $elm$core$List$concat(
				_List_fromArray(
					[
						itemsA,
						_List_fromArray(
						[draggedItem]),
						itemsB
					]));
		};
		var newItems = A2(
			$elm$core$Maybe$withDefault,
			items,
			A2(
				$elm$core$Maybe$map,
				shift,
				A2($elm_community$list_extra$List$Extra$getAt, dragId, items)));
		return newItems;
	});
var $author$project$Type$Sortable$toTible = function (sortable) {
	if (!sortable.$) {
		var tible = sortable.a;
		return $elm$core$Maybe$Just(tible);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Stack$updateTiblesToModel = F2(
	function (tibles, model) {
		var stack = model.v;
		var newSortables = A2($elm$core$List$map, $author$project$Type$Sortable$Tible, tibles);
		var newPrintRefs = A2(
			$elm$core$List$filterMap,
			function (x) {
				return x;
			},
			A2(
				$elm$core$List$map,
				function (p) {
					return $author$project$Type$DocRef$fromPath(p);
				},
				A2(
					$elm$core$List$map,
					function (p) {
						return 'prints/' + p.dU.di;
					},
					tibles)));
		var newStack = _Utils_update(
			stack,
			{dX: newPrintRefs});
		return _Utils_update(
			model,
			{cy: newSortables, v: newStack, aD: tibles});
	});
var $author$project$Page$Stack$updateDragDropMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($norpan$elm_html5_drag_drop$Html5$DragDrop$update, childMsg, model.an);
		var childModel = _v0.a;
		var result = _v0.b;
		var dragIndex = $norpan$elm_html5_drag_drop$Html5$DragDrop$getDragId(childModel);
		var dropIndex = $norpan$elm_html5_drag_drop$Html5$DragDrop$getDropId(childModel);
		var _v1 = function () {
			if (result.$ === 1) {
				return _Utils_Tuple3(
					A3($author$project$Reorder$sortablesWithPlaceholder, dragIndex, dropIndex, model.aD),
					model.aD,
					$elm$core$Platform$Cmd$none);
			} else {
				var _v3 = result.a;
				var dragId = _v3.a;
				var dropId = _v3.b;
				var reorderedTibles = A2(
					$elm$core$List$filterMap,
					function (x) {
						return x;
					},
					A2(
						$elm$core$List$map,
						$author$project$Type$Sortable$toTible,
						A3(
							$author$project$Reorder$reorderSortables,
							dragId,
							dropId,
							A2(
								$elm$core$List$map,
								function (t) {
									return $author$project$Type$Sortable$Tible(t);
								},
								model.aD))));
				var newModel = A2($author$project$Page$Stack$updateTiblesToModel, reorderedTibles, model);
				var newCmdMsg = $author$project$Firebase$updateStack(
					$author$project$Type$Stack$stackEncoder(newModel.v));
				return _Utils_Tuple3(newModel.cy, newModel.aD, newCmdMsg);
			}
		}();
		var newSortables = _v1.a;
		var newTibles = _v1.b;
		var cmdMsg = _v1.c;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{an: childModel, cy: newSortables, aD: newTibles}),
			cmdMsg);
	});
var $author$project$Page$Stack$passThruToEditStackModalMsg = F2(
	function (subMsg, model) {
		var _v0 = model.x;
		if (!_v0.$) {
			var editModalModel = _v0.a;
			var _v1 = A2($author$project$Modal$Picker$update, subMsg, editModalModel);
			var newEditModalModel = _v1.a;
			var cmdMsg = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						x: $elm$core$Maybe$Just(newEditModalModel)
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Stack$EditStackModalMsg, cmdMsg));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Page$initEditable = A3($author$project$Page$EditableField, '', '', false);
var $author$project$Page$Stack$saveStack = F2(
	function (model, editModalModel) {
		var stack = model.v;
		var newTitle = function () {
			var _v1 = editModalModel.y;
			if (!_v1.$) {
				var editableTitle = _v1.a;
				return editableTitle.H;
			} else {
				return model.ef;
			}
		}();
		var newTibles = $elm$core$Dict$values(
			A2(
				$elm$core$Dict$filter,
				F2(
					function (k, _v0) {
						return A2($elm$core$List$member, k, editModalModel.u);
					}),
				model.bv));
		var newSortables = A2($elm$core$List$map, $author$project$Type$Sortable$Tible, newTibles);
		var newPrintRefs = A2(
			$elm$core$List$filterMap,
			function (x) {
				return x;
			},
			A2(
				$elm$core$List$map,
				function (p) {
					return $author$project$Type$DocRef$fromPath(p);
				},
				A2(
					$elm$core$List$map,
					function (t) {
						return 'prints/' + t.dU.di;
					},
					newTibles)));
		var newStack = _Utils_update(
			stack,
			{dX: newPrintRefs, ef: newTitle});
		return _Utils_update(
			model,
			{
				aI: $author$project$Page$initEditable,
				x: $elm$core$Maybe$Just(editModalModel),
				u: editModalModel.u,
				cy: newSortables,
				v: newStack,
				aB: newStack.ef,
				aD: newTibles
			});
	});
var $author$project$Page$Stack$updateEditStackModalMsg = F2(
	function (subMsg, model) {
		switch (subMsg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{x: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return A2($author$project$Page$Stack$passThruToEditStackModalMsg, subMsg, model);
			case 3:
				var _v1 = model.x;
				if (!_v1.$) {
					var editModalModel = _v1.a;
					var _v2 = A2($author$project$Modal$Picker$update, subMsg, editModalModel);
					var newEditModalModel = _v2.a;
					var cmdMsg = _v2.b;
					var newModel = A2($author$project$Page$Stack$saveStack, model, newEditModalModel);
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Firebase$updateStack(
									$author$project$Type$Stack$stackEncoder(newModel.v)),
									A2($elm$core$Platform$Cmd$map, $author$project$Page$Stack$EditStackModalMsg, cmdMsg)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return A2($author$project$Page$Stack$passThruToEditStackModalMsg, subMsg, model);
		}
	});
var $author$project$Page$Stack$PageMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Stack$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.dR);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dR: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Stack$PageMsg, pageCmd));
	});
var $author$project$Page$Stack$TibleModalMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Stack$updateTibleModalMsg = F2(
	function (subMsg, model) {
		if (subMsg.$ === 1) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{aa: $elm$core$Maybe$Nothing}),
				$elm$core$Platform$Cmd$none);
		} else {
			var _v1 = function () {
				var _v2 = model.aa;
				if (!_v2.$) {
					var printModalModel = _v2.a;
					var _v3 = A2($author$project$Modal$Tible$update, subMsg, printModalModel);
					var newPrintModalModel = _v3.a;
					var subCmd = _v3.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(newPrintModalModel),
						subCmd);
				} else {
					return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
				}
			}();
			var maybePrintModalModel = _v1.a;
			var newSubCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{aa: maybePrintModalModel}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Stack$TibleModalMsg, newSubCmd));
		}
	});
var $author$project$Page$Stack$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 11:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 7:
				var subMsg = msg.a;
				return A2($author$project$Page$Stack$updateEditStackModalMsg, subMsg, model);
			case 8:
				var subMsg = msg.a;
				return A2($author$project$Page$Stack$updateTibleModalMsg, subMsg, model);
			case 3:
				var dragMsg = msg.a;
				return A2($author$project$Page$Stack$updateDragDropMsg, dragMsg, model);
			case 4:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aL: $elm$core$Maybe$Just(index)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aL: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var id = msg.a;
				var newTibles = A2(
					$elm$core$List$filter,
					function (p) {
						return !_Utils_eq(p.dU.di, id);
					},
					model.aD);
				var newModel = A2($author$project$Page$Stack$updateTiblesToModel, newTibles, model);
				var cmdMsg = $author$project$Firebase$updateStack(
					$author$project$Type$Stack$stackEncoder(newModel.v));
				return _Utils_Tuple2(newModel, cmdMsg);
			case 1:
				var _v1 = A4(
					$author$project$Modal$Picker$init,
					model.d5,
					model.d6,
					model.dV,
					$author$project$Type$Modal$StackModal(model.v));
				var subModel = _v1.a;
				var subMsg = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							x: $elm$core$Maybe$Just(subModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Stack$EditStackModalMsg, subMsg));
			case 0:
				var newEditableTitle = A3($author$project$Page$EditableField, $author$project$Type$Stack$emptyStack.ef, $author$project$Type$Stack$emptyStack.ef, false);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: newEditableTitle, aD: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 10:
				var tible = msg.a;
				var index = msg.b;
				var totalNum = $elm$core$String$fromInt(
					$elm$core$List$length(model.aD));
				var num = $elm$core$String$fromInt(1 + index);
				var title = num + (' of ' + totalNum);
				var _v2 = A4($author$project$Modal$Tible$init, model.d5, model.aD, title, tible);
				var tibleModalModel = _v2.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aa: $elm$core$Maybe$Just(tibleModalModel)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var subMsg = msg.a;
				return A2($author$project$Page$Stack$updatePageMsg, subMsg, model);
		}
	});
var $author$project$Update$updateStackMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Stack$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$StackPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$StackMsg, subCmd));
	});
var $author$project$Update$mergeDrawers = F2(
	function (old, _new) {
		var _v0 = $elm$core$List$length(old);
		if (!_v0) {
			return A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.bl;
				},
				_new);
		} else {
			var newIds = A2(
				$elm$core$List$map,
				function (d) {
					return d.di;
				},
				_new);
			var newOld = A2(
				$elm$core$List$filter,
				function (d) {
					return !A2($elm$core$List$member, d.di, newIds);
				},
				old);
			return A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.bl;
				},
				_Utils_ap(newOld, _new));
		}
	});
var $author$project$Type$Stack$stackDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'printRefs',
		$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'order',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Type$Stack$Stack)))));
var $author$project$Update$updateStacksChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Stack$stackDecoder, data);
		if (!_v0.$) {
			var stack = _v0.a;
			var newStacks = A2(
				$author$project$Update$mergeDrawers,
				model.d8,
				_List_fromArray(
					[stack]));
			var newModel = _Utils_update(
				model,
				{d8: newStacks});
			var _v1 = model.dQ;
			switch (_v1.$) {
				case 0:
					var homeModel = _v1.a;
					return A2($author$project$Update$updateStacksAtHome, newModel, homeModel);
				case 1:
					return $author$project$Update$updateStacksAtStack(newModel);
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cQ: _List_fromArray(
							[alert]),
						d8: _List_Nil
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateUrlChanged = F2(
	function (url, model) {
		var maybeRoute = A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url);
		var _v0 = A2($author$project$Update$pageForRoute, model, maybeRoute);
		var newModel = _v0.a;
		var subCmdMsg = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				newModel,
				{dA: maybeRoute, cH: url}),
			subCmdMsg);
	});
var $author$project$Update$updateWallAtHome = F3(
	function (model, homeModel, wall) {
		var newHomeModel = _Utils_update(
			homeModel,
			{
				C: A2($author$project$Page$Home$Wall$updateWallContent, wall, homeModel.C)
			});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dQ: $author$project$Type$Page$HomePage(newHomeModel)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Type$Wall$Wall = F5(
	function (id, idsOfVisiblePrintNumbers, pagesToDisplay, printRefs, userId) {
		return {di: id, w: idsOfVisiblePrintNumbers, dS: pagesToDisplay, dX: printRefs, ei: userId};
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Type$Wall$wallDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'userId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'printRefs',
		$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'pagesToDisplay',
			$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
			$elm$core$Dict$empty,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'idsOfVisiblePrintNumbers',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				_List_Nil,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Type$Wall$Wall))))));
var $author$project$Update$updateWallChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Wall$wallDecoder, data);
		if (!_v0.$) {
			var wall = _v0.a;
			var newModel = _Utils_update(
				model,
				{
					dC: $elm$core$Maybe$Just(wall)
				});
			var _v1 = model.dQ;
			if (!_v1.$) {
				var homeModel = _v1.a;
				return A3($author$project$Update$updateWallAtHome, newModel, homeModel, wall);
			} else {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dC: $elm$core$Maybe$Just(wall)
						}),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.dI);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cQ: _List_fromArray(
							[alert]),
						dC: $elm$core$Maybe$Nothing
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.dQ);
		_v0$32:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var urlRequest = _v0.a.a;
					return A2($author$project$Update$updateLinkClicked, urlRequest, model);
				case 1:
					var url = _v0.a.a;
					return A2($author$project$Update$updateUrlChanged, url, model);
				case 3:
					if (!_v0.b.$) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateHomeMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 4:
					if (_v0.b.$ === 1) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateStackMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 6:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateCollectionMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 8:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateMarketMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 11:
					if (_v0.b.$ === 9) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateSetPacksMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 9:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateClubhouseMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 13:
					if (_v0.b.$ === 7) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateProfileMsg, model, subMsg, subModel);
					} else {
						break _v0$32;
					}
				case 5:
					if (_v0.b.$ === 2) {
						if (_v0.a.a.$ === 1) {
							var _v1 = _v0.a.a;
							var subModel = _v0.b.a;
							return A2($author$project$Update$updateGetStartedForCheckEmail, model, subModel);
						} else {
							var subMsg = _v0.a.a;
							var subModel = _v0.b.a;
							return A3($author$project$Update$updateGetStarted, model, subMsg, subModel);
						}
					} else {
						break _v0$32;
					}
				case 14:
					var data = _v0.a.a;
					return A2($author$project$Update$updateAuthStateChanged, model, data);
				case 16:
					var data = _v0.a.a;
					return A2($author$project$Update$updateProfileChanged, model, data);
				case 17:
					var data = _v0.a.a;
					return A2($author$project$Update$updateWallChanged, model, data);
				case 19:
					var data = _v0.a.a;
					return A2($author$project$Update$updateSetChanged, model, data);
				case 20:
					var id = _v0.a.a;
					return A2($author$project$Update$updateSetRemoved, model, id);
				case 18:
					var data = _v0.a.a;
					return A2($author$project$Update$updateStacksChanged, model, data);
				case 15:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotAlert, model, data);
				case 33:
					var subMsg = _v0.a.a;
					return A2($author$project$Update$updateAlertView, model, subMsg);
				case 12:
					var subMsg = _v0.a.a;
					return A2($author$project$Update$updatePageMsg, model, subMsg);
				case 22:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotPrint, model, data);
				case 23:
					var id = _v0.a.a;
					return A2($author$project$Update$updatePrintRemoved, model, id);
				case 21:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotPack, model, data);
				case 24:
					var id = _v0.a.a;
					return A2($author$project$Update$updateRemoveStack, model, id);
				case 25:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotRoom, model, data);
				case 30:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotChatMessage, model, data);
				case 29:
					var data = _v0.a.a;
					return A2($author$project$Update$updateAccountChanged, model, data);
				case 27:
					return _Utils_Tuple2(
						model,
						A2($elm$core$Task$perform, $author$project$Msg$GotViewport, $elm$browser$Browser$Dom$getViewport));
				case 26:
					var _v2 = _v0.a;
					return _Utils_Tuple2(
						model,
						A2($elm$core$Task$perform, $author$project$Msg$GotViewport, $elm$browser$Browser$Dom$getViewport));
				case 28:
					var viewport = _v0.a.a;
					return A2($author$project$Update$updateGotViewport, model, viewport);
				case 32:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotNewPrints, model, data);
				case 34:
					var time = _v0.a.a;
					var now = $elm$time$Time$posixToMillis(time);
					var newAlerts = A2(
						$elm$core$List$filter,
						function (a) {
							return (_Utils_cmp(a.c3 + $author$project$Ox$Theme$alertTimeOut, now) > 0) && (a.$7 === '');
						},
						model.cQ);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cQ: newAlerts,
								dI: $elm$time$Time$posixToMillis(time)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					break _v0$32;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Msg$AlertMsg = function (a) {
	return {$: 33, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Ox$Alert$RemoveAlert = $elm$core$Basics$identity;
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Ox$Icon$cancel = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Theme$colorDanger = '#B00020';
var $author$project$Ox$Theme$colorDark = '#333';
var $author$project$Ox$Theme$colorLight = '#ddd';
var $author$project$Ox$Theme$colorSuccess = 'ForestGreen';
var $author$project$Ox$Theme$colorWarning = 'Gold';
var $author$project$Ox$Theme$colorWhite = 'White';
var $author$project$Ox$Theme$colorsForAlertKind = function (kind) {
	switch (kind) {
		case 'success':
			return _Utils_Tuple2($author$project$Ox$Theme$colorDark, $author$project$Ox$Theme$colorSuccess);
		case 'warning':
			return _Utils_Tuple2($author$project$Ox$Theme$colorDark, $author$project$Ox$Theme$colorWarning);
		case 'error':
			return _Utils_Tuple2($author$project$Ox$Theme$colorWhite, $author$project$Ox$Theme$colorDanger);
		default:
			return _Utils_Tuple2($author$project$Ox$Theme$colorDark, $author$project$Ox$Theme$colorLight);
	}
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Ox$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{b4: msg, dT: true, eb: true}));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Ox$Alert$viewAlert = F2(
	function (index, alert) {
		var _v0 = $author$project$Ox$Theme$colorsForAlertKind(alert.b1);
		var color = _v0.a;
		var bgColor = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'position', 'relative'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '8px'),
					A2($elm$html$Html$Attributes$style, 'width', '90%'),
					A2($elm$html$Html$Attributes$style, 'padding', '12px 12px'),
					A2($elm$html$Html$Attributes$style, 'margin-bottom', '8px'),
					A2($elm$html$Html$Attributes$style, 'background-color', bgColor),
					A2($elm$html$Html$Attributes$style, 'color', color),
					A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)'),
					A2($elm$html$Html$Attributes$style, 'min-width', '240px'),
					A2($elm$html$Html$Attributes$style, 'max-width', '328px'),
					A2($elm$html$Html$Attributes$style, 'max-height', '400px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
							A2($elm$html$Html$Attributes$style, 'text-align', 'left'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '30px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'font-size', '15px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(alert.b4)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Ox$Events$onClick(index),
							A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2($elm$html$Html$Attributes$style, 'right', '10px'),
							A2($elm$html$Html$Attributes$style, 'top', '10px'),
							A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
							A2($elm$html$Html$Attributes$style, 'height', '24px'),
							A2($elm$html$Html$Attributes$style, 'width', '24px')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$cancel(color)
						]))
				]));
	});
var $author$project$Ox$Alert$view = function (alerts) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'flex-end'),
				A2($elm$html$Html$Attributes$style, 'justify-content', 'flex-end'),
				A2($elm$html$Html$Attributes$style, 'bottom', '50px'),
				A2($elm$html$Html$Attributes$style, 'right', '40px'),
				A2($elm$html$Html$Attributes$style, 'z-index', '5000'),
				A2($elm$html$Html$Attributes$style, 'border', '1px solid red')
			]),
		A2($elm$core$List$indexedMap, $author$project$Ox$Alert$viewAlert, alerts));
};
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $elm$html$Html$Lazy$lazy2 = $elm$virtual_dom$VirtualDom$lazy2;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$View$Navigation$viewLink = F3(
	function (path, title, color) {
		return A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('link dim dib mr3 ' + color),
							$elm$html$Html$Attributes$href(path)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$View$Navigation$viewGuest = A2(
	$elm$html$Html$header,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('bg-black-90 avenir fixed w-100 ph3 pv3')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw4 ttu tracked tr')
				]),
			_List_fromArray(
				[
					A3($author$project$View$Navigation$viewLink, '/home', 'Home', 'white'),
					A3($author$project$View$Navigation$viewLink, '/signin', 'Sign In', 'white')
				]))
		]));
var $author$project$Ox$Icon$cart = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M88.598,165.857c-3.561,-1.676 -7.265,-4.711 -8.317,-10.179c-1.413,-7.341 -32.851,-135.278 -32.851,-135.278c0,0 -1.451,-5.355 -9.067,-5.355c-5.577,0 -21.159,0.054 -21.159,0.134c0,4.548 -3.692,8.24 -8.24,8.24c-4.548,0 -8.24,-3.692 -8.24,-8.24c0,-4.548 3.692,-8.24 8.24,-8.24c0,0 36.283,0.064 36.284,0.064c0.151,0.001 8.412,0.079 11.737,10.503c0.568,1.779 4.918,18.915 10.357,40.531l118.793,-0.025l-30.929,79.861l-67.87,0.025c2.627,10.522 4.31,17.279 4.31,17.279c0,0 2.147,3.968 8.37,3.968c6.223,0 61.133,0.381 61.133,0.381l0.107,8.249c1.604,2.581 2.531,5.626 2.531,8.886c0,9.308 -7.557,16.866 -16.866,16.866c-9.309,0 -16.866,-7.558 -16.866,-16.866c0,-3.209 0.898,-6.21 2.457,-8.765l-16.557,0c1.559,2.555 2.457,5.556 2.457,8.765c0,9.308 -7.557,16.866 -16.866,16.866c-9.309,0 -16.866,-7.558 -16.866,-16.866c0,-4.109 1.472,-7.877 3.918,-10.804Zm14.998,2.039l-4.1,0c-3.981,0.928 -6.95,4.502 -6.95,8.765c0,4.967 4.033,9 9,9c4.967,0 9,-4.033 9,-9c0,-4.263 -2.969,-7.837 -6.95,-8.765Zm45.375,0l-4.1,0c-3.981,0.928 -6.95,4.502 -6.95,8.765c0,4.967 4.033,9 9,9c4.967,0 9,-4.033 9,-9c0,-4.263 -2.969,-7.837 -6.95,-8.765Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$home = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')
					]),
				_List_Nil)
			]));
};
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $author$project$Ox$Icon$items = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('32.364'),
						$elm$svg$Svg$Attributes$cy('32.364'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('32.364'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('167.636'),
						$elm$svg$Svg$Attributes$cy('32.364'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('32.364'),
						$elm$svg$Svg$Attributes$cy('100'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('100'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('167.636'),
						$elm$svg$Svg$Attributes$cy('100'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('32.364'),
						$elm$svg$Svg$Attributes$cy('167.636'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('167.636'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('167.636'),
						$elm$svg$Svg$Attributes$cy('167.636'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Theme$primaryColor = '#4EC0E3';
var $author$project$Ox$Bar$tab = F2(
	function (attributes, navLinks) {
		var newAttributes = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
					A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.1em'),
					A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
					A2($elm$html$Html$Attributes$style, 'justify-content', 'space-around'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
					A2($elm$html$Html$Attributes$style, 'bottom', '0'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid rgba(0,0,0,0.2)'),
					A2($elm$html$Html$Attributes$style, 'z-index', '3000'),
					A2($elm$html$Html$Attributes$style, 'height', '36px')
				]),
			attributes);
		return A2($elm$html$Html$nav, newAttributes, navLinks);
	});
var $author$project$Ox$Bar$tabOption = F2(
	function (path, content) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('link dim dib black'),
					A2($elm$html$Html$Attributes$style, 'outline', 'none'),
					$elm$html$Html$Attributes$href(path)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '24px'),
							A2($elm$html$Html$Attributes$style, 'height', '24px')
						]),
					content)
				]));
	});
var $author$project$View$Mobile$tabBar = function (route) {
	var navLink = F3(
		function (path, icon, navRoute) {
			var color = _Utils_eq(navRoute, route) ? $author$project$Ox$Theme$primaryColor : 'gray';
			return A2(
				$author$project$Ox$Bar$tabOption,
				path,
				_List_fromArray(
					[
						icon(color)
					]));
		});
	return A2(
		$author$project$Ox$Bar$tab,
		_List_Nil,
		_List_fromArray(
			[
				A3(navLink, '/home', $author$project$Ox$Icon$home, $author$project$Route$Home),
				A3(navLink, '/collection', $author$project$Ox$Icon$items, $author$project$Route$Collection),
				A3(navLink, '/shop', $author$project$Ox$Icon$cart, $author$project$Route$Market)
			]));
};
var $author$project$Ox$Icon$cartSmall = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M158,172l-98,0l-30,-144.722l-20,-0.278c0,0 -10,-0.442 -10,-10.108c0,-9.665 10,-9.892 10,-9.892l18,0c15.197,0 17.956,5.062 20.486,12.89c0.056,0.173 3.514,16.11 3.514,16.11l143,0l-37,97l-85.04,0l4.04,21l81,0l0,18Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('76.363'),
						$elm$svg$Svg$Attributes$cy('173.5'),
						$elm$svg$Svg$Attributes$r('19.5'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('158'),
						$elm$svg$Svg$Attributes$cy('173.5'),
						$elm$svg$Svg$Attributes$r('19.5'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$itemsSmall = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('46.923'),
						$elm$svg$Svg$Attributes$cy('46.923'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('153.161'),
						$elm$svg$Svg$Attributes$cy('46.923'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('46.923'),
						$elm$svg$Svg$Attributes$cy('153.161'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('153.161'),
						$elm$svg$Svg$Attributes$cy('153.161'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$signout = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M87,150l0,-70.02c0,-1.321 0.525,-2.588 1.459,-3.521c0.933,-0.934 2.2,-1.459 3.521,-1.459c4.602,0 11.405,0 16.015,0c2.764,0 5.005,2.241 5.005,5.005l0,69.995l20.395,0c0.765,0 1.463,0.436 1.798,1.124c0.335,0.688 0.249,1.506 -0.223,2.109c-7.197,9.196 -27.639,35.317 -33.426,42.711c-0.372,0.475 -0.941,0.752 -1.544,0.752c-0.603,0 -1.172,-0.277 -1.544,-0.752c-5.787,-7.394 -26.229,-33.515 -33.426,-42.711c-0.472,-0.603 -0.558,-1.421 -0.223,-2.109c0.335,-0.688 1.033,-1.124 1.798,-1.124c6.976,0 20.395,0 20.395,0Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M41.211,164.098c-2.442,2.58 -5.876,3.986 -9.426,3.86c-3.55,-0.126 -6.875,-1.772 -9.128,-4.518c-14.141,-17.293 -22.657,-39.38 -22.657,-63.44c0,-55.192 44.808,-100 100,-100c55.192,0 100,44.808 100,100c0,24.042 -8.503,46.114 -22.662,63.369c-2.251,2.746 -5.574,4.393 -9.122,4.52c-3.549,0.126 -6.98,-1.278 -9.422,-3.857c-0.035,0.033 -0.036,0.033 -0.036,0.033c-4.291,-4.533 -4.543,-11.549 -0.587,-16.378c10.704,-12.961 17.109,-29.582 17.109,-47.687c0,-41.548 -33.732,-75.28 -75.28,-75.28c-41.548,0 -75.28,33.732 -75.28,75.28c0,18.135 6.427,34.782 17.125,47.783c3.913,4.765 3.669,11.698 -0.57,16.176c0.002,0.069 -0.031,0.104 -0.064,0.139Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Theme$darkGray = '#666';
var $author$project$Ox$Theme$primaryDarkColor = '#0090b1';
var $author$project$View$Desktop$viewNavLink = F5(
	function (path, title, icon, myRoute, route) {
		var iconColor = _Utils_eq(myRoute, route) ? $author$project$Ox$Theme$primaryDarkColor : $author$project$Ox$Theme$darkGray;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mr3 f6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ml1 link dib flex items-center'),
							A2($elm$html$Html$Attributes$style, 'outline', 'none'),
							$elm$html$Html$Attributes$href(path)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '1.5em')
								]),
							_List_fromArray(
								[
									icon(iconColor)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f6 pl2 fw5 dim'),
									A2($elm$html$Html$Attributes$style, 'color', iconColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								]))
						]))
				]));
	});
var $author$project$View$Desktop$viewNav = function (route) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fixed w-100 tc'),
				A2($elm$html$Html$Attributes$style, 'z-index', '1000'),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorWhite),
				A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 2px rgba(0,0,0,0.1)')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$nav,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f5 fw4 tracked flex justify-between pt3 ph2 pb2'),
						A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
						A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('f4 fw5 ml2 link dib black'),
								$elm$html$Html$Attributes$href('/'),
								A2($elm$html$Html$Attributes$style, 'outline', 'none')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('tĭbles')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex ')
							]),
						_List_fromArray(
							[
								A5($author$project$View$Desktop$viewNavLink, '/home', 'Room', $author$project$Ox$Icon$home, $author$project$Route$Home, route),
								A5($author$project$View$Desktop$viewNavLink, '/collection', 'Collection', $author$project$Ox$Icon$itemsSmall, $author$project$Route$Collection, route),
								A5($author$project$View$Desktop$viewNavLink, '/shop', 'Shop', $author$project$Ox$Icon$cartSmall, $author$project$Route$Market, route),
								A5($author$project$View$Desktop$viewNavLink, '/signout', 'Sign out', $author$project$Ox$Icon$signout, $author$project$Route$SignOut, route)
							]))
					]))
			]));
};
var $author$project$View$Navigation$viewSignedIn = F3(
	function (route, _v0, screenSize) {
		var viewNav = function () {
			switch (screenSize.$) {
				case 0:
					return $author$project$View$Mobile$tabBar(route);
				case 1:
					return $author$project$View$Mobile$tabBar(route);
				default:
					return $author$project$View$Desktop$viewNav(route);
			}
		}();
		return viewNav;
	});
var $author$project$View$Navigation$view = F2(
	function (route, session) {
		if (session.$ === 3) {
			var user = session.b;
			return A3(
				$author$project$View$Navigation$viewSignedIn,
				route,
				user,
				$author$project$Type$Session$screenSize(session));
		} else {
			return $author$project$View$Navigation$viewGuest;
		}
	});
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Page$viewPageTitle = F3(
	function (title, screenSize, htmlMsg) {
		var classStyle = function () {
			switch (screenSize.$) {
				case 0:
					return 'tl flex justify-between items-center ph2 mb2 pb2';
				case 1:
					return 'tl flex justify-between items-center ph2 mb2 pb2';
				default:
					return 'tl flex justify-between items-center ph3 mb2';
			}
		}();
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classStyle)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f3 fw7')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					htmlMsg
				]));
	});
var $author$project$Page$Clubhouse$GoToRoom = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$Clubhouse$viewRoom = F2(
	function (model, room) {
		var _v0 = function () {
			var _v1 = model.cs;
			switch (_v1.$) {
				case 0:
					return _Utils_Tuple2('f4 fw3 pb1 ml2', 'f6 fw4 ml2');
				case 1:
					return _Utils_Tuple2('f4 fw3 pb1 ml2', 'f6 fw4 ml2');
				default:
					return _Utils_Tuple2('f3 fw3 pb1', 'f5 fw4');
			}
		}();
		var titleStyle = _v0.a;
		var descStyle = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Page$Clubhouse$GoToRoom(room.di)),
					$elm$html$Html$Attributes$class('tl pv3 bb b--black-10 pointer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(titleStyle)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(room.ef)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(descStyle)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(room.c5)
						]))
				]));
	});
var $author$project$Page$viewTitle = F2(
	function (title, htmlMsg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl flex justify-between items-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f4 fw6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					htmlMsg
				]));
	});
var $author$project$Page$Clubhouse$viewRooms = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph2')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$author$project$Page$viewTitle,
				'Rooms',
				A2($elm$html$Html$span, _List_Nil, _List_Nil)),
			A2(
				$elm$core$List$map,
				$author$project$Page$Clubhouse$viewRoom(model),
				model.bo)));
};
var $author$project$Page$viewShimTop = function (paddingTop) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tl flex justify-between items-center'),
				A2($elm$html$Html$Attributes$style, 'padding-top', paddingTop)
			]),
		_List_Nil);
};
var $author$project$Page$viewShim = $author$project$Page$viewShimTop('60px');
var $author$project$Page$Clubhouse$viewLoggedIn = function (model) {
	var menu = A2($elm$html$Html$span, _List_Nil, _List_Nil);
	return A2(
		$elm$html$Html$main_,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
						A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim,
						A3($author$project$Page$viewPageTitle, model.ef, model.cs, menu),
						$author$project$Page$Clubhouse$viewRooms(model)
					]))
			]));
};
var $author$project$Ox$Theme$mobileHeaderColor = '#C6E5F2';
var $author$project$Type$Screen$toScrollTop = function (size) {
	switch (size.$) {
		case 0:
			var scrollTop = size.b;
			return scrollTop;
		case 1:
			var scrollTop = size.b;
			return scrollTop;
		case 2:
			var scrollTop = size.b;
			return scrollTop;
		default:
			var scrollTop = size.b;
			return scrollTop;
	}
};
var $author$project$Page$getMobileTitleClassStyles = F3(
	function (title, screenSize, kind) {
		if (!kind) {
			return ($author$project$Type$Screen$toScrollTop(screenSize) > 44) ? _Utils_Tuple2(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f7 fw6 tc animate__fadeIn animate__faster animate__animated flex items-center justify-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-center bg-white fixed w-100 top-0 bb b--black-20'),
						A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$mobileHeaderColor)
					])) : _Utils_Tuple2(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('animate__fadeOut animate__animated')
						]),
					_List_Nil),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-center bg-transparent fixed w-100 top-0 tl ')
					]));
		} else {
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 fw6 flex items-center justify-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(' flex flex-column justify-center bg-white fixed w-100 top-0 bb b--black-20')
					]));
		}
	});
var $author$project$Page$viewMobileHeader = F4(
	function (title, screenSize, kind, _v0) {
		var leftMenu = _v0.a;
		var rightMenu = _v0.b;
		var _v1 = A3($author$project$Page$getMobileTitleClassStyles, title, screenSize, kind);
		var newTitle = _v1.a;
		var attributes = _v1.b;
		var mobileView = A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('animate__animated animate__fadeIn'),
						A2($elm$html$Html$Attributes$style, 'z-index', '2000'),
						A2($elm$html$Html$Attributes$style, 'height', '48px'),
						A2($elm$html$Html$Attributes$style, 'padding', '10px')
					]),
				attributes),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-100 '),
							A2($elm$html$Html$Attributes$style, 'display', 'grid'),
							A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr 1fr')
						]),
					_List_fromArray(
						[leftMenu, newTitle, rightMenu]))
				]));
		switch (screenSize.$) {
			case 0:
				return mobileView;
			case 1:
				return mobileView;
			default:
				return A2($elm$html$Html$span, _List_Nil, _List_Nil);
		}
	});
var $author$project$Page$Clubhouse$viewMobileHeader = function (model) {
	var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var leftMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	return A4(
		$author$project$Page$viewMobileHeader,
		model.ef,
		model.cs,
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Page$Clubhouse$viewBody = function (model) {
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Clubhouse$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, params.d0, model.d5)),
				A2($elm$html$Html$Lazy$lazy, $author$project$Page$Clubhouse$viewLoggedIn, model),
				$author$project$Page$Clubhouse$viewMobileHeader(model)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Clubhouse$view = function (model) {
	return _Utils_Tuple2(
		model.ef,
		$author$project$Page$Clubhouse$viewBody(model));
};
var $author$project$Setting$appName = 'tĭbles';
var $author$project$Page$pageTitle = function (title) {
	return title + (' | ' + $author$project$Setting$appName);
};
var $author$project$Page$Collection$PageMsg = function (a) {
	return {$: 6, a: a};
};
var $elm$html$Html$article = _VirtualDom_node('article');
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$View$Collection$gatherItemInfoPlus = function (ips) {
	var tibles = ips.aD;
	var item = ips.aq;
	var dateAcquired = A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$andThen,
			function (t) {
				return $elm$core$Maybe$Just(t.dU.c2);
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$sortBy,
					function (t) {
						return t.dU.c2;
					},
					ips.aD))));
	return A2(
		$elm$core$Maybe$andThen,
		function (t) {
			return $elm$core$Maybe$Just(
				{bI: ips.bI, c2: dateAcquired, di: t.dU.du, aq: item, aD: tibles, ef: item.ef, bw: t.dU.dW, bx: t.by.ef});
		},
		$elm$core$List$head(tibles));
};
var $author$project$View$Collection$makeItemAndTibles = F2(
	function (model, item) {
		var tibles = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function (t) {
					return t.dU.d1;
				},
				A2(
					$elm$core$List$filter,
					function (t) {
						return _Utils_eq(t.dU.du, item.du);
					},
					$elm$core$Dict$values(model.bv))));
		return {
			bI: $elm$core$List$length(tibles),
			aq: item,
			aD: tibles
		};
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$svg$Svg$image = $elm$svg$Svg$trustedNode('image');
var $elm$svg$Svg$Attributes$opacity = _VirtualDom_attribute('opacity');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$xlinkHref = function (value) {
	return A3(
		_VirtualDom_attributeNS,
		'http://www.w3.org/1999/xlink',
		'xlink:href',
		_VirtualDom_noJavaScriptUri(value));
};
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$View$Sticker$frontFaded = F2(
	function (item, attributes) {
		var o = '0.2';
		var imageUrl = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (x) {
					return $elm$core$Maybe$Just(x.dj);
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (v) {
							return v.cI === '10';
						},
						item.dk))));
		var h = 1000;
		var w = $elm$core$String$fromFloat(item.aP * h);
		var bgColor = '#ffffff';
		return A2(
			$elm$svg$Svg$svg,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$width('100%'),
						$elm$svg$Svg$Attributes$height('100%'),
						$elm$svg$Svg$Attributes$viewBox('0 0 667 1000'),
						$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
					]),
				attributes),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$rect,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$fill(bgColor),
							$elm$svg$Svg$Attributes$stroke('#000000'),
							$elm$svg$Svg$Attributes$width(w),
							$elm$svg$Svg$Attributes$height('1000'),
							$elm$svg$Svg$Attributes$rx('40'),
							$elm$svg$Svg$Attributes$ry('40'),
							$elm$svg$Svg$Attributes$y('0'),
							$elm$svg$Svg$Attributes$x('0'),
							$elm$svg$Svg$Attributes$opacity(o)
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$image,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$xlinkHref(imageUrl),
							$elm$svg$Svg$Attributes$height('667'),
							$elm$svg$Svg$Attributes$width('667'),
							$elm$svg$Svg$Attributes$y('167'),
							$elm$svg$Svg$Attributes$x('1'),
							$elm$svg$Svg$Attributes$opacity(o)
						]),
					_List_Nil)
				]));
	});
var $author$project$View$Collection$viewItem = F2(
	function (viewAs, item) {
		if (!viewAs) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-between')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$View$Sticker$frontFaded, item, _List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-column h-100')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw6 f7 tc'),
										A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,0.2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(item.ef)
									]))
							]))
					]));
		} else {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20 flex items-center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '60px'),
								$elm$html$Html$Attributes$class('tl mt1')
							]),
						_List_fromArray(
							[
								A2($author$project$View$Sticker$frontFaded, item, _List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-column h-100')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw6 f7 tc'),
										A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,0.2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(item.ef)
									]))
							]))
					]));
		}
	});
var $author$project$View$Collection$NoOp = {$: 0};
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$dx = _VirtualDom_attribute('dx');
var $elm$svg$Svg$Attributes$dy = _VirtualDom_attribute('dy');
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $elm$svg$Svg$node = $elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var $author$project$View$Sticker$feDropShadow = F2(
	function (attributes, children) {
		return A3($elm$svg$Svg$node, 'feDropShadow', attributes, children);
	});
var $elm$svg$Svg$filter = $elm$svg$Svg$trustedNode('filter');
var $elm$svg$Svg$Attributes$floodOpacity = _VirtualDom_attribute('flood-opacity');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$Attributes$stdDeviation = _VirtualDom_attribute('stdDeviation');
var $author$project$View$Sticker$front = F2(
	function (tible, attributes) {
		var item = tible.aq;
		var h = 1000;
		var heightStr = $elm$core$String$fromFloat(h);
		var w = item.aP * h;
		var radiusStr = $elm$core$String$fromInt(
			$elm$core$Basics$round(item.a6.c_ * w));
		var widthStr = $elm$core$String$fromFloat(w);
		var frontImage = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (x) {
					return $elm$core$Maybe$Just(x.dj);
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (v) {
							return _Utils_eq(v.cI, tible.dU.cI);
						},
						item.dk))));
		var frontAndBackImages = function () {
			var _v0 = item.a6.dy;
			if (!_v0.$) {
				var imageUrl = _v0.a;
				return _List_fromArray(
					[
						A2(
						$elm$svg$Svg$image,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$xlinkHref(imageUrl),
								$elm$svg$Svg$Attributes$height('1000'),
								$elm$svg$Svg$Attributes$width('667'),
								$elm$svg$Svg$Attributes$y('0'),
								$elm$svg$Svg$Attributes$x('0'),
								$elm$svg$Svg$Attributes$clipPath('url(#roundCorners)')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$image,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$xlinkHref(frontImage),
								$elm$svg$Svg$Attributes$height('667'),
								$elm$svg$Svg$Attributes$width('667'),
								$elm$svg$Svg$Attributes$y('167'),
								$elm$svg$Svg$Attributes$x('0')
							]),
						_List_Nil)
					]);
			} else {
				return _List_fromArray(
					[
						A2(
						$elm$svg$Svg$image,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$xlinkHref(frontImage),
								$elm$svg$Svg$Attributes$height('667'),
								$elm$svg$Svg$Attributes$width('667'),
								$elm$svg$Svg$Attributes$y('167'),
								$elm$svg$Svg$Attributes$x('0')
							]),
						_List_Nil)
					]);
			}
		}();
		var backgroundColor = '#ffffff';
		return A2(
			$elm$svg$Svg$svg,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$width('100%'),
						$elm$svg$Svg$Attributes$height('100%'),
						$elm$svg$Svg$Attributes$viewBox('0 0 667 1000'),
						$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2'),
						$elm$svg$Svg$Attributes$style('filter:url(#shadow);')
					]),
				attributes),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$defs,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$svg$Svg$clipPath,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$id('roundCorners')
									]),
								_List_fromArray(
									[
										A2(
										$elm$svg$Svg$rect,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$width(widthStr),
												$elm$svg$Svg$Attributes$height(heightStr),
												$elm$svg$Svg$Attributes$rx(radiusStr),
												$elm$svg$Svg$Attributes$ry(radiusStr),
												$elm$svg$Svg$Attributes$y('0'),
												$elm$svg$Svg$Attributes$x('0')
											]),
										_List_Nil)
									])),
								A2(
								$elm$svg$Svg$filter,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$id('shadow')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$View$Sticker$feDropShadow,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$dx('0'),
												$elm$svg$Svg$Attributes$dy('0'),
												$elm$svg$Svg$Attributes$stdDeviation('1'),
												$elm$svg$Svg$Attributes$floodOpacity('0.5')
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$svg$Svg$rect,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$fill(backgroundColor),
								$elm$svg$Svg$Attributes$width(widthStr),
								$elm$svg$Svg$Attributes$height(heightStr),
								$elm$svg$Svg$Attributes$rx(radiusStr),
								$elm$svg$Svg$Attributes$ry(radiusStr),
								$elm$svg$Svg$Attributes$y('0'),
								$elm$svg$Svg$Attributes$x('0')
							]),
						_List_Nil)
					]),
				frontAndBackImages));
	});
var $author$project$Type$Tible$toVariantNumberString = function (tible) {
	var printNum = '#' + $elm$core$String$fromInt(tible.dU.dW);
	return (tible.aq.bJ > 0) ? (tible.by.ef + (' ' + (printNum + ('/' + $elm$core$String$fromInt(tible.aq.bJ))))) : (tible.by.ef + (' ' + printNum));
};
var $author$project$View$Collection$viewTible = F3(
	function (viewAs, its, tible) {
		var titleStr = $author$project$Type$Tible$toVariantNumberString(tible);
		var countStr = $elm$core$String$fromInt(its.bI);
		var newTitleStr = (countStr === '1') ? titleStr : (titleStr + (' (' + (countStr + ')')));
		if (!viewAs) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-between'),
						$elm$html$Html$Events$onClick($author$project$View$Collection$NoOp)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$View$Sticker$front, tible, _List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-column h-100')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw6 f7 tc')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(its.aq.ef)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('f7 tc')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(newTitleStr)
									]))
							]))
					]));
		} else {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20 flex items-center'),
						$elm$html$Html$Events$onClick($author$project$View$Collection$NoOp)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '60px'),
								$elm$html$Html$Attributes$class('tl mt1')
							]),
						_List_fromArray(
							[
								A2($author$project$View$Sticker$front, tible, _List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-column w-100 pl2')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw6 f7 tl')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(its.aq.ef)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('f7 tl')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(newTitleStr)
									]))
							]))
					]));
		}
	});
var $author$project$View$Collection$viewItemOrTible = F2(
	function (viewAs, its) {
		var _v0 = $elm$core$List$head(its.aD);
		if (!_v0.$) {
			var tible = _v0.a;
			return A3($author$project$View$Collection$viewTible, viewAs, its, tible);
		} else {
			return A2($author$project$View$Collection$viewItem, viewAs, its.aq);
		}
	});
var $author$project$View$Collection$viewAsItems = F2(
	function (model, options) {
		var tempCol = function () {
			var _v2 = $author$project$Type$Session$screenSize(model.d5);
			switch (_v2.$) {
				case 0:
					return 'repeat(auto-fill, minmax(100px, 1fr))';
				case 1:
					return 'repeat(auto-fill, minmax(125px, 1fr))';
				default:
					return 'repeat(auto-fill, minmax(150px, 1fr))';
			}
		}();
		var searchString = $elm$core$String$toLower(model.d2);
		var searchFilter = function (itemsInfos) {
			var itemsTitleIds = A2(
				$elm$core$List$map,
				function (v) {
					return v.aq.du;
				},
				A2(
					$elm$core$List$filter,
					function (v) {
						return A2(
							$elm$core$String$contains,
							searchString,
							$elm$core$String$toLower(v.aq.ef));
					},
					itemsInfos));
			var itemsTagsIds = A2(
				$elm$core$List$map,
				function (v) {
					return v.aq.du;
				},
				A2(
					$elm$core$List$filter,
					function (v) {
						return A2(
							$elm$core$List$any,
							$elm$core$String$contains(searchString),
							v.aq.aV);
					},
					itemsInfos));
			var resultIds = $elm$core$Set$toList(
				$elm$core$Set$fromList(
					_Utils_ap(itemsTitleIds, itemsTagsIds)));
			return A2(
				$elm$core$List$filter,
				function (i) {
					return A2($elm$core$List$member, i.di, resultIds);
				},
				itemsInfos);
		};
		var itemsInfoPlus = function () {
			var itemsInfos = A2(
				$elm$core$List$filterMap,
				function (j) {
					return j;
				},
				A2(
					$elm$core$List$map,
					$author$project$View$Collection$gatherItemInfoPlus,
					A2(
						$elm$core$List$map,
						$author$project$View$Collection$makeItemAndTibles(model),
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function (s) {
									return s.bd;
								},
								$elm$core$Dict$values(model.d6))))));
			return (searchString === '') ? itemsInfos : searchFilter(itemsInfos);
		}();
		var itemUl = (!model.b$.aZ) ? $elm$html$Html$ul(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list ma0 pa0'),
					A2($elm$html$Html$Attributes$style, 'grid-template-columns', tempCol),
					A2($elm$html$Html$Attributes$style, 'display', 'grid'),
					A2($elm$html$Html$Attributes$style, 'grid-gap', '.5em')
				])) : $elm$html$Html$ul(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list ma0 pa0'),
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'column')
				]));
		var _v0 = function () {
			var _v1 = options.aS;
			switch (_v1) {
				case 0:
					return _Utils_Tuple2(
						A2(
							$elm$core$List$map,
							function (i) {
								return {bI: i.bI, aq: i.aq, aD: i.aD};
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.ef;
								},
								itemsInfoPlus)),
						'Sorted by Title');
				case 1:
					return _Utils_Tuple2(
						A2(
							$elm$core$List$map,
							function (i) {
								return {bI: i.bI, aq: i.aq, aD: i.aD};
							},
							$elm$core$List$reverse(
								A2(
									$elm$core$List$sortBy,
									function ($) {
										return $.bI;
									},
									itemsInfoPlus))),
						'Sorted by Count Owned');
				case 2:
					return _Utils_Tuple2(
						A2(
							$elm$core$List$map,
							function (i) {
								return {bI: i.bI, aq: i.aq, aD: i.aD};
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.bw;
								},
								itemsInfoPlus)),
						'Sorted by Print #');
				case 3:
					return _Utils_Tuple2(
						A2(
							$elm$core$List$map,
							function (i) {
								return {bI: i.bI, aq: i.aq, aD: i.aD};
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.bx;
								},
								itemsInfoPlus)),
						'Sorted by Variant');
				default:
					return _Utils_Tuple2(
						A2(
							$elm$core$List$map,
							function (i) {
								return {bI: i.bI, aq: i.aq, aD: i.aD};
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.c2;
								},
								itemsInfoPlus)),
						'Sorted by Date Acquired');
			}
		}();
		var itemsAndTibles = _v0.a;
		var sortedByStr = _v0.b;
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl pa3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mb3 f6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(sortedByStr)
						])),
					A2(
					$elm$html$Html$article,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mb3 br4 ph3 bg-white'),
							A2($elm$html$Html$Attributes$style, 'border', '2px solid black'),
							A2($elm$html$Html$Attributes$style, 'padding', '28px 16px')
						]),
					_List_fromArray(
						[
							itemUl(
							A2(
								$elm$core$List$map,
								$author$project$View$Collection$viewItemOrTible(model.b$.aZ),
								itemsAndTibles))
						]))
				]));
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm_community$list_extra$List$Extra$gatherWith = F2(
	function (testFn, list) {
		var helper = F2(
			function (scattered, gathered) {
				if (!scattered.b) {
					return $elm$core$List$reverse(gathered);
				} else {
					var toGather = scattered.a;
					var population = scattered.b;
					var _v1 = A2(
						$elm$core$List$partition,
						testFn(toGather),
						population);
					var gathering = _v1.a;
					var remaining = _v1.b;
					return A2(
						helper,
						remaining,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(toGather, gathering),
							gathered));
				}
			});
		return A2(helper, list, _List_Nil);
	});
var $elm_community$list_extra$List$Extra$gatherEquals = function (list) {
	return A2($elm_community$list_extra$List$Extra$gatherWith, $elm$core$Basics$eq, list);
};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$Type$Variant$mixedVariant = {
	a6: A2($author$project$Type$Variant$Content, '#ffffff', $elm$core$Maybe$Nothing),
	bI: 0,
	dj: '',
	au: $elm$core$Maybe$Nothing,
	av: $elm$core$Maybe$Just(0),
	ae: 0,
	ef: 'Mixed',
	aX: 'Mixed',
	cI: '0'
};
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$View$Collection$gatherSetViewInfo = F3(
	function (model, options, set) {
		var variantSelected = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Dict$get, set.di, model.ah));
		var searchString = $elm$core$String$toLower(model.d2);
		var keepPrintsOfVariants = function (itemTibles) {
			return _Utils_update(
				itemTibles,
				{
					aD: A2(
						$elm$core$List$filter,
						function (p) {
							return _Utils_eq(p.by.cI, variantSelected);
						},
						itemTibles.aD)
				});
		};
		var filteredSearch = function (setItems) {
			var setItemsIdForTitle = A2(
				$elm$core$List$map,
				function (i) {
					return i.du;
				},
				A2(
					$elm$core$List$filter,
					function (p) {
						return A2(
							$elm$core$String$contains,
							searchString,
							$elm$core$String$toLower(p.ef));
					},
					setItems));
			var setItemsIdForTags = A2(
				$elm$core$List$map,
				function (i) {
					return i.du;
				},
				A2(
					$elm$core$List$filter,
					function (p) {
						return A2(
							$elm$core$List$any,
							$elm$core$String$contains(searchString),
							p.aV);
					},
					setItems));
			var resultIds = $elm$core$Set$toList(
				$elm$core$Set$fromList(
					_Utils_ap(setItemsIdForTitle, setItemsIdForTags)));
			return A2(
				$elm$core$List$filter,
				function (s) {
					return A2($elm$core$List$member, s.du, resultIds);
				},
				setItems);
		};
		var filteredSetItems = (searchString === '') ? set.bd : filteredSearch(set.bd);
		var itemsAndTibles = function () {
			var _v1 = _Utils_Tuple2(options.cw, variantSelected);
			if (_v1.a) {
				if (_v1.b === '') {
					return A2(
						$elm$core$List$map,
						$author$project$View$Collection$makeItemAndTibles(model),
						filteredSetItems);
				} else {
					return A2(
						$elm$core$List$map,
						function (ip) {
							return keepPrintsOfVariants(ip);
						},
						A2(
							$elm$core$List$map,
							$author$project$View$Collection$makeItemAndTibles(model),
							filteredSetItems));
				}
			} else {
				if (_v1.b === '') {
					return A2(
						$elm$core$List$filter,
						function (ips) {
							return ips.bI > 0;
						},
						A2(
							$elm$core$List$map,
							$author$project$View$Collection$makeItemAndTibles(model),
							filteredSetItems));
				} else {
					return A2(
						$elm$core$List$map,
						function (ip) {
							return keepPrintsOfVariants(ip);
						},
						A2(
							$elm$core$List$filter,
							function (ips) {
								return ips.bI > 0;
							},
							A2(
								$elm$core$List$map,
								$author$project$View$Collection$makeItemAndTibles(model),
								filteredSetItems)));
				}
			}
		}();
		var variantCountDict = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var id = _v0.a;
					var l = _v0.b;
					return _Utils_Tuple2(
						id,
						$elm$core$List$length(l) + 1);
				},
				$elm_community$list_extra$List$Extra$gatherEquals(
					$elm$core$List$sort(
						A2(
							$elm$core$List$map,
							function (t) {
								return t.by.cI;
							},
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (it) {
										return it.aD;
									},
									A2(
										$elm$core$List$map,
										$author$project$View$Collection$makeItemAndTibles(model),
										filteredSetItems))))))));
		var variantsForButtons = options.cw ? A2($elm$core$List$cons, $author$project$Type$Variant$mixedVariant, set.ek) : A2(
			$elm$core$List$cons,
			$author$project$Type$Variant$mixedVariant,
			A2(
				$elm$core$List$filter,
				function (x) {
					return A2($elm$core$Dict$member, x.cI, variantCountDict);
				},
				set.ek));
		var countCollectingItems = (variantSelected === '') ? $elm$core$List$length(
			$elm$core$Set$toList(
				$elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						function (p) {
							return p.du;
						},
						A2(
							$elm$core$List$filter,
							function (p) {
								return _Utils_eq(p.d7, set.di);
							},
							$elm$core$Dict$values(model.dV)))))) : A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Dict$get, variantSelected, variantCountDict));
		var completionStr = _Utils_eq(countCollectingItems, set.dt) ? 'Complete' : ($elm$core$String$fromInt(countCollectingItems) + ('/' + $elm$core$String$fromInt(set.dt)));
		var completionScore = countCollectingItems / set.dt;
		return {a4: countCollectingItems, a5: completionScore, al: completionStr, c4: set.c4, di: set.di, dt: set.dt, ar: itemsAndTibles, ef: set.ef, ek: set.ek};
	});
var $author$project$View$Collection$Collapse = function (a) {
	return {$: 1, a: a};
};
var $author$project$View$Collection$Expand = function (a) {
	return {$: 2, a: a};
};
var $author$project$View$Collection$FilterPrintByVariant = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Ox$Icon$check = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z')
					]),
				_List_Nil)
			]));
};
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Ox$Button$iconOnly = F2(
	function (icon, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pointer link br2 flex items-center justify-around'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'width', '36px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '24px'),
							A2($elm$html$Html$Attributes$style, 'height', '24px')
						]),
					_List_fromArray(
						[icon]))
				]));
	});
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$progress = _VirtualDom_node('progress');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Ox$Progress$linear = F3(
	function (str, maxVal, val) {
		var valueStr = $elm$core$String$fromInt(val);
		var maxStr = $elm$core$String$fromInt(maxVal);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center'),
					A2($elm$html$Html$Attributes$style, 'gap', '8px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$progress,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$max(maxStr),
							$elm$html$Html$Attributes$value(valueStr)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tracked fw6 f6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(str)
						]))
				]));
	});
var $author$project$Ox$Icon$plus = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$remove = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19 13H5v-2h14v2z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Button$toggle = F2(
	function (attributes, buttons) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '0px'),
					A2($elm$html$Html$Attributes$style, 'min-height', '28px'),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
					A2($elm$html$Html$Attributes$style, 'border', '2px solid black'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '6px')
				]),
			attributes);
		return A2($elm$html$Html$div, attributeList, buttons);
	});
var $author$project$Ox$Button$toggleOption = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px'),
					A2($elm$html$Html$Attributes$style, 'min-height', '28px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '52px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$View$Collection$viewSet = F2(
	function (model, setViewInfo) {
		var variantSelected = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Dict$get, setViewInfo.di, model.ah));
		var tempCol = function () {
			var _v0 = $author$project$Type$Session$screenSize(model.d5);
			switch (_v0.$) {
				case 0:
					return 'repeat(auto-fill, minmax(100px, 1fr))';
				case 1:
					return 'repeat(auto-fill, minmax(125px, 1fr))';
				default:
					return 'repeat(auto-fill, minmax(150px, 1fr))';
			}
		}();
		var setContent = A2($elm$core$List$member, setViewInfo.di, model.bH) ? A2($elm$html$Html$span, _List_Nil, _List_Nil) : ((!model.cv.aZ) ? A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list ma0 pa0'),
					A2($elm$html$Html$Attributes$style, 'grid-template-columns', tempCol),
					A2($elm$html$Html$Attributes$style, 'display', 'grid'),
					A2($elm$html$Html$Attributes$style, 'grid-gap', '.5em')
				]),
			A2(
				$elm$core$List$map,
				$author$project$View$Collection$viewItemOrTible(model.cv.aZ),
				setViewInfo.ar)) : A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list ma0 pa0'),
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'column')
				]),
			A2(
				$elm$core$List$map,
				$author$project$View$Collection$viewItemOrTible(model.cv.aZ),
				setViewInfo.ar)));
		var progressStatus = (setViewInfo.al === 'Complete') ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center justify-start')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'height', '20px'),
							A2($elm$html$Html$Attributes$style, 'width', '20px')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$check('black')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(setViewInfo.al)
						]))
				])) : A3($author$project$Ox$Progress$linear, setViewInfo.al, setViewInfo.dt, setViewInfo.a4);
		var plusOrMinusBtn = A2($elm$core$List$member, setViewInfo.di, model.bH) ? A2(
			$author$project$Ox$Button$iconOnly,
			$author$project$Ox$Icon$plus('black'),
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$View$Collection$Expand(setViewInfo.di))
				])) : A2(
			$author$project$Ox$Button$iconOnly,
			$author$project$Ox$Icon$remove('black'),
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$View$Collection$Collapse(setViewInfo.di))
				]));
		var formatVariantButtons = function (variantSum) {
			var btnMsg = $elm$html$Html$Events$onClick(
				A2($author$project$View$Collection$FilterPrintByVariant, setViewInfo.di, variantSum.cI));
			var attrs = _Utils_eq(variantSelected, variantSum.cI) ? _List_fromArray(
				[
					btnMsg,
					A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
					A2($elm$html$Html$Attributes$style, 'color', 'white')
				]) : _List_fromArray(
				[btnMsg]);
			return A2($author$project$Ox$Button$toggleOption, variantSum.ef, attrs);
		};
		var variantBtns = A2(
			$elm$core$List$map,
			function (v) {
				return formatVariantButtons(v);
			},
			A2(
				$elm$core$List$filter,
				function (d) {
					return !d.bI;
				},
				setViewInfo.ek));
		var variantSelectors = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mv3')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Ox$Button$toggle,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'background-color', 'transparent'),
							A2($elm$html$Html$Attributes$style, 'border', '0')
						]),
					variantBtns)
				]));
		return A2(
			$elm$html$Html$article,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb3 br4 bg-white'),
					A2($elm$html$Html$Attributes$style, 'border', '2px solid black'),
					A2($elm$html$Html$Attributes$style, 'padding', '16px 16px')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$viewTitle, setViewInfo.ef, plusOrMinusBtn),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex mt3')
						]),
					_List_fromArray(
						[progressStatus])),
					variantSelectors,
					setContent
				]));
	});
var $author$project$View$Collection$viewAsSets = F2(
	function (model, options) {
		var collectingSetsIds = $elm$core$Set$toList(
			$elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					function (t) {
						return t.dU.d7;
					},
					$elm$core$Dict$values(model.bv))));
		var collectingSets = $elm$core$Dict$values(
			A2(
				$elm$core$Dict$filter,
				F2(
					function (k, _v2) {
						return A2($elm$core$List$member, k, collectingSetsIds);
					}),
				model.d6));
		var setViewInfos = A2(
			$elm$core$List$filter,
			function (cs) {
				return $elm$core$List$length(cs.ar) > 0;
			},
			A2(
				$elm$core$List$map,
				A2($author$project$View$Collection$gatherSetViewInfo, model, options),
				collectingSets));
		var _v0 = function () {
			var _v1 = options.aS;
			switch (_v1) {
				case 0:
					return _Utils_Tuple2(
						$elm$core$List$reverse(
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.c4;
								},
								setViewInfos)),
						'Sorted by Publish Date');
				case 1:
					return _Utils_Tuple2(
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.ef;
							},
							setViewInfos),
						'Sorted by Title');
				default:
					return _Utils_Tuple2(
						$elm$core$List$reverse(
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.a5;
								},
								setViewInfos)),
						'Sorted by Almost Complete');
			}
		}();
		var sortedSets = _v0.a;
		var sortedByStr = _v0.b;
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl pa3 mb4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mb3 f6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(sortedByStr)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$View$Collection$viewSet(model),
						sortedSets))
				]));
	});
var $author$project$View$Collection$view = function (model) {
	var _v0 = model.cJ;
	if (!_v0.$) {
		var options = _v0.a;
		return A2($author$project$View$Collection$viewAsSets, model, options);
	} else {
		var options = _v0.a;
		return A2($author$project$View$Collection$viewAsItems, model, options);
	}
};
var $author$project$Page$Collection$CloseOptions = {$: 5};
var $author$project$Ox$Theme$mobileModalMaskStyles = function (attributes) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(0,0,0,0.5)'),
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'z-index', '5000')
			]),
		attributes);
};
var $author$project$Ox$Sheet$bottom = F3(
	function (maskAttributes, attributes, content) {
		return A2(
			$elm$html$Html$div,
			$author$project$Ox$Theme$mobileModalMaskStyles(maskAttributes),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('bg-near-white flex flex-column items-end'),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'bottom', '0'),
								A2($elm$html$Html$Attributes$style, 'left', '0'),
								$elm$html$Html$Attributes$class('animate__animated animate__slideInUp animate__faster'),
								A2($elm$html$Html$Attributes$style, 'border-radius', '16px 16px 0 0')
							]),
						attributes),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$Type$Filters$ByCountOwned = 1;
var $author$project$Type$Filters$ByPrintNum = 2;
var $author$project$Type$Filters$ByTitle = 0;
var $author$project$Type$Filters$ByVariant = 3;
var $author$project$View$Filters$Close = {$: 5};
var $author$project$Type$Filters$ItemsView = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Filters$ListView = 1;
var $author$project$View$Filters$SetViewOption = function (a) {
	return {$: 2, a: a};
};
var $author$project$View$Filters$setsOrItemsToggle = function (model) {
	var optionViewStyle = function (optionView) {
		return _Utils_eq(optionView, model.cJ) ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'color', 'black'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(optionView))
			]) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
				A2($elm$html$Html$Attributes$style, 'color', 'white'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(optionView))
			]);
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 mb3 flex justify-around')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$toggle,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$toggleOption,
						'Sets',
						optionViewStyle(
							$author$project$Type$Filters$SetsView(model.cv))),
						A2(
						$author$project$Ox$Button$toggleOption,
						'Items',
						optionViewStyle(
							$author$project$Type$Filters$ItemsView(model.b$)))
					]))
			]));
};
var $author$project$Ox$Theme$btnTextColor = '#333';
var $author$project$Ox$Button$textLink = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$btnTextColor),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 15px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$View$Filters$viewItemOptions = function (model) {
	var options = model.b$;
	var optionViewAsStyle = function (viewAs) {
		var newOptions = _Utils_update(
			options,
			{aZ: viewAs});
		return _Utils_eq(viewAs, options.aZ) ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'color', 'black'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(
					$author$project$Type$Filters$ItemsView(newOptions)))
			]) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
				A2($elm$html$Html$Attributes$style, 'color', 'white'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(
					$author$project$Type$Filters$ItemsView(newOptions)))
			]);
	};
	var itemSortOption = F2(
		function (sort, title) {
			return _Utils_eq(options.aS, sort) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb2 flex justify-between items-center'),
						A2($elm$html$Html$Attributes$style, 'padding', '5px 0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '18px'),
								A2($elm$html$Html$Attributes$style, 'width', '18px')
							]),
						_List_fromArray(
							[
								$author$project$Ox$Icon$check('black')
							]))
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb2 flex justify-between items-center pointer'),
						A2($elm$html$Html$Attributes$style, 'padding', '5px 0'),
						$elm$html$Html$Events$onClick(
						$author$project$View$Filters$SetViewOption(
							$author$project$Type$Filters$ItemsView(
								_Utils_update(
									options,
									{aS: sort}))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '18px'),
								A2($elm$html$Html$Attributes$style, 'width', '18px')
							]),
						_List_Nil)
					]));
		});
	var sortOptions = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mh1 mb3'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-rows', '1fr 1fr 1fr')
			]),
		_List_fromArray(
			[
				A2(itemSortOption, 0, 'By Title'),
				A2(itemSortOption, 1, 'By Count Owned'),
				A2(itemSortOption, 2, 'By Print #'),
				A2(itemSortOption, 3, 'By Variant'),
				A2(itemSortOption, 4, 'By Date Acquired')
			]));
	var header = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr 1fr')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex items-center')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$textLink,
						'Done',
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$View$Filters$Close)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 f6 tc')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('View Options')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_Nil)
			]));
	var gridOrList = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center justify-between'),
				A2($elm$html$Html$Attributes$style, 'padding', '5px 0')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('View as')
					])),
				A2(
				$author$project$Ox$Button$toggle,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$toggleOption,
						'Grid',
						optionViewAsStyle(0)),
						A2(
						$author$project$Ox$Button$toggleOption,
						'List',
						optionViewAsStyle(1))
					]))
			]));
	var otherOptions = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mh1'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-rows', '1fr')
			]),
		_List_fromArray(
			[gridOrList]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa2 w-100 tl'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '34px')
			]),
		_List_fromArray(
			[
				header,
				$author$project$View$Filters$setsOrItemsToggle(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 f6 mb2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Sort')
					])),
				sortOptions,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mh1 fw6 f6 mb2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Options')
					])),
				otherOptions
			]));
};
var $author$project$Type$Filters$ByAlmostComplete = 2;
var $author$project$Type$Filters$BySetTitle = 1;
var $author$project$View$Filters$CollapseAll = {$: 3};
var $author$project$View$Filters$ExpandAll = {$: 4};
var $author$project$Ox$Button$switch = F2(
	function (attributes, status) {
		var btnAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'height', '24px'),
				A2($elm$html$Html$Attributes$style, 'width', '24px'),
				A2($elm$html$Html$Attributes$style, 'border', '0'),
				A2($elm$html$Html$Attributes$style, 'outline', '0'),
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'border-radius', '12px')
			]);
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'height', '28px'),
					A2($elm$html$Html$Attributes$style, 'width', '46px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0 2px'),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background-color', 'lightGray'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '14px'),
					$elm$html$Html$Attributes$class('flex items-center')
				]),
			attributes);
		var newAttributeList = status ? _Utils_ap(
			attributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('justify-end')
				])) : attributeList;
		return A2(
			$elm$html$Html$div,
			newAttributeList,
			_List_fromArray(
				[
					A2($elm$html$Html$div, btnAttributes, _List_Nil)
				]));
	});
var $author$project$View$Filters$viewSetOptions = function (model) {
	var options = model.cv;
	var setSortOption = F2(
		function (sort, title) {
			return _Utils_eq(options.aS, sort) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb2 flex justify-between items-center'),
						A2($elm$html$Html$Attributes$style, 'padding', '5px 0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '18px'),
								A2($elm$html$Html$Attributes$style, 'width', '18px')
							]),
						_List_fromArray(
							[
								$author$project$Ox$Icon$check('black')
							]))
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb2 flex justify-between items-center pointer'),
						A2($elm$html$Html$Attributes$style, 'padding', '5px 0'),
						$elm$html$Html$Events$onClick(
						$author$project$View$Filters$SetViewOption(
							$author$project$Type$Filters$SetsView(
								_Utils_update(
									options,
									{aS: sort}))))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '18px'),
								A2($elm$html$Html$Attributes$style, 'width', '18px')
							]),
						_List_Nil)
					]));
		});
	var sortOptions = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mh1 mb3'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-rows', '1fr 1fr 1fr')
			]),
		_List_fromArray(
			[
				A2(setSortOption, 0, 'By Publish Date'),
				A2(setSortOption, 1, 'By Title'),
				A2(setSortOption, 2, 'By Almost Completed')
			]));
	var toggleShowMissing = function () {
		var newOptions = _Utils_update(
			options,
			{cw: !options.cw});
		var switchAttr = options.cw ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(
					$author$project$Type$Filters$SetsView(newOptions)))
			]) : _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(
					$author$project$Type$Filters$SetsView(newOptions)))
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex justify-between items-center pointer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Show Missing')
						])),
					A2($author$project$Ox$Button$switch, switchAttr, options.cw)
				]));
	}();
	var optionViewAsStyle = function (viewAs) {
		var newOptions = _Utils_update(
			options,
			{aZ: viewAs});
		return _Utils_eq(viewAs, options.aZ) ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'color', 'black'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(
					$author$project$Type$Filters$SetsView(newOptions)))
			]) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
				A2($elm$html$Html$Attributes$style, 'color', 'white'),
				$elm$html$Html$Events$onClick(
				$author$project$View$Filters$SetViewOption(
					$author$project$Type$Filters$SetsView(newOptions)))
			]);
	};
	var header = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('items-center'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr 1fr')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex items-center')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$textLink,
						'Done',
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$View$Filters$Close)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 f6 tc')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('View Options')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_Nil)
			]));
	var gridOrList = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center justify-between'),
				A2($elm$html$Html$Attributes$style, 'padding', '5px 0')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('View as')
					])),
				A2(
				$author$project$Ox$Button$toggle,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$toggleOption,
						'Grid',
						optionViewAsStyle(0)),
						A2(
						$author$project$Ox$Button$toggleOption,
						'List',
						optionViewAsStyle(1))
					]))
			]));
	var otherOptions = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mh1'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-rows', '1fr 1fr 1fr 1fr')
			]),
		_List_fromArray(
			[
				gridOrList,
				toggleShowMissing,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex items-center pointer'),
						A2($elm$html$Html$Attributes$style, 'padding', '5px 0'),
						$elm$html$Html$Events$onClick($author$project$View$Filters$CollapseAll)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Collapse All')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex items-center pointer'),
						A2($elm$html$Html$Attributes$style, 'padding', '5px 0'),
						$elm$html$Html$Events$onClick($author$project$View$Filters$ExpandAll)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Expand All')
					]))
			]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa2 w-100 tl'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '34px')
			]),
		_List_fromArray(
			[
				header,
				$author$project$View$Filters$setsOrItemsToggle(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 f6 mb2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Sort')
					])),
				sortOptions,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mh1 fw6 f6 mb2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Options')
					])),
				otherOptions
			]));
};
var $author$project$View$Filters$view = function (model) {
	var _v0 = model.cJ;
	if (!_v0.$) {
		return $author$project$View$Filters$viewSetOptions(model);
	} else {
		return $author$project$View$Filters$viewItemOptions(model);
	}
};
var $author$project$Page$Collection$viewOptionsContent = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Page$Collection$FiltersMsg,
		$author$project$View$Filters$view(model.ao));
};
var $author$project$Modal$Tible$Close = {$: 1};
var $author$project$Type$Modal$cardStyle = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
		A2($elm$html$Html$Attributes$style, 'top', '50%'),
		A2($elm$html$Html$Attributes$style, 'left', '50%'),
		A2($elm$html$Html$Attributes$style, 'padding', '0px'),
		A2($elm$html$Html$Attributes$style, 'transform', 'translate(-50%, -50%)')
	]);
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Ox$Icon$times = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z')
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$Tible$toPx = function (num) {
	return $elm$core$String$fromInt(num) + 'px';
};
var $author$project$Modal$Tible$Flip = {$: 2};
var $author$project$Modal$Tible$Gallery = 2;
var $author$project$Modal$Tible$Log = 1;
var $author$project$Modal$Tible$Script = 3;
var $author$project$Modal$Tible$Video = 0;
var $author$project$Ox$Icon$flip = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M11.718,66.048l0.068,-47.45c0,-10.984 18.414,-11.321 18.414,-0.337l-0.08,16.797c0,0 28.327,-28.113 70.951,-28.113c69.516,0 91.926,67.694 91.926,87.054c0,5.574 -2.368,10.028 -6.68,10.269c-4.647,0.26 -8.138,-2.367 -9.456,-10.205c-4.685,-27.86 -25.798,-72.328 -73.862,-72.519c-43.164,-0.172 -61.776,26.635 -61.776,26.635l16.392,-0.065c11.944,0 12.25,18.182 0.324,17.542l-46.221,0.392Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M188.227,133.919l-0.068,47.45c0,10.984 -18.414,11.321 -18.414,0.337l0.08,-16.797c0,0 -28.327,28.113 -70.951,28.113c-69.516,0 -91.926,-67.695 -91.926,-87.055c0,-5.573 2.368,-10.027 6.68,-10.269c4.647,-0.26 8.138,2.367 9.456,10.206c4.685,27.86 25.798,72.328 73.862,72.519c43.164,0.172 61.776,-26.636 61.776,-26.636l-16.392,0.065c-11.944,0 -12.25,-18.182 -0.324,-17.542l46.221,-0.391Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$Tible$flipColor = '#b9c92f';
var $author$project$Modal$Tible$Select = function (a) {
	return {$: 3, a: a};
};
var $author$project$Modal$Tible$btnSelectedColor = '#359ad2';
var $author$project$Modal$Tible$btnUnselectedColor = '#fdcd06';
var $author$project$Modal$Tible$viewMenuButton = F3(
	function (model, cardBack, title) {
		var fontSizeStyle = function () {
			var _v0 = model.cs;
			if (!_v0.$) {
				return 'f7';
			} else {
				return 'f6';
			}
		}();
		var buttonStyle = _Utils_eq(model.ak, cardBack) ? _List_fromArray(
			[
				$elm$html$Html$Attributes$class('br1 flex items-center justify-center fw7 pv2 ph1 link dib pointer ttu ' + fontSizeStyle),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Modal$Tible$btnSelectedColor)
			]) : _List_fromArray(
			[
				$elm$html$Html$Attributes$class('br1 flex items-center justify-center fw7 pv2 ph1 link dib pointer ttu ' + fontSizeStyle),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Modal$Tible$btnUnselectedColor)
			]);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				buttonStyle,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Modal$Tible$Select(cardBack))
					])),
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				]));
	});
var $author$project$Modal$Tible$viewBackMenu = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr 1fr 1fr 1fr'),
				A2($elm$html$Html$Attributes$style, 'grid-column-gap', '0.3em')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br1 flex items-center justify-center f5 fw7 link dib pointer'),
						A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Modal$Tible$flipColor),
						$elm$html$Html$Events$onClick($author$project$Modal$Tible$Flip)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(''),
								A2($elm$html$Html$Attributes$style, 'width', '1.5em'),
								A2($elm$html$Html$Attributes$style, 'height', '1.5em')
							]),
						_List_fromArray(
							[
								$author$project$Ox$Icon$flip('black')
							]))
					])),
				A3($author$project$Modal$Tible$viewMenuButton, model, 0, 'Video'),
				A3($author$project$Modal$Tible$viewMenuButton, model, 1, 'Log'),
				A3($author$project$Modal$Tible$viewMenuButton, model, 2, 'Gallery'),
				A3($author$project$Modal$Tible$viewMenuButton, model, 3, 'Script')
			]));
};
var $author$project$Modal$Tible$viewTitle = function (model) {
	var classCss = function () {
		var _v0 = model.cs;
		if (!_v0.$) {
			return 'fw6 f5 tl white ttu';
		} else {
			return 'fw7 f4 tl white ttu';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(classCss)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(model.ef)
			]));
};
var $author$project$Modal$Tible$viewCardBackLog = function (model) {
	var lines = _List_Nil;
	var height = $author$project$Modal$Tible$toPx(
		$elm$core$Basics$round(model.a2 * 480));
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_List_fromArray(
				[
					$author$project$Modal$Tible$viewTitle(model),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fw4 f4 tl white lh-copy mt2'),
							A2($elm$html$Html$Attributes$style, 'overflow', 'auto'),
							A2($elm$html$Html$Attributes$style, 'height', height)
						]),
					A2(
						$elm$core$List$map,
						function (l) {
							return A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(l)
									]));
						},
						lines))
				])),
			$author$project$Modal$Tible$viewBackMenu(model)
		]);
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Modal$Tible$viewCardBackSticker = function (model) {
	var printInfoStr = $author$project$Type$Tible$toVariantNumberString(model.I);
	var item = model.I.aq;
	var _v0 = item.a6.dx;
	if (_v0.$ === 1) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Modal$Tible$Flip),
						$elm$html$Html$Attributes$class('bg-white w-100 h-100 flex flex-column items-center justify-around'),
						A2(
						$elm$html$Html$Attributes$style,
						'border-radius',
						$author$project$Modal$Tible$toPx(model.N))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw6')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(item.ef)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(printInfoStr)
									]))
							]))
					]))
			]);
	} else {
		var imageUrl = _v0.a;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Modal$Tible$Flip),
						$elm$html$Html$Attributes$class('bg-white w-100 h-100 flex flex-column items-center justify-around'),
						A2(
						$elm$html$Html$Attributes$style,
						'border-radius',
						$author$project$Modal$Tible$toPx(model.N))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2(
								$elm$html$Html$Attributes$style,
								'border-radius',
								$author$project$Modal$Tible$toPx(model.N))
							]),
						_List_Nil)
					]))
			]);
	}
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$controls = $elm$html$Html$Attributes$boolProperty('controls');
var $elm$html$Html$source = _VirtualDom_node('source');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$video = _VirtualDom_node('video');
var $author$project$Modal$Tible$viewCardBackVideo = function (model) {
	var _v0 = function () {
		var _v1 = model.cs;
		if (!_v1.$) {
			return _Utils_Tuple2(
				'fw4 f6 tl white ttu lh-copy ph3',
				_List_fromArray(
					[
						$elm$html$Html$Attributes$controls(true),
						$elm$html$Html$Attributes$class('mt2 ba br2 bw1 w-100')
					]));
		} else {
			return _Utils_Tuple2(
				'fw4 f3 tl white ttu lh-copy ph3',
				_List_fromArray(
					[
						$elm$html$Html$Attributes$controls(true),
						$elm$html$Html$Attributes$class('mt3 ba br3 bw2 w-100'),
						A2($elm$html$Html$Attributes$style, 'border-color', $author$project$Modal$Tible$btnSelectedColor)
					]));
		}
	}();
	var quoteStyle = _v0.a;
	var videoProps = _v0.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_List_fromArray(
				[
					$author$project$Modal$Tible$viewTitle(model),
					A2(
					$elm$html$Html$video,
					videoProps,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$source,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(''),
									$elm$html$Html$Attributes$type_('video/mp4')
								]),
							_List_Nil)
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(quoteStyle)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('')
				])),
			$author$project$Modal$Tible$viewBackMenu(model)
		]);
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $author$project$Modal$Tible$NoOp = {$: 0};
var $author$project$Modal$Tible$ShowImage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Modal$Tible$viewGalleryThumbnail = F3(
	function (model, index, imageCaption) {
		var styleAdj = function () {
			var _v1 = model.cs;
			if (!_v1.$) {
				return 'bw1 br2';
			} else {
				return 'bw2 br3';
			}
		}();
		var _v0 = _Utils_eq(index, model.ap) ? _Utils_Tuple2('ba b--blue ' + styleAdj, $author$project$Modal$Tible$NoOp) : _Utils_Tuple2(
			'pointer ba br3 b--black ' + styleAdj,
			$author$project$Modal$Tible$ShowImage(index));
		var thumbStyle = _v0.a;
		var cmdMsg = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(cmdMsg)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(thumbStyle),
							$elm$html$Html$Attributes$src(imageCaption.a)
						]),
					_List_Nil)
				]));
	});
var $author$project$Modal$Tible$viewThumbnails = F2(
	function (model, imagesAndCaptions) {
		var gtcStyle = ($elm$core$List$length(imagesAndCaptions) < 4) ? 'repeat(auto-fill, minmax(100px, 1fr))' : A2(
			$elm$core$String$repeat,
			$elm$core$List$length(imagesAndCaptions),
			'1fr ');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'display', 'grid'),
					A2($elm$html$Html$Attributes$style, 'grid-template-columns', gtcStyle),
					A2($elm$html$Html$Attributes$style, 'grid-column-gap', '0.2em')
				]),
			A2(
				$elm$core$List$indexedMap,
				$author$project$Modal$Tible$viewGalleryThumbnail(model),
				imagesAndCaptions));
	});
var $author$project$Modal$Tible$viewCardGallery = function (model) {
	var images = _List_Nil;
	var captions = _List_Nil;
	var imagesAndCaptions = A3($elm$core$List$map2, $elm$core$Tuple$pair, images, captions);
	var _v0 = function () {
		var _v1 = model.cs;
		if (!_v1.$) {
			return _Utils_Tuple2('ba br3 b--blue w-100 mt1 bw1', 'white ttu fw5 f7 pv2');
		} else {
			return _Utils_Tuple2('ba br3 b--blue w-100 mt2 bw2', 'white ttu fw5 f5 pv2');
		}
	}();
	var imgCss = _v0.a;
	var capCss = _v0.b;
	var firstImageAndCaption = function () {
		var _v2 = A2($elm_community$list_extra$List$Extra$getAt, model.ap, imagesAndCaptions);
		if (!_v2.$) {
			var imageAndCaption = _v2.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-between')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageAndCaption.a),
								$elm$html$Html$Attributes$class(imgCss)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(capCss)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(imageAndCaption.b)
							])),
						A2($author$project$Modal$Tible$viewThumbnails, model, imagesAndCaptions)
					]));
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_List_fromArray(
				[
					$author$project$Modal$Tible$viewTitle(model),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('')
						]),
					_List_fromArray(
						[firstImageAndCaption]))
				])),
			$author$project$Modal$Tible$viewBackMenu(model)
		]);
};
var $author$project$Modal$Tible$viewCardScript = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_List_fromArray(
				[
					$author$project$Modal$Tible$viewTitle(model)
				])),
			$author$project$Modal$Tible$viewBackMenu(model)
		]);
};
var $author$project$Modal$Tible$viewCardBack = function (model) {
	var styleCss = function () {
		var _v1 = model.cs;
		if (!_v1.$) {
			return 'flex flex-columnjustify-between w-100 h-100';
		} else {
			return 'flex flex-column justify-between w-100 h-100';
		}
	}();
	var viewBack = function (backContent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(styleCss),
					A2($elm$html$Html$Attributes$style, 'backface-visibility', 'hidden'),
					A2($elm$html$Html$Attributes$style, '-webkit-backface-visibility', 'hidden'),
					A2($elm$html$Html$Attributes$style, 'transform', 'rotateY(180deg)'),
					A2(
					$elm$html$Html$Attributes$style,
					'border-radius',
					$author$project$Modal$Tible$toPx(model.N))
				]),
			backContent);
	};
	var _v0 = model.ak;
	switch (_v0) {
		case 4:
			return viewBack(
				$author$project$Modal$Tible$viewCardBackSticker(model));
		case 0:
			return viewBack(
				$author$project$Modal$Tible$viewCardBackVideo(model));
		case 1:
			return viewBack(
				$author$project$Modal$Tible$viewCardBackLog(model));
		case 2:
			return viewBack(
				$author$project$Modal$Tible$viewCardGallery(model));
		default:
			return viewBack(
				$author$project$Modal$Tible$viewCardScript(model));
	}
};
var $author$project$Modal$Tible$viewCardFront = function (model) {
	var item = model.I.aq;
	var frontImage = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$andThen,
			function (x) {
				return $elm$core$Maybe$Just(x.dj);
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (v) {
						return _Utils_eq(v.cI, model.I.dU.cI);
					},
					model.I.aq.dk))));
	var backgroudColor = '#ffffff';
	var backgroundStyles = function () {
		var _v0 = item.a6.dy;
		if (!_v0.$) {
			var imageUrl = _v0.a;
			return _List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + (imageUrl + ')')),
					A2($elm$html$Html$Attributes$style, 'background-size', 'cover'),
					A2($elm$html$Html$Attributes$style, 'background-color', backgroudColor)
				]);
		} else {
			return _List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'background-color', backgroudColor)
				]);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Modal$Tible$Flip),
					$elm$html$Html$Attributes$class('absolute w-100 h-100 flex items-center'),
					A2($elm$html$Html$Attributes$style, '-webkit-backface-visibility', 'hidden'),
					A2($elm$html$Html$Attributes$style, 'backface-visibility', 'hidden'),
					A2(
					$elm$html$Html$Attributes$style,
					'border-radius',
					$author$project$Modal$Tible$toPx(model.N))
				]),
			backgroundStyles),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(frontImage)
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$Tible$view = function (model) {
	var transformStyle = model.aA ? 'rotateY(0deg)' : 'rotateY(180deg';
	var titleStr = $author$project$Type$Tible$toVariantNumberString(model.I);
	var printNumberBubble = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('absolute w-100 flex justify-center'),
				A2($elm$html$Html$Attributes$style, 'top', '-50px'),
				A2($elm$html$Html$Attributes$style, '-webkit-backface-visibility', 'hidden')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br4 ph3 pv2 f5 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.ef)
					]))
			]));
	var closeIconTimes = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('absolute pointer'),
				A2($elm$html$Html$Attributes$style, 'width', '1.5em'),
				A2($elm$html$Html$Attributes$style, 'top', '-44px'),
				A2($elm$html$Html$Attributes$style, 'left', '4px'),
				$elm$html$Html$Events$onClick($author$project$Modal$Tible$Close)
			]),
		_List_fromArray(
			[
				$author$project$Ox$Icon$times('black')
			]));
	return A2(
		$elm$html$Html$div,
		_Utils_ap(
			$author$project$Type$Modal$cardStyle,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'width',
					$author$project$Modal$Tible$toPx(model.bz)),
					A2(
					$elm$html$Html$Attributes$style,
					'height',
					$author$project$Modal$Tible$toPx(model.a9)),
					A2($elm$html$Html$Attributes$style, 'perspective', '3000px'),
					A2($elm$html$Html$Attributes$style, 'opacity', '1')
				])),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('flip-card-inner'),
						$elm$html$Html$Attributes$class('tc relative w-100 h-100 relative'),
						A2($elm$html$Html$Attributes$style, 'transition', 'transform 0.6s'),
						A2($elm$html$Html$Attributes$style, 'transform-style', 'preserve-3d'),
						A2(
						$elm$html$Html$Attributes$style,
						'border-radius',
						$author$project$Modal$Tible$toPx(model.N)),
						A2($elm$html$Html$Attributes$style, 'box-shadow', '0 4px 18px 0 rgba(0,0,0,0.5)'),
						A2($elm$html$Html$Attributes$style, 'transform', transformStyle)
					]),
				_List_fromArray(
					[
						$author$project$Modal$Tible$viewCardFront(model),
						$author$project$Modal$Tible$viewCardBack(model)
					])),
				printNumberBubble,
				closeIconTimes,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt3 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.I.aq.ef)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(titleStr)
					]))
			]));
};
var $author$project$Ox$Theme$maskBgColor = 'rgba(196,255,253,0.9)';
var $author$project$Page$desktopModalMaskStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$maskBgColor),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'z-index', '1000'),
		A2($elm$html$Html$Attributes$style, 'z-index', '5000')
	]);
var $author$project$Page$mobileModalMaskStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + ($author$project$Ox$Theme$backgroundWallImage + ')')),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'z-index', '1000'),
		A2($elm$html$Html$Attributes$style, 'z-index', '5000')
	]);
var $author$project$Page$viewModalMask = F2(
	function (size, content) {
		switch (size.$) {
			case 0:
				return A2(
					$elm$html$Html$div,
					$author$project$Page$mobileModalMaskStyles,
					_List_fromArray(
						[content]));
			case 1:
				return A2(
					$elm$html$Html$div,
					$author$project$Page$mobileModalMaskStyles,
					_List_fromArray(
						[content]));
			default:
				return A2(
					$elm$html$Html$div,
					$author$project$Page$desktopModalMaskStyles,
					_List_fromArray(
						[content]));
		}
	});
var $author$project$Page$Collection$viewPrintModal = F2(
	function (model, printModalModel) {
		return A2(
			$author$project$Page$viewModalMask,
			model.cs,
			A2(
				$elm$html$Html$map,
				$author$project$Page$Collection$TibleModalMsg,
				$author$project$Modal$Tible$view(printModalModel)));
	});
var $author$project$Page$Collection$viewModalOrNot = function (model) {
	var _v0 = model.z;
	_v0$2:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 0:
					var subModel = _v0.a.a;
					return A2($author$project$Page$Collection$viewPrintModal, model, subModel);
				case 2:
					var _v1 = _v0.a;
					return A3(
						$author$project$Ox$Sheet$bottom,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Page$Collection$CloseOptions)
							]),
						_List_fromArray(
							[
								$author$project$Ox$Events$onClick($author$project$Page$Collection$NoOp)
							]),
						$author$project$Page$Collection$viewOptionsContent(model));
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return A2($elm$html$Html$span, _List_Nil, _List_Nil);
};
var $author$project$Page$Collection$Search = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Collection$SearchMode = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Collection$OpenOptionPicker = {$: 4};
var $author$project$Ox$Icon$filter = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$Collection$viewDesktopOptionPickerOrBtn = function (model) {
	return model._ ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('relative pointer'),
				$elm$html$Html$Attributes$id('optionPicker'),
				A2($elm$html$Html$Attributes$style, 'z-index', '6000')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('absolute'),
						A2($elm$html$Html$Attributes$style, 'right', '0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('br2 bg-near-white relative flex flex-column items-center'),
								A2($elm$html$Html$Attributes$style, 'width', '328px'),
								A2($elm$html$Html$Attributes$style, 'box-shadow', '2px 2px 5px rgba(0,0,0,0.1)'),
								$author$project$Ox$Events$onClick($author$project$Page$Collection$NoOp)
							]),
						_List_fromArray(
							[
								$author$project$Page$Collection$viewOptionsContent(model)
							]))
					])),
				A2(
				$author$project$Ox$Button$iconOnly,
				$author$project$Ox$Icon$filter('black'),
				_List_Nil)
			])) : A2(
		$author$project$Ox$Button$iconOnly,
		$author$project$Ox$Icon$filter('black'),
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick($author$project$Page$Collection$OpenOptionPicker)
			]));
};
var $author$project$Page$viewMobilePageTitle = F3(
	function (title, _v0, htmlMsg) {
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl flex flex-column justify-between ph2 mb2 pb2 b--black-20')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f3 fw7 ph2 mb2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					htmlMsg
				]));
	});
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Ox$Icon$search = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M120.977,137.569c-11.824,8.503 -26.325,13.513 -41.988,13.513c-39.753,0 -72.028,-32.275 -72.028,-72.028c0,-39.754 32.275,-72.028 72.028,-72.028c39.754,0 72.029,32.274 72.029,72.028c0,18.088 -6.682,34.628 -17.711,47.283l54.702,54.507l-11.644,12.114l-55.388,-55.389Zm-41.988,-121.903c34.985,0 63.388,28.403 63.388,63.388c0,34.984 -28.403,63.387 -63.388,63.387c-34.984,0 -63.387,-28.403 -63.387,-63.387c0,-34.985 28.403,-63.388 63.387,-63.388Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$viewSearchMenu = F4(
	function (searchTerm, isMobile, onInputMsg, onFocusMsg) {
		return isMobile ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100 relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute'),
							A2($elm$html$Html$Attributes$style, 'width', '1.2em'),
							A2($elm$html$Html$Attributes$style, 'height', '1.2em'),
							A2($elm$html$Html$Attributes$style, 'top', '.5em'),
							A2($elm$html$Html$Attributes$style, 'left', '.5em')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$search('gray')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('search'),
							$elm$html$Html$Attributes$placeholder('Search'),
							$elm$html$Html$Attributes$autocomplete(true),
							$elm$html$Html$Attributes$class('input-reset ba b--near-white pv2 pr2 pl4 db w-100 br3 bg-near-white'),
							$elm$html$Html$Attributes$name('search'),
							$elm$html$Html$Attributes$id('search-field'),
							$elm$html$Html$Events$onInput(onInputMsg),
							$elm$html$Html$Events$onFocus(
							onFocusMsg(true)),
							$elm$html$Html$Attributes$value(searchTerm)
						]),
					_List_Nil)
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute'),
							A2($elm$html$Html$Attributes$style, 'width', '1.2em'),
							A2($elm$html$Html$Attributes$style, 'height', '1.2em'),
							A2($elm$html$Html$Attributes$style, 'top', '.5em'),
							A2($elm$html$Html$Attributes$style, 'left', '.5em')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$search('gray')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('search'),
							$elm$html$Html$Attributes$placeholder('Search'),
							$elm$html$Html$Attributes$class('input-reset ba b--black-20 pv2 pr2 pl4 db w-100 br3 bg-near-white'),
							$elm$html$Html$Attributes$name('search'),
							$elm$html$Html$Attributes$id('search-field'),
							$elm$html$Html$Events$onInput(onInputMsg),
							$elm$html$Html$Attributes$value(searchTerm)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$Collection$viewPageTitle = function (model) {
	var mobileSearchMenu = A4($author$project$Page$viewSearchMenu, model.F, true, $author$project$Page$Collection$Search, $author$project$Page$Collection$SearchMode);
	var desktopSearchMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center'),
				A2($elm$html$Html$Attributes$style, 'gap', '8px')
			]),
		_List_fromArray(
			[
				A4($author$project$Page$viewSearchMenu, model.F, false, $author$project$Page$Collection$Search, $author$project$Page$Collection$SearchMode),
				$author$project$Page$Collection$viewDesktopOptionPickerOrBtn(model)
			]));
	var _v0 = model.cs;
	switch (_v0.$) {
		case 0:
			return A3($author$project$Page$viewMobilePageTitle, model.ef, model.cs, mobileSearchMenu);
		case 1:
			return A3($author$project$Page$viewMobilePageTitle, model.ef, model.cs, mobileSearchMenu);
		default:
			return A3($author$project$Page$viewPageTitle, model.ef, model.cs, desktopSearchMenu);
	}
};
var $author$project$Page$Collection$viewMain = function (model) {
	var viewCollectionOrSearch = model.Z ? _List_fromArray(
		[
			$author$project$Page$viewShimTop('54px'),
			A2(
			$elm$html$Html$map,
			$author$project$Page$Collection$CollectionMsg,
			$author$project$View$Collection$view(model.g)),
			$author$project$Page$Collection$viewModalOrNot(model)
		]) : _List_fromArray(
		[
			$author$project$Page$viewShim,
			$author$project$Page$Collection$viewPageTitle(model),
			A2(
			$elm$html$Html$map,
			$author$project$Page$Collection$CollectionMsg,
			$author$project$View$Collection$view(model.g)),
			$author$project$Page$Collection$viewModalOrNot(model)
		]);
	return A2(
		$elm$html$Html$main_,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
						A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
					]),
				viewCollectionOrSearch)
			]));
};
var $author$project$Page$Collection$OpenOptionSheet = {$: 3};
var $author$project$Page$Collection$OpenProfileSheet = {$: 10};
var $author$project$Ox$Icon$accountCircle = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z')
					]),
				_List_Nil)
			]));
};
var $author$project$Setting$hiliteBlue = '#0A99F6';
var $author$project$Page$Collection$viewMobileHeader = F2(
	function (user, model) {
		if (model.Z) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-white fixed w-100 top-0 tl ph3 flex flex-column justify-center bb b--black-20 animate__animated animate__fadeIn animate__faster'),
						A2($elm$html$Html$Attributes$style, 'z-index', '2000'),
						A2($elm$html$Html$Attributes$style, 'height', '54px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex items-center justify-between ')
							]),
						_List_fromArray(
							[
								A4($author$project$Page$viewSearchMenu, model.F, true, $author$project$Page$Collection$Search, $author$project$Page$Collection$SearchMode),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ml2 pointer'),
										A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue),
										$elm$html$Html$Events$onClick(
										$author$project$Page$Collection$SearchMode(false))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancel')
									]))
							]))
					]));
		} else {
			var rightMenu = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-center pv2 pr3 items-end')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$iconOnly,
						$author$project$Ox$Icon$filter('black'),
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Page$Collection$OpenOptionSheet)
							]))
					]));
			var leftMenu = function () {
				var _v0 = user.dz;
				if (!_v0.$) {
					var profile = _v0.a;
					var userImg = (profile.bX === '') ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '30px'),
								A2($elm$html$Html$Attributes$style, 'width', '30px'),
								A2($elm$html$Html$Attributes$style, 'margin-right', '4px')
							]),
						_List_fromArray(
							[
								$author$project$Ox$Icon$accountCircle('black')
							])) : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '30px'),
								A2($elm$html$Html$Attributes$style, 'width', '30px'),
								A2($elm$html$Html$Attributes$style, 'margin-right', '4px'),
								A2($elm$html$Html$Attributes$style, 'clip-path', 'circle(15px at center)')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(profile.bX)
									]),
								_List_Nil)
							]));
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tl pl2 fw6 f7 flex items-center'),
								$elm$html$Html$Events$onClick($author$project$Page$Collection$OpenProfileSheet)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class(''),
										A2($elm$html$Html$Attributes$style, 'height', '32px'),
										A2($elm$html$Html$Attributes$style, 'width', '32px')
									]),
								_List_fromArray(
									[userImg])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(profile.ej)
									]))
							]));
				} else {
					return A2($elm$html$Html$span, _List_Nil, _List_Nil);
				}
			}();
			return A4(
				$author$project$Page$viewMobileHeader,
				model.ef,
				model.cs,
				0,
				_Utils_Tuple2(leftMenu, rightMenu));
		}
	});
var $author$project$Page$Collection$viewLoggedInOrGuest = function (model) {
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Collection$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, params.d0, model.d5)),
				A2($elm$html$Html$Lazy$lazy, $author$project$Page$Collection$viewMain, model),
				A2($author$project$Page$Collection$viewMobileHeader, user, model)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Collection$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.ef),
		$author$project$Page$Collection$viewLoggedInOrGuest(model));
};
var $author$project$Page$GetStarted$SetFieldFocus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$GetStarted$UnsetFieldFocus = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$GetStarted$UpdateInput = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Ox$Theme$colorMuted = '#ccc';
var $author$project$Ox$Button$contained = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
					A2($elm$html$Html$Attributes$style, 'color', 'white'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '64px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Ox$Theme$Activated = 2;
var $author$project$Ox$Theme$Focused = 0;
var $author$project$Ox$Theme$Inactive = 1;
var $author$project$Page$GetStarted$labelState = F3(
	function (model, formField, value) {
		return _Utils_eq(
			model.R,
			$elm$core$Maybe$Just(formField)) ? 0 : (($elm$core$String$length(value) > 0) ? 2 : 1);
	});
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$Ox$Theme$colorForHelper = function (kind) {
	if (kind === 'error') {
		return $author$project$Ox$Theme$colorDanger;
	} else {
		return $author$project$Ox$Theme$colorDark;
	}
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Ox$Text$outlined = F4(
	function (labelName, state, inputAttrs, maybeAlert) {
		var labelAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
				A2($elm$html$Html$Attributes$style, 'top', '50%'),
				A2($elm$html$Html$Attributes$style, 'left', '16px'),
				A2($elm$html$Html$Attributes$style, 'right', 'initial'),
				A2($elm$html$Html$Attributes$style, 'transform-origin', 'left top'),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
				$elm$html$Html$Attributes$class('infoLabel')
			]);
		var inputAttributes = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, '-webkit-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, '-moz-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'padding', '6px 14px 6px'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
					A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
					A2($elm$html$Html$Attributes$style, 'height', '56px'),
					A2($elm$html$Html$Attributes$style, 'box-sizing', 'border-box')
				]),
			inputAttrs);
		var helper = function () {
			if (!maybeAlert.$) {
				var alert = maybeAlert.a;
				var color = $author$project$Ox$Theme$colorForHelper(alert.b1);
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'font-size', '12px'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '4px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(alert.b4)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var _v0 = function () {
			switch (state) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-185%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryDarkColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-185%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-50%)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.4)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid rgba(128,128,128,.8)'),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-185%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
								$elm$html$Html$Attributes$disabled(true),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)')
							]));
			}
		}();
		var addLabelAttributes = _v0.a;
		var addInputAttributes = _v0.b;
		var newInputAttributes = _Utils_ap(inputAttributes, addInputAttributes);
		var newLabelAttributes = _Utils_ap(labelAttributes, addLabelAttributes);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'position', 'relative'),
					A2($elm$html$Html$Attributes$style, 'height', '56px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '4px 4px 0 0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					newLabelAttributes,
					_List_fromArray(
						[
							$elm$html$Html$text(labelName)
						])),
					A2($elm$html$Html$input, newInputAttributes, _List_Nil),
					helper
				]));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $author$project$Page$GetStarted$viewEnterEmail = function (model) {
	var sendBtn = $rtfeldman$elm_validate$Validate$isValidEmail(model.da) ? A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(false),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor)
			])) : A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(true),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorMuted)
			]));
	var maybeError = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (x) {
				return x.$7 === 'password';
			},
			model.cQ));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(''),
				A2($elm$html$Html$Attributes$style, 'max-width', '310px'),
				A2($elm$html$Html$Attributes$style, 'margin', '40px auto'),
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'padding', '9px 9px 20px'),
				A2($elm$html$Html$Attributes$style, 'border-radius', '16px')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f2 w400')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('tĭbles')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 mb4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enter your '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw9')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('email')
							])),
						$elm$html$Html$text(' to sign in or sign up.')
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$GetStarted$CheckEmail),
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb4')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Email',
								A3($author$project$Page$GetStarted$labelState, model, 0, model.da),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('email'),
										$elm$html$Html$Attributes$id('emailField'),
										$elm$html$Html$Attributes$value(model.da),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Events$onInput(
										$author$project$Page$GetStarted$UpdateInput(0)),
										$elm$html$Html$Events$onFocus(
										$author$project$Page$GetStarted$SetFieldFocus(0)),
										$elm$html$Html$Events$onBlur(
										$author$project$Page$GetStarted$UnsetFieldFocus(0)),
										$elm$html$Html$Attributes$name('email')
									]),
								maybeError)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb2')
							]),
						_List_fromArray(
							[sendBtn]))
					]))
			]));
};
var $author$project$Ox$Icon$chevronLeft = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M128.445,190.568c-0.114,-0.114 -90.581,-90.568 -90.581,-90.568l0.034,-0.034l-0.034,-0.035l90.617,-90.584l0.024,0.025c3.124,-3.098 8.176,-3.09 11.29,0.024c3.122,3.122 3.122,8.192 0,11.314c-0.092,0.091 -79.269,79.256 -79.269,79.256l79.268,79.269l-0.027,0.027c3.114,3.123 3.111,8.187 -0.008,11.306c-3.122,3.122 -8.192,3.122 -11.314,0Z')
					]),
				_List_Nil)
			]));
};
var $author$project$View$Navigation$viewGetStarted = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('absolute tl ph3 pv4')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('link pointer'),
					$elm$html$Html$Attributes$href('/')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '1.5em'),
							A2($elm$html$Html$Attributes$style, 'height', '1.5em')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$chevronLeft('black')
						]))
				]))
		]));
var $author$project$Page$GetStarted$viewNoSignUp = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3 f3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Please sign up with the mobile app!')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3 f3 fw1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('This site currently only allows you to sign in ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3 f3 fw1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('with an existing account.')
					]))
			]));
};
var $author$project$Page$GetStarted$FirebaseSignIn = {$: 5};
var $author$project$Page$GetStarted$SendPasswordReset = {$: 8};
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$Attributes$minlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'minLength',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$GetStarted$validateSignIn = function (model) {
	return ($elm$core$String$length(model.q) > 5) ? true : false;
};
var $author$project$Page$GetStarted$viewPasswordSignIn = function (model) {
	var sendBtn = $author$project$Page$GetStarted$validateSignIn(model) ? A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(false),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor)
			])) : A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(true),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorMuted)
			]));
	var maybeError = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (x) {
				return x.$7 === 'password';
			},
			model.cQ));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(''),
				A2($elm$html$Html$Attributes$style, 'max-width', '310px'),
				A2($elm$html$Html$Attributes$style, 'margin', '40px auto'),
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'padding', '9px 9px 20px'),
				A2($elm$html$Html$Attributes$style, 'border-radius', '16px')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f2 w400')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('tĭbles')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 mb3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enter the '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw9')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('password')
							])),
						$elm$html$Html$text(' for '),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw5')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(model.da)
							]))
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$GetStarted$FirebaseSignIn),
						$elm$html$Html$Attributes$class('tc')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb2')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Password',
								A3($author$project$Page$GetStarted$labelState, model, 1, model.q),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('password'),
										$elm$html$Html$Attributes$id('passwordField'),
										$elm$html$Html$Attributes$value(model.q),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Attributes$minlength(6),
										$elm$html$Html$Events$onInput(
										$author$project$Page$GetStarted$UpdateInput(1)),
										$elm$html$Html$Events$onFocus(
										$author$project$Page$GetStarted$SetFieldFocus(1)),
										$elm$html$Html$Events$onBlur(
										$author$project$Page$GetStarted$UnsetFieldFocus(1)),
										$elm$html$Html$Attributes$name('password')
									]),
								maybeError)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pv3 mb2 flex items-center flex-column')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Page$GetStarted$SendPasswordReset),
										$elm$html$Html$Attributes$class('underline tc pointer pa2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('I forgot my password')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb2 f5')
							]),
						_List_fromArray(
							[sendBtn]))
					]))
			]));
};
var $author$project$Page$GetStarted$view = function (model) {
	var title = model.ef;
	var stageView = function () {
		var _v0 = model.aT;
		switch (_v0) {
			case 0:
				return $author$project$Page$GetStarted$viewEnterEmail(model);
			case 2:
				return $author$project$Page$GetStarted$viewNoSignUp(model);
			default:
				return $author$project$Page$GetStarted$viewPasswordSignIn(model);
		}
	}();
	var content = _List_fromArray(
		[
			$author$project$View$Navigation$viewGetStarted,
			A3(
			$elm$html$Html$Lazy$lazy2,
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
							A2($elm$html$Html$Attributes$style, 'height', '20px')
						]),
					_List_Nil),
					stageView
				]))
		]);
	return _Utils_Tuple2(title, content);
};
var $author$project$Page$Home$viewGuest = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
					A2($elm$html$Html$Attributes$style, 'height', '20px')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f2 w400 pt5')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('tĭbles')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f4 pb5')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Buy, browse, organize and display your crypto-collectibles.')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('link'),
							$elm$html$Html$Attributes$href('/get-started')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Ox$Button$contained,
							'Get Stared',
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
									A2($elm$html$Html$Attributes$style, 'color', 'black')
								]))
						]))
				]))
		]));
var $author$project$Page$Home$Stacks$New = {$: 0};
var $author$project$Modal$Picker$desktopModalMaskStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(0,0,0,0.5)'),
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'backdrop-filter', 'blur(5px)'),
		A2($elm$html$Html$Attributes$style, '-webkit-backdrop-filter', 'blur(5px)'),
		A2($elm$html$Html$Attributes$style, 'z-index', '5000')
	]);
var $author$project$Modal$Picker$mobileModalMaskStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(0,0,0,0)'),
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'z-index', '5000')
	]);
var $author$project$Modal$Picker$desktopModalStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
		A2($elm$html$Html$Attributes$style, 'top', '5%'),
		A2($elm$html$Html$Attributes$style, 'left', '5%'),
		A2($elm$html$Html$Attributes$style, 'height', '90%'),
		A2($elm$html$Html$Attributes$style, 'max-height', '90%'),
		A2($elm$html$Html$Attributes$style, 'width', '90%'),
		A2($elm$html$Html$Attributes$style, 'max-width', '112rem'),
		A2($elm$html$Html$Attributes$style, 'padding', '0px'),
		A2($elm$html$Html$Attributes$style, 'border-radius', '6px'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)'),
		$elm$html$Html$Attributes$class('animate__animated animate__fadeIn animate__faster')
	]);
var $author$project$Modal$Picker$mobileModalStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'padding', '0px'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)')
	]);
var $author$project$Modal$Picker$Cancel = {$: 2};
var $author$project$Ox$Theme$secondaryDarkColor = '#82ada9';
var $author$project$Ox$Theme$secondaryTextColor = '#000000';
var $author$project$Ox$Button$outlined = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '64px'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$secondaryDarkColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$secondaryTextColor),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Modal$Picker$viewCloseOrCancelBtn = function (model) {
	var title = model.W ? 'Cancel' : 'Close';
	return A2(
		$author$project$Ox$Button$outlined,
		title,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick($author$project$Modal$Picker$Cancel)
			]));
};
var $author$project$Modal$Picker$Save = {$: 3};
var $author$project$Modal$Picker$viewSaveBtn = function (_v0) {
	return A2(
		$author$project$Ox$Button$outlined,
		'Save',
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick($author$project$Modal$Picker$Save)
			]));
};
var $author$project$Modal$Picker$viewEditMenu = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-between mh3')
			]),
		_List_fromArray(
			[
				$author$project$Modal$Picker$viewCloseOrCancelBtn(model),
				$author$project$Modal$Picker$viewSaveBtn(model)
			]));
};
var $author$project$Modal$Picker$Search = function (a) {
	return {$: 8, a: a};
};
var $author$project$Modal$Picker$SearchMode = function (a) {
	return {$: 9, a: a};
};
var $author$project$Modal$Picker$viewMobileHeader = F2(
	function (model, screenSize) {
		if (model.Z) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-white avenir fixed w-100 top-0 tl ph3 flex flex-column justify-center bb b--black-20 animate__animated animate__fadeIn animate__faster'),
						A2($elm$html$Html$Attributes$style, 'z-index', '2000'),
						A2($elm$html$Html$Attributes$style, 'height', '54px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex items-center justify-between ')
							]),
						_List_fromArray(
							[
								A4($author$project$Page$viewSearchMenu, model.F, true, $author$project$Modal$Picker$Search, $author$project$Modal$Picker$SearchMode),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ml2 pointer'),
										A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue),
										$elm$html$Html$Events$onClick(
										$author$project$Modal$Picker$SearchMode(false))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Cancel')
									]))
							]))
					]));
		} else {
			var rightMenu = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f5 tr')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$contained,
						'Save',
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Modal$Picker$Save),
								A2($elm$html$Html$Attributes$style, 'background-color', 'green')
							]))
					]));
			var cancelOrClose = model.W ? 'Cancel' : 'Close';
			var leftMenu = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f5 tl')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Ox$Button$contained,
						cancelOrClose,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Modal$Picker$Cancel),
								A2($elm$html$Html$Attributes$style, 'background-color', 'red')
							]))
					]));
			return A4(
				$author$project$Page$viewMobileHeader,
				model.ef,
				screenSize,
				1,
				_Utils_Tuple2(leftMenu, rightMenu));
		}
	});
var $author$project$Modal$Picker$EditTitle = function (a) {
	return {$: 5, a: a};
};
var $author$project$Modal$Picker$EditTitleEnd = {$: 6};
var $author$project$Modal$Picker$EditTitleStart = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Modal$Picker$viewTitleOrEditField = function (model) {
	var _v0 = model.y;
	if (!_v0.$) {
		var editableTitle = _v0.a;
		var penIcon = A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f5 pl3 pt1 fas fa-pen fa-sm gray')
				]),
			_List_Nil);
		var btnEndEdit = (!_Utils_eq(editableTitle.H, editableTitle.bm)) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Modal$Picker$EditTitleEnd),
					$elm$html$Html$Attributes$class('absolute right-0 fas fa-check pa2 ml2 pointer green')
				]),
			_List_Nil) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var menu = editableTitle.ba ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative flex w-100 pb1')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$id('title-edit'),
							$elm$html$Html$Attributes$value(editableTitle.H),
							$elm$html$Html$Attributes$class('ph2 w-100 f3 fw6'),
							$elm$html$Html$Events$onInput($author$project$Modal$Picker$EditTitle),
							$elm$html$Html$Events$onBlur($author$project$Modal$Picker$EditTitleEnd)
						]),
					_List_Nil),
					btnEndEdit
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center f3 fw6 pb2'),
					$elm$html$Html$Events$onClick(
					A2($author$project$Modal$Picker$EditTitleStart, editableTitle.H, 'title-edit'))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(editableTitle.H),
					penIcon
				]));
		return menu;
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-centered f3 fw6 ')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(model.ef)
				]));
	}
};
var $author$project$Modal$Picker$viewPageTitle = F2(
	function (model, screenSize) {
		var viewerMobile = A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl flex flex-column justify-between ph2 mb2 pb2 bb b--black-20')
				]),
			_List_fromArray(
				[
					$author$project$Modal$Picker$viewTitleOrEditField(model),
					A4($author$project$Page$viewSearchMenu, model.F, true, $author$project$Modal$Picker$Search, $author$project$Modal$Picker$SearchMode)
				]));
		var viewerDesktop = A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl flex justify-between items-center ph2 mv2 ml2')
				]),
			_List_fromArray(
				[
					$author$project$Modal$Picker$viewTitleOrEditField(model),
					A4($author$project$Page$viewSearchMenu, model.F, false, $author$project$Modal$Picker$Search, $author$project$Modal$Picker$SearchMode)
				]));
		switch (screenSize.$) {
			case 0:
				return viewerMobile;
			case 1:
				return viewerMobile;
			default:
				return viewerDesktop;
		}
	});
var $author$project$Modal$Picker$Select = function (a) {
	return {$: 7, a: a};
};
var $author$project$Ox$Icon$checkCircle = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z')
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$Picker$viewPrintSelector = F2(
	function (model, print) {
		var maybeItem = A2($elm$core$Dict$get, print.du, model.d6);
		var _v0 = A2($elm$core$List$member, print.di, model.u) ? _Utils_Tuple2(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('absolute'),
						A2($elm$html$Html$Attributes$style, 'width', '30px'),
						A2($elm$html$Html$Attributes$style, 'height', '30px'),
						A2($elm$html$Html$Attributes$style, 'top', '4px'),
						A2($elm$html$Html$Attributes$style, 'right', '4px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$checkCircle('black')
					])),
			'flex flex-column justify-between items-center relative animate__animated animate__fadeIn animate__faster pointer') : _Utils_Tuple2(
			A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('absolute')
					]),
				_List_Nil),
			'flex flex-column justify-between items-center relative animate__animated animate__fadeIn animate__faster pointer');
		var selector = _v0.a;
		var liClass = _v0.b;
		if (!maybeItem.$) {
			var item = maybeItem.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Modal$Picker$Select(print.di)),
						$elm$html$Html$Attributes$class(liClass)
					]),
				_List_fromArray(
					[
						selector,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw5 f7 pv2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(item.ef)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tc o-80 f7 black')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'#' + $elm$core$String$fromInt(print.dW))
							]))
					]));
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	});
var $author$project$Modal$Picker$viewPrintGrid = F2(
	function (model, tempCol) {
		var prints = (model.F === '') ? $elm$core$Dict$values(model.dV) : model.af;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb0 ph2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ma0 pa0 pt1'),
							A2($elm$html$Html$Attributes$style, 'grid-template-columns', tempCol),
							A2($elm$html$Html$Attributes$style, 'display', 'grid'),
							A2($elm$html$Html$Attributes$style, 'max-height', '80vh'),
							A2($elm$html$Html$Attributes$style, 'grid-gap', '.5em')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Modal$Picker$viewPrintSelector(model),
						prints))
				]));
	});
var $author$project$Modal$Picker$viewEditModal = F2(
	function (model, screenSize) {
		var totalOwned = 'Total items: ' + $elm$core$String$fromInt(
			$elm$core$List$length(
				$elm$core$Dict$values(model.dV)));
		var selectedNumber = 'Selected: ' + $elm$core$String$fromInt(
			$elm$core$List$length(model.u));
		var maxNumber = 'Max: ' + $elm$core$String$fromInt(model.at);
		var info = selectedNumber + (', ' + maxNumber);
		var viewInfo = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center justify-between f5 ph2 pv2 b--black-20 gray relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(info)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(totalOwned)
						]))
				]));
		var viewDesktopEditModal = function (tempCol) {
			return A2(
				$elm$html$Html$div,
				$author$project$Modal$Picker$desktopModalStyles,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('overflow-y-scroll'),
								A2($elm$html$Html$Attributes$style, 'padding-top', '140px'),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'height', '98%'),
								$elm$html$Html$Attributes$id('print-grid')
							]),
						_List_fromArray(
							[
								A2($author$project$Modal$Picker$viewPrintGrid, model, tempCol)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('absolute top-0 w-100 bg-white bb b--black-20'),
								A2($elm$html$Html$Attributes$style, 'border-radius', '6px 6px 0 0')
							]),
						_List_fromArray(
							[
								$author$project$Page$viewShimTop('10px'),
								$author$project$Modal$Picker$viewEditMenu(model),
								A2($author$project$Modal$Picker$viewPageTitle, model, screenSize),
								viewInfo
							]))
					]));
		};
		var viewMobileEditModal = function (tempCol) {
			return A2(
				$elm$html$Html$div,
				_Utils_ap(
					$author$project$Modal$Picker$mobileModalStyles,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(model.ac)
						])),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'height', '100%'),
								$elm$html$Html$Attributes$class('overflow-y-scroll'),
								$elm$html$Html$Attributes$id('print-grid')
							]),
						_List_fromArray(
							[
								$author$project$Page$viewShim,
								A2($author$project$Modal$Picker$viewPageTitle, model, screenSize),
								viewInfo,
								A2($author$project$Modal$Picker$viewPrintGrid, model, tempCol),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('tc mt2 pb2')
									]),
								_List_Nil)
							])),
						A2($author$project$Modal$Picker$viewMobileHeader, model, screenSize)
					]));
		};
		switch (screenSize.$) {
			case 0:
				return viewMobileEditModal('repeat(auto-fill, minmax(100px, 1fr))');
			case 1:
				return viewMobileEditModal('repeat(auto-fill, minmax(125px, 1fr))');
			default:
				return viewDesktopEditModal('repeat(auto-fill, minmax(150px, 1fr))');
		}
	});
var $author$project$Modal$Picker$view = function (model) {
	var screenSize = $author$project$Type$Session$screenSize(model.d5);
	var modalStyles = function () {
		switch (screenSize.$) {
			case 0:
				return $author$project$Modal$Picker$mobileModalMaskStyles;
			case 1:
				return $author$project$Modal$Picker$mobileModalMaskStyles;
			default:
				return $author$project$Modal$Picker$desktopModalMaskStyles;
		}
	}();
	return A2(
		$elm$html$Html$div,
		modalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Modal$Picker$viewEditModal, model, screenSize)
					]))
			]));
};
var $author$project$Page$Home$Stacks$viewModalOrNot = function (model) {
	var _v0 = model.t;
	if (!_v0.$) {
		var editModel = _v0.a;
		return A2(
			$elm$html$Html$map,
			$author$project$Page$Home$Stacks$EditStackModalMsg,
			$author$project$Modal$Picker$view(editModel));
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Home$Stacks$viewEmptyStacks = function (model) {
	var emptyMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var addBtn = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '80px'),
				A2($elm$html$Html$Attributes$style, 'height', '80px')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pointer'),
						$elm$html$Html$Events$onClick($author$project$Page$Home$Stacks$New)
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$plus('black')
					]))
			]));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pb2 ph2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pb2')
					]),
				_List_fromArray(
					[
						A2($author$project$Page$viewTitle, model.ef, emptyMenu)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column items-center justify-center')
					]),
				_List_fromArray(
					[
						addBtn,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mv3 f4')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Add a new stack')
							]))
					])),
				$author$project$Page$Home$Stacks$viewModalOrNot(model)
			]));
};
var $author$project$Ox$Button$iconBig = F2(
	function (icon, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pointer link br2 flex items-center justify-around'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'width', '36px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '36px'),
							A2($elm$html$Html$Attributes$style, 'height', '36px')
						]),
					_List_fromArray(
						[icon]))
				]));
	});
var $author$project$Page$Home$Stacks$Delete = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$Stacks$MouseLeftCover = {$: 3};
var $author$project$Page$Home$Stacks$MouseOverCover = function (a) {
	return {$: 2, a: a};
};
var $author$project$View$Stack$feDropShadow = F2(
	function (attributes, children) {
		return A3($elm$svg$Svg$node, 'feDropShadow', attributes, children);
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$svg$Svg$Attributes$version = _VirtualDom_attribute('version');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $author$project$View$Stack$viewCard = function (_v0) {
	var _v1 = _v0.a;
	var x = _v1.a;
	var r = _v1.b;
	var tible = _v0.b;
	var translateStr = 'translate(' + ($elm$core$String$fromInt(x) + ', -10) ');
	var scaleStr = 'scale(.95,.95)';
	var ctrX = ((x / 2) | 0) + 500;
	var rotateStr = 'rotate(' + ($elm$core$String$fromInt(r) + (' 110 ' + ($elm$core$String$fromInt(ctrX) + ') ')));
	var transformStr = _Utils_ap(
		rotateStr,
		_Utils_ap(scaleStr, translateStr));
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 300 500'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2,align-self:center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$transform(transformStr)
					]),
				_List_fromArray(
					[
						A2(
						$author$project$View$Sticker$front,
						tible,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$style('filter:url(#stackShadow);')
							]))
					]))
			]));
};
var $elm$svg$Svg$Attributes$xmlSpace = A2(_VirtualDom_attributeNS, 'http://www.w3.org/XML/1998/namespace', 'xml:space');
var $author$project$View$Stack$fanOut = function (tibles) {
	var howMany = $elm$core$List$length(tibles);
	var exes = _List_fromArray(
		[
			_Utils_Tuple2(-200, -5),
			_Utils_Tuple2(0, 0),
			_Utils_Tuple2(200, 5)
		]);
	var assembleImageList = F2(
		function (num, xlist) {
			return A2(
				$elm$core$List$map,
				$author$project$View$Stack$viewCard,
				$elm$core$List$reverse(
					A3(
						$elm$core$List$map2,
						$elm$core$Tuple$pair,
						xlist,
						A2($elm$core$List$take, num, tibles))));
		});
	var imageList = (!howMany) ? _List_Nil : ((howMany === 1) ? A2(assembleImageList, 1, exes) : ((howMany === 2) ? A2(assembleImageList, 2, exes) : A2(assembleImageList, 3, exes)));
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$version('1.1'),
				$elm$svg$Svg$Attributes$xmlSpace('preserve'),
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 500 300')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$svg$Svg$defs,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$filter,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$id('stackShadow')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$View$Stack$feDropShadow,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$dx('0'),
										$elm$svg$Svg$Attributes$dy('0'),
										$elm$svg$Svg$Attributes$stdDeviation('6'),
										$elm$svg$Svg$Attributes$floodOpacity('0.5')
									]),
								_List_Nil)
							]))
					])),
			imageList));
};
var $author$project$Reorder$onCustomClick = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{b4: msg, dT: true, eb: true}));
};
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$Home$Stacks$viewStackCover = F2(
	function (model, stack) {
		var stackTibles = A2(
			$elm$core$List$filterMap,
			function (p) {
				return p;
			},
			A2(
				$elm$core$List$map,
				function (ref) {
					return A2($elm$core$Dict$get, ref.di, model.bv);
				},
				stack.dX));
		var count = $elm$core$List$length(stack.dX);
		var actionButtonOrNot = _Utils_eq(
			model.am,
			$elm$core$Maybe$Just(stack.di)) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('absolute top-0 right-0 h-100 flex flex-column-reverse justify-between')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('black far fa-trash-alt ma1'),
							$author$project$Reorder$onCustomClick(
							$author$project$Page$Home$Stacks$Delete(stack.di))
						]),
					_List_Nil)
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('absolute')
				]),
			_List_Nil);
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Events$onMouseEnter(
					$author$project$Page$Home$Stacks$MouseOverCover(stack.di)),
					$elm$html$Html$Events$onMouseLeave($author$project$Page$Home$Stacks$MouseLeftCover),
					$elm$html$Html$Attributes$class('pointer tc mr2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('/stack/' + stack.di)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex items-center justify-center relative')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('absolute top-0 left-0 ma1 fw6 ml3 f7 br4 ph2 pv1 white bg-black-40 inline-flex items-center')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(count))
										])),
									$author$project$View$Stack$fanOut(stackTibles),
									actionButtonOrNot
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pv2 f5 fw6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(' ' + stack.ef)
						]))
				]));
	});
var $author$project$Page$Home$Stacks$viewStacks = function (model) {
	var menu = (_Utils_cmp(
		$elm$core$List$length(model.d8),
		model.at) < 0) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$iconBig,
				$author$project$Ox$Icon$plus('black'),
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Page$Home$Stacks$New)
					]))
			])) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var colStyle = function () {
		var _v0 = model.cs;
		switch (_v0.$) {
			case 0:
				return '1fr 1fr';
			case 1:
				return '1fr 1fr 1fr';
			default:
				return 'repeat(auto-fill, 250px)';
		}
	}();
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pb2 ph2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pb2')
					]),
				_List_fromArray(
					[
						A2($author$project$Page$viewTitle, model.ef, menu)
					])),
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('list ma0 pa0'),
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', colStyle),
						A2($elm$html$Html$Attributes$style, 'grid-gap', '0')
					]),
				A2(
					$elm$core$List$map,
					$author$project$Page$Home$Stacks$viewStackCover(model),
					model.d8)),
				$author$project$Page$Home$Stacks$viewModalOrNot(model)
			]));
};
var $author$project$Page$Home$Stacks$view = function (model) {
	return (!$elm$core$List$length(model.d8)) ? $author$project$Page$Home$Stacks$viewEmptyStacks(model) : $author$project$Page$Home$Stacks$viewStacks(model);
};
var $author$project$Page$Home$Wall$Edit = {$: 9};
var $author$project$Page$Home$Wall$viewEditWallModal = F2(
	function (model, subModel) {
		return A2(
			$elm$html$Html$map,
			$author$project$Page$Home$Wall$EditWallModalMsg,
			$author$project$Modal$Picker$view(subModel));
	});
var $author$project$Page$Home$Wall$CloseModal = {$: 10};
var $author$project$Page$Home$Wall$modalStyle = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
		A2($elm$html$Html$Attributes$style, 'top', '50%'),
		A2($elm$html$Html$Attributes$style, 'left', '50%'),
		A2($elm$html$Html$Attributes$style, 'height', 'auto'),
		A2($elm$html$Html$Attributes$style, 'max-height', '80%'),
		A2($elm$html$Html$Attributes$style, 'width', '900px'),
		A2($elm$html$Html$Attributes$style, 'max-width', '95%'),
		A2($elm$html$Html$Attributes$style, 'padding', '0px'),
		A2($elm$html$Html$Attributes$style, 'border-radius', '6px'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)'),
		A2($elm$html$Html$Attributes$style, 'transform', 'translate(-50%, -50%)')
	]);
var $author$project$Ox$Theme$desktopModalMaskStyles = function (attributes) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(0,0,0,0.5)'),
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'backdrop-filter', 'blur(5px)'),
				A2($elm$html$Html$Attributes$style, '-webkit-backdrop-filter', 'blur(5px)'),
				A2($elm$html$Html$Attributes$style, 'z-index', '5000')
			]),
		attributes);
};
var $author$project$Page$Home$Wall$viewModalMask = F3(
	function (model, animateStr, content) {
		var _v0 = model.cs;
		switch (_v0.$) {
			case 0:
				return A2(
					$elm$html$Html$div,
					$author$project$Ox$Theme$mobileModalMaskStyles(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'background-image', model.aH)
							])),
					_List_fromArray(
						[content]));
			case 1:
				return A2(
					$elm$html$Html$div,
					$author$project$Ox$Theme$mobileModalMaskStyles(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'background-image', model.aH)
							])),
					_List_fromArray(
						[content]));
			default:
				return A2(
					$elm$html$Html$div,
					$author$project$Ox$Theme$desktopModalMaskStyles(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'background-image', model.aH),
								A2($elm$html$Html$Attributes$style, 'opacity', '0.9'),
								$elm$html$Html$Attributes$class(animateStr)
							])),
					_List_fromArray(
						[content]));
		}
	});
var $author$project$Page$Home$Wall$viewShelvesInModal = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ma3 mb0 ba b--black-20 pa3')
			]),
		_List_Nil);
};
var $author$project$Page$Home$Wall$viewShareModal = function (model) {
	return A3(
		$author$project$Page$Home$Wall$viewModalMask,
		model,
		'',
		A2(
			$elm$html$Html$div,
			$author$project$Page$Home$Wall$modalStyle,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('relative')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f3 pl3 pt3 pb2 b--black-30'),
									A2($elm$html$Html$Attributes$style, 'border-radius', '10px 10px 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Share it!')
								])),
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('absolute top-0 right-0 fas fa-times fa-2x pointer pr3 pt3'),
									$elm$html$Html$Events$onClick($author$project$Page$Home$Wall$CloseModal)
								]),
							_List_Nil)
						])),
					$author$project$Page$Home$Wall$viewShelvesInModal(model),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tc mt2 pb2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fab fa-instagram fa-2x pa2')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fab fa-twitter fa-2x pa2')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fab fa-facebook fa-2x pa2')
								]),
							_List_Nil)
						]))
				])));
};
var $author$project$Page$Home$Wall$viewTibleModal = F2(
	function (model, subModel) {
		return A3(
			$author$project$Page$Home$Wall$viewModalMask,
			model,
			'animate__animated animate__zoomIn animate__faster',
			A2(
				$elm$html$Html$map,
				$author$project$Page$Home$Wall$TibleModalMsg,
				$author$project$Modal$Tible$view(subModel)));
	});
var $author$project$Page$Home$Wall$viewModalOrNot = function (model) {
	var _v0 = model.z;
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 0:
				var subModel = _v0.a.a;
				return A2($author$project$Page$Home$Wall$viewTibleModal, model, subModel);
			case 1:
				var subModel = _v0.a.a;
				return A2($author$project$Page$Home$Wall$viewEditWallModal, model, subModel);
			default:
				var _v1 = _v0.a;
				return $author$project$Page$Home$Wall$viewShareModal(model);
		}
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $author$project$Ox$Theme$secondaryLightColor = '#e5ffff';
var $author$project$Ox$Theme$primaryTextColor = '#000000';
var $author$project$Ox$Button$containedIcon = F3(
	function (title, icon, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryTextColor),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '64px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px 0px 12px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '18px'),
							A2($elm$html$Html$Attributes$style, 'height', '18px')
						]),
					_List_fromArray(
						[icon])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-left', '8px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Page$Home$Wall$viewEmptyShelf = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('ba br4 list pa0 mb4 flex items-center justify-around'),
			A2($elm$html$Html$Attributes$style, 'height', '350px'),
			A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$secondaryLightColor)
		]),
	_List_fromArray(
		[
			A3(
			$author$project$Ox$Button$containedIcon,
			'Put some stuff on your wall',
			$author$project$Ox$Icon$plus($author$project$Ox$Theme$primaryTextColor),
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Page$Home$Wall$Edit),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid black')
				]))
		]));
var $author$project$Page$Home$Wall$DragDropMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Home$Wall$EnterShelfItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Home$Wall$LeaveShelfItem = {$: 3};
var $author$project$Page$Home$Wall$OpenTibleModal = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnd = {$: 1};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragStart = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions = F3(
	function (name, _v0, decoder) {
		var stopPropagation = _v0.eb;
		var preventDefault = _v0.dT;
		return A2(
			$elm$html$Html$Events$custom,
			name,
			A2(
				$elm$json$Json$Decode$map,
				function (msg) {
					return {b4: msg, dT: preventDefault, eb: stopPropagation};
				},
				decoder));
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$draggable = F2(
	function (wrap, drag) {
		return _List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'draggable', 'true'),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragstart',
				{dT: false, eb: true},
				A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						wrap,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragStart(drag)),
					$elm$json$Json$Decode$value)),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragend',
				{dT: false, eb: true},
				$elm$json$Json$Decode$succeed(
					wrap($norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnd)))
			]);
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnter = function (a) {
	return {$: 2, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragLeave = function (a) {
	return {$: 3, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragOver = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$Drop = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$Position = F4(
	function (width, height, x, y) {
		return {a9: height, bz: width, cK: x, eo: y};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $norpan$elm_html5_drag_drop$Html5$DragDrop$positionDecoder = A5(
	$elm$json$Json$Decode$map4,
	$norpan$elm_html5_drag_drop$Html5$DragDrop$Position,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['currentTarget', 'clientWidth']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['currentTarget', 'clientHeight']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$round,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['offsetX']),
			$elm$json$Json$Decode$float)),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$round,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['offsetY']),
			$elm$json$Json$Decode$float)));
var $norpan$elm_html5_drag_drop$Html5$DragDrop$timeStampDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$round,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['timeStamp']),
		$elm$json$Json$Decode$float));
var $norpan$elm_html5_drag_drop$Html5$DragDrop$droppable = F2(
	function (wrap, dropId) {
		return _List_fromArray(
			[
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragenter',
				{dT: true, eb: true},
				$elm$json$Json$Decode$succeed(
					wrap(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnter(dropId)))),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragleave',
				{dT: true, eb: true},
				$elm$json$Json$Decode$succeed(
					wrap(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragLeave(dropId)))),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragover',
				{dT: true, eb: false},
				A2(
					$elm$json$Json$Decode$map,
					wrap,
					A3(
						$elm$json$Json$Decode$map2,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragOver(dropId),
						$norpan$elm_html5_drag_drop$Html5$DragDrop$timeStampDecoder,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$positionDecoder))),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'drop',
				{dT: true, eb: true},
				A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						wrap,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$Drop(dropId)),
					$norpan$elm_html5_drag_drop$Html5$DragDrop$positionDecoder))
			]);
	});
var $author$project$View$Sticker$placeholder = function (attributes) {
	var o = '0.2';
	var imageUrl = '';
	var h = 1000;
	var w = $elm$core$String$fromFloat(0.667 * h);
	var bgColor = '#dddddd';
	return A2(
		$elm$svg$Svg$svg,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$width('100%'),
					$elm$svg$Svg$Attributes$height('100%'),
					$elm$svg$Svg$Attributes$viewBox('0 0 667 1000'),
					$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
				]),
			attributes),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$rect,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(bgColor),
						$elm$svg$Svg$Attributes$stroke('#000000'),
						$elm$svg$Svg$Attributes$width(w),
						$elm$svg$Svg$Attributes$height('1000'),
						$elm$svg$Svg$Attributes$rx('40'),
						$elm$svg$Svg$Attributes$ry('40'),
						$elm$svg$Svg$Attributes$y('0'),
						$elm$svg$Svg$Attributes$x('0'),
						$elm$svg$Svg$Attributes$opacity(o)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$image,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$xlinkHref(imageUrl),
						$elm$svg$Svg$Attributes$height('667'),
						$elm$svg$Svg$Attributes$width('667'),
						$elm$svg$Svg$Attributes$y('167'),
						$elm$svg$Svg$Attributes$x('1'),
						$elm$svg$Svg$Attributes$opacity(o)
					]),
				_List_Nil)
			]));
};
var $author$project$Page$viewPrintNumber = function (number) {
	return (!number) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('white pa1 tc f7 white')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('.')
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tc f7 white pv1 ph2 bg-dark-blue o-90 ba b--white-50')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				'#' + $elm$core$String$fromInt(number))
			]));
};
var $author$project$Page$Home$Wall$viewItem = F4(
	function (model, shelfIndex, index, sortable) {
		var newIndex = (shelfIndex * model.az) + index;
		var dragIndex = $norpan$elm_html5_drag_drop$Html5$DragDrop$getDragId(model.an);
		switch (sortable.$) {
			case 0:
				var tible = sortable.a;
				var sideMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
				var printNumberIsVisible = A2($elm$core$List$member, tible.dU.di, model.w);
				var mouseIsHovering = _Utils_eq(dragIndex, $elm$core$Maybe$Nothing) && _Utils_eq(
					$elm$core$Maybe$Just(newIndex),
					model.ad);
				var isMobile = function () {
					var _v2 = model.cs;
					switch (_v2.$) {
						case 0:
							return true;
						case 1:
							return true;
						default:
							return false;
					}
				}();
				var _v1 = printNumberIsVisible ? _Utils_Tuple2(
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('absolute bottom--1')
							]),
						_List_fromArray(
							[
								$author$project$Page$viewPrintNumber(tible.dU.dW)
							])),
					'Hide') : _Utils_Tuple2(
					A2($elm$html$Html$div, _List_Nil, _List_Nil),
					'Show');
				var showPrintNumberOrNot = _v1.a;
				return A2(
					$elm$html$Html$li,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$Home$Wall$OpenTibleModal, tible, newIndex)),
								$elm$html$Html$Events$onMouseEnter(
								$author$project$Page$Home$Wall$EnterShelfItem(newIndex)),
								$elm$html$Html$Events$onMouseLeave($author$project$Page$Home$Wall$LeaveShelfItem),
								$elm$html$Html$Attributes$class('flex items-end justify-center ma0 pt1 ph1 pb0 relative animate__animated animate__fadeIn move')
							]),
						_Utils_ap(
							A2($norpan$elm_html5_drag_drop$Html5$DragDrop$draggable, $author$project$Page$Home$Wall$DragDropMsg, newIndex),
							A2($norpan$elm_html5_drag_drop$Html5$DragDrop$droppable, $author$project$Page$Home$Wall$DragDropMsg, newIndex))),
					_List_fromArray(
						[
							sideMenu,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'max-width', '240px')
								]),
							_List_fromArray(
								[
									A2($author$project$View$Sticker$front, tible, _List_Nil)
								])),
							showPrintNumberOrNot
						]));
			case 2:
				return A2(
					$elm$html$Html$li,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex items-end justify-center ma0 pt1 ph1 pb0 relative br3'),
								A2($elm$html$Html$Attributes$style, 'border', '2px dashed #aaa'),
								A2($elm$html$Html$Attributes$style, 'margin', '-2px')
							]),
						A2($norpan$elm_html5_drag_drop$Html5$DragDrop$droppable, $author$project$Page$Home$Wall$DragDropMsg, newIndex)),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'max-width', '240px')
								]),
							_List_fromArray(
								[
									$author$project$View$Sticker$placeholder(_List_Nil)
								]))
						]));
			default:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('item'),
							A2($elm$html$Html$Attributes$style, 'display', 'hidden')
						]),
					_List_Nil);
		}
	});
var $author$project$Page$Home$Wall$viewShelf = F3(
	function (model, index, sortables) {
		var gridTempCol = function () {
			var _v0 = $elm$core$List$length(model.cy);
			switch (_v0) {
				case 1:
					return '1fr';
				case 2:
					return '1fr 1fr';
				case 3:
					return '1fr 1fr 1fr';
				case 4:
					return '1fr 1fr 1fr 1fr';
				case 5:
					return '1fr 1fr 1fr';
				case 6:
					return '1fr 1fr 1fr';
				default:
					return '1fr 1fr 1fr 1fr';
			}
		}();
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list relative ma0 pa0'),
					A2($elm$html$Html$Attributes$style, 'grid-template-columns', gridTempCol),
					A2($elm$html$Html$Attributes$style, 'grid-column-gap', '0'),
					A2($elm$html$Html$Attributes$style, 'display', 'grid')
				]),
			A2(
				$elm$core$List$indexedMap,
				A2($author$project$Page$Home$Wall$viewItem, model, index),
				sortables));
	});
var $author$project$Page$Home$Wall$viewShelvesOrEmpty = function (model) {
	var itemCount = $elm$core$List$length(model.cy);
	if (!itemCount) {
		return $author$project$Page$Home$Wall$viewEmptyShelf;
	} else {
		var numOnShelf = $author$project$Page$Home$Wall$countPrintsPerRow(itemCount);
		var shelves = A2($elm_community$list_extra$List$Extra$greedyGroupsOf, numOnShelf, model.cy);
		var bgImage = 'url(' + ($author$project$Ox$Theme$backgroundWallImage + ')');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list br4 pa4 mb4 flex flex-column items-center justify-around'),
					A2($elm$html$Html$Attributes$style, 'border', '2px solid black'),
					A2($elm$html$Html$Attributes$style, 'min-height', '250px'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$secondaryLightColor),
					A2($elm$html$Html$Attributes$style, 'background-image', bgImage),
					A2($elm$html$Html$Attributes$style, 'background-repeat', 'repeat')
				]),
			A2(
				$elm$core$List$indexedMap,
				$author$project$Page$Home$Wall$viewShelf(model),
				shelves));
	}
};
var $author$project$Page$Home$Wall$view = function (model) {
	var editBtn = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$iconBig,
				$author$project$Ox$Icon$plus('black'),
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Page$Home$Wall$Edit)
					]))
			]));
	var menu = ($elm$core$List$length(model.aD) > 0) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center')
			]),
		_List_fromArray(
			[editBtn])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center')
			]),
		_List_Nil);
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pb3')
					]),
				_List_fromArray(
					[
						A2($author$project$Page$viewTitle, model.ef, menu)
					])),
				$author$project$Page$Home$Wall$viewShelvesOrEmpty(model),
				$author$project$Page$Home$Wall$viewModalOrNot(model)
			]));
};
var $author$project$View$Profile$Close = 2;
var $author$project$View$Profile$NoOp = 0;
var $author$project$Ox$Sheet$action = F2(
	function (attributes, content) {
		return A2(
			$elm$html$Html$div,
			$author$project$Ox$Theme$mobileModalMaskStyles(attributes),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-column items-end'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2($elm$html$Html$Attributes$style, 'bottom', '0'),
							A2($elm$html$Html$Attributes$style, 'left', '0'),
							$elm$html$Html$Attributes$class('animate__animated animate__slideInUp animate__faster')
						]),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$View$Profile$viewModalOrNot = function (model) {
	var _v0 = model.z;
	if (!_v0.$) {
		var _v1 = _v0.a;
		return A2(
			$author$project$Ox$Sheet$action,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'z-index', '10000')
				]),
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('cancel')
					])));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$View$Profile$onCustomClick = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{b4: msg, dT: true, eb: true}));
};
var $author$project$View$Profile$OpenActionSheet = 1;
var $author$project$Ox$Button$containedSmall = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f7 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
					A2($elm$html$Html$Attributes$style, 'color', 'white'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 10px'),
					A2($elm$html$Html$Attributes$style, 'height', '24px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '48px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$View$Profile$viewEmailBar = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(1),
				$elm$html$Html$Attributes$class('fixed bottom-0 bt b--black-20 w-100 bg-near-white flex items-center justify-center'),
				A2($elm$html$Html$Attributes$style, 'padding', '6px 0 4px')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$containedSmall,
				model.aY.da,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'background-color', 'black')
					]))
			]));
};
var $author$project$View$Profile$viewSheet = function (model) {
	var header = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('items-center'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr')
			]),
		_List_Nil);
	var defaultImg = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin-top', '30px 0'),
				A2($elm$html$Html$Attributes$style, 'height', '160px'),
				A2($elm$html$Html$Attributes$style, 'width', '160px')
			]),
		_List_fromArray(
			[
				$author$project$Ox$Icon$accountCircle('black')
			]));
	var avatar = function () {
		var _v0 = model.aY.dz;
		if (!_v0.$) {
			var profile = _v0.a;
			var userImg = (profile.bX === '') ? defaultImg : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'margin', '30px 0'),
						A2($elm$html$Html$Attributes$style, 'height', '160px'),
						A2($elm$html$Html$Attributes$style, 'width', '160px'),
						A2($elm$html$Html$Attributes$style, 'clip-path', 'circle(80px at center)')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(profile.bX)
							]),
						_List_Nil)
					]));
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 flex flex-column items-center justify-around')
					]),
				_List_fromArray(
					[
						userImg,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw6 f4 tc')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(profile.ej)
							]))
					]));
		} else {
			return defaultImg;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$View$Profile$onCustomClick(0),
				$elm$html$Html$Attributes$class('w-100 tl h-100'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '64px')
			]),
		_List_fromArray(
			[
				header,
				avatar,
				$author$project$View$Profile$viewEmailBar(model)
			]));
};
var $author$project$View$Profile$view = function (model) {
	var _v0 = model.cs;
	switch (_v0.$) {
		case 0:
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$author$project$Ox$Sheet$bottom,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(2)
							]),
						_List_fromArray(
							[
								$author$project$Ox$Events$onClick(0)
							]),
						$author$project$View$Profile$viewSheet(model)),
						$author$project$View$Profile$viewModalOrNot(model)
					]));
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$author$project$Ox$Sheet$bottom,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(2)
							]),
						_List_fromArray(
							[
								$author$project$Ox$Events$onClick(0)
							]),
						$author$project$View$Profile$viewSheet(model)),
						$author$project$View$Profile$viewModalOrNot(model)
					]));
		default:
			return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Home$viewModalOrNot = F2(
	function (model, user) {
		var screenSize = $author$project$Type$Session$screenSize(model.d5);
		var _v0 = model.z;
		if (!_v0.$) {
			var subModel = _v0.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Page$Home$ProfileMsg,
				$author$project$View$Profile$view(subModel));
		} else {
			return A2($elm$html$Html$span, _List_Nil, _List_Nil);
		}
	});
var $author$project$Page$Home$viewMain = F2(
	function (model, user) {
		return A2(
			$elm$html$Html$main_,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim,
							A3(
							$author$project$Page$viewPageTitle,
							model.ef,
							model.cs,
							A2($elm$html$Html$span, _List_Nil, _List_Nil)),
							A2(
							$elm$html$Html$map,
							$author$project$Page$Home$WallSectionMsg,
							$author$project$Page$Home$Wall$view(model.C)),
							A2(
							$elm$html$Html$map,
							$author$project$Page$Home$StackSectionMsg,
							$author$project$Page$Home$Stacks$view(model.G)),
							A2($author$project$Page$Home$viewModalOrNot, model, user)
						]))
				]));
	});
var $author$project$Page$Home$OpenProfileSheet = {$: 5};
var $author$project$Page$Home$viewMobileHeader = F2(
	function (user, model) {
		var rightMenu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pr2 fw6 f7 flex items-center justify-end '),
					$elm$html$Html$Events$onClick($author$project$Page$Home$OpenProfileSheet)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ba bg--black'),
							A2($elm$html$Html$Attributes$style, 'height', '32px'),
							A2($elm$html$Html$Attributes$style, 'width', '32px')
						]),
					_List_Nil)
				]));
		var leftMenu = function () {
			var _v0 = user.dz;
			if (!_v0.$) {
				var profile = _v0.a;
				var userImg = (profile.bX === '') ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'height', '30px'),
							A2($elm$html$Html$Attributes$style, 'width', '30px'),
							A2($elm$html$Html$Attributes$style, 'margin-right', '4px')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$accountCircle('black')
						])) : A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'height', '30px'),
							A2($elm$html$Html$Attributes$style, 'width', '30px'),
							A2($elm$html$Html$Attributes$style, 'margin-right', '4px'),
							A2($elm$html$Html$Attributes$style, 'clip-path', 'circle(15px at center)')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(profile.bX)
								]),
							_List_Nil)
						]));
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tl pl2 fw6 f7 flex items-center'),
							$elm$html$Html$Events$onClick($author$project$Page$Home$OpenProfileSheet)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class(''),
									A2($elm$html$Html$Attributes$style, 'height', '32px'),
									A2($elm$html$Html$Attributes$style, 'width', '32px')
								]),
							_List_fromArray(
								[userImg])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(profile.ej)
								]))
						]));
			} else {
				return A2($elm$html$Html$span, _List_Nil, _List_Nil);
			}
		}();
		return A4(
			$author$project$Page$viewMobileHeader,
			model.ef,
			model.cs,
			0,
			_Utils_Tuple2(leftMenu, rightMenu));
	});
var $author$project$Page$Home$viewLoggedInOrGuest = function (model) {
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Home$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, params.d0, model.d5)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$Home$viewMain, model, user),
				A2($author$project$Page$Home$viewMobileHeader, user, model)
			]);
	} else {
		return _List_fromArray(
			[$author$project$Page$Home$viewGuest]);
	}
};
var $author$project$Page$Home$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.ef),
		$author$project$Page$Home$viewLoggedInOrGuest(model));
};
var $author$project$Page$Market$viewMobileHeader = F2(
	function (model, credits) {
		var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var leftMenu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-column justify-center tl pv2 ml3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f6'),
							A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryColor)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(credits)
						]))
				]));
		return A4(
			$author$project$Page$viewMobileHeader,
			model.ef,
			model.cs,
			0,
			_Utils_Tuple2(leftMenu, rightMenu));
	});
var $author$project$Page$Market$GoSetPacks = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Market$viewSet = function (set) {
	var color = 'black';
	var borderRadius = '12px';
	var bgImageUrl = 'url(' + (set.dj + ')');
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pointer flex flex-column ma0 relative animate__animated animate__fadeIn animate__faster'),
				A2($elm$html$Html$Attributes$style, 'border', '2px solid black'),
				A2($elm$html$Html$Attributes$style, 'border-radius', borderRadius),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorWhite),
				$elm$html$Html$Events$onClick(
				$author$project$Page$Market$GoSetPacks(set.di))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '240px'),
								A2($elm$html$Html$Attributes$style, 'border-top-left-radius', borderRadius),
								A2($elm$html$Html$Attributes$style, 'border-top-right-radius', borderRadius),
								A2($elm$html$Html$Attributes$style, 'background-image', bgImageUrl),
								A2($elm$html$Html$Attributes$style, 'background-size', 'cover'),
								A2($elm$html$Html$Attributes$style, 'background-position', 'center')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tl mv3 mh3'),
								A2($elm$html$Html$Attributes$style, 'color', color)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('f6 fw3 gray')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('The Places of Dr. Seuss')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('f4 fw5 mv1')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(set.ef)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('lh-copy f6 gray')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(set.c5)
									]))
							]))
					]))
			]));
};
var $author$project$Page$Market$viewSection = function (model) {
	var tempCol = function () {
		var _v0 = model.cs;
		if (!_v0.$) {
			return 'repeat(auto-fill, minmax(290px, 1fr))';
		} else {
			return 'repeat(auto-fill, minmax(480px, 1fr))';
		}
	}();
	var sets = A2(
		$elm$core$List$sortBy,
		function ($) {
			return $.c4;
		},
		$elm$core$Dict$values(model.d6));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mh3 mt3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('list pv2'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', tempCol),
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-gap', '.5em')
					]),
				A2($elm$core$List$map, $author$project$Page$Market$viewSet, sets))
			]));
};
var $author$project$Page$Market$viewMain = F2(
	function (model, user) {
		var menu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var credits = function () {
			var _v0 = user.dw;
			if (!_v0.$) {
				var account = _v0.a;
				return 'Coins: ' + $elm$core$String$fromInt(account.c0);
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pb5')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim,
							A3($author$project$Page$viewPageTitle, model.ef, model.cs, menu),
							$author$project$Page$Market$viewSection(model),
							A2($author$project$Page$Market$viewMobileHeader, model, credits)
						]))
				]));
	});
var $author$project$Page$Market$viewBody = function (model) {
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Market$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, params.d0, model.d5)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$Market$viewMain, model, user)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Market$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.ef),
		$author$project$Page$Market$viewBody(model));
};
var $author$project$Page$Message$PageMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Message$GoToChat = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Message$viewChatRoom = F2(
	function (model, chat) {
		var _v0 = function () {
			var _v1 = $author$project$Type$Session$screenSize(model.d5);
			switch (_v1.$) {
				case 0:
					return _Utils_Tuple2('f4 fw3 pb1 ml2', 'f6 fw4 ml2');
				case 1:
					return _Utils_Tuple2('f4 fw3 pb1 ml2', 'f6 fw4 ml2');
				default:
					return _Utils_Tuple2('f3 fw3 pb1', 'f5 fw4');
			}
		}();
		var titleStyle = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Page$Message$GoToChat(chat.di)),
					$elm$html$Html$Attributes$class('tl pv3 bb b--black-10 pointer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(titleStyle)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(chat.ef)
						]))
				]));
	});
var $author$project$Page$Message$viewChats = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph2')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$author$project$Page$viewTitle,
				'Rooms',
				A2($elm$html$Html$span, _List_Nil, _List_Nil)),
			A2(
				$elm$core$List$map,
				$author$project$Page$Message$viewChatRoom(model),
				model.a3)));
};
var $author$project$Page$Message$viewLoggedIn = function (model) {
	var menu = A2($elm$html$Html$span, _List_Nil, _List_Nil);
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
						A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim,
						A3(
						$author$project$Page$viewPageTitle,
						model.ef,
						$author$project$Type$Session$screenSize(model.d5),
						menu),
						$author$project$Page$Message$viewChats(model)
					]))
			]));
};
var $author$project$Page$Message$viewMobileHeader = function (model) {
	var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var leftMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	return A4(
		$author$project$Page$viewMobileHeader,
		model.ef,
		$author$project$Type$Session$screenSize(model.d5),
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Page$Message$viewLoggedInOrGuest = function (model) {
	var screenSize = $author$project$Type$Session$screenSize(model.d5);
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Message$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, params.d0, model.d5)),
				A2($elm$html$Html$Lazy$lazy, $author$project$Page$Message$viewLoggedIn, model),
				$author$project$Page$Message$viewMobileHeader(model)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Message$view = function (model) {
	return _Utils_Tuple2(
		model.ef,
		$author$project$Page$Message$viewLoggedInOrGuest(model));
};
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $author$project$Page$Profile$viewPersonalInfo = function (profile) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(profile.bX),
						A2($elm$html$Html$Attributes$style, 'border-radius', '50%'),
						A2($elm$html$Html$Attributes$style, 'max-width', '160px')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt3 f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(profile.ej)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt3 f5 fw3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(profile.cT)
					]))
			]));
};
var $author$project$Page$Profile$viewLoggedIn = F3(
	function (profile, _v0, _v1) {
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ph2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim,
							$author$project$Page$Profile$viewPersonalInfo(profile)
						]))
				]));
	});
var $author$project$Page$Profile$viewMobileHeader = function (model) {
	var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var leftMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center tl pv2 pl3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '1.5em'),
						A2($elm$html$Html$Attributes$style, 'height', '1.5em'),
						A2($elm$html$Html$Attributes$style, 'margin-left', '-10px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$chevronLeft($author$project$Setting$hiliteBlue)
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('/home'),
						$elm$html$Html$Attributes$class('link black dib'),
						A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('My Room')
					]))
			]));
	return A4(
		$author$project$Page$viewMobileHeader,
		model.ef,
		model.cs,
		1,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Page$viewMobileNavAlt = F2(
	function (menu, screenSize) {
		var mobileView = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-white fixed w-100 tc bottom-0 bt b--black-20'),
					A2($elm$html$Html$Attributes$style, 'z-index', '3000')
				]),
			_List_fromArray(
				[menu]));
		var desktopView = A2($elm$html$Html$span, _List_Nil, _List_Nil);
		switch (screenSize.$) {
			case 0:
				return mobileView;
			case 1:
				return mobileView;
			default:
				return desktopView;
		}
	});
var $author$project$Page$Profile$viewLoggedInOrGuest = function (model) {
	var _v0 = _Utils_Tuple2(model.d5, model.dz);
	if ((_v0.a.$ === 3) && (!_v0.b.$)) {
		var _v1 = _v0.a;
		var user = _v1.b;
		var profile = _v0.b.a;
		var menu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pa2')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('')
				]));
		return _List_fromArray(
			[
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewMobileNavAlt, menu, model.cs),
				A4($elm$html$Html$Lazy$lazy3, $author$project$Page$Profile$viewLoggedIn, profile, model, user),
				$author$project$Page$Profile$viewMobileHeader(model)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Profile$view = function (model) {
	return _Utils_Tuple2(
		model.ef,
		$author$project$Page$Profile$viewLoggedInOrGuest(model));
};
var $author$project$Page$Room$PageMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Room$viewLoggedIn = function (model) {
	var emptyMenu = A2($elm$html$Html$span, _List_Nil, _List_Nil);
	return A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
						A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim,
						A3(
						$author$project$Page$viewPageTitle,
						model.ef,
						$author$project$Type$Session$screenSize(model.d5),
						emptyMenu),
						A2($author$project$Page$viewTitle, model.bn.ef, emptyMenu)
					]))
			]));
};
var $author$project$Page$Room$viewLoggedInOrGuest = function (model) {
	var screenSize = $author$project$Type$Session$screenSize(model.d5);
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Room$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, $author$project$Route$Clubhouse, model.d5)),
				A2($elm$html$Html$Lazy$lazy, $author$project$Page$Room$viewLoggedIn, model)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Room$view = function (model) {
	return _Utils_Tuple2(
		model.ef,
		$author$project$Page$Room$viewLoggedInOrGuest(model));
};
var $author$project$Page$SetPacks$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$Buy$mobileModalStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'max-width', '300px'),
		A2($elm$html$Html$Attributes$style, 'padding', '0'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)'),
		$elm$html$Html$Attributes$class('animate__animated animate__slideInUp animate__faster br4 avenir')
	]);
var $author$project$Modal$Buy$Buy = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Modal$Buy$viewChoices = function (model) {
	var buyText = 'Buy for ' + ($elm$core$String$fromInt(model.ay.c0) + ' credits');
	var btnClasses = 'f4 pointer fw3';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pt2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ph2 pv3 fw6 f4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.ay.ef)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-10')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(btnClasses),
						$elm$html$Html$Events$onClick(
						A2($author$project$Modal$Buy$Buy, model.ay.di, model.a_)),
						A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue),
						A2($elm$html$Html$Attributes$style, 'padding', '12px 40px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(buyText)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-10')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(btnClasses),
						$elm$html$Html$Events$onClick($author$project$Modal$Buy$Close),
						A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue),
						A2($elm$html$Html$Attributes$style, 'padding', '12px 40px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Cancel')
					]))
			]));
};
var $author$project$Modal$Buy$viewBuyOption = function (model) {
	var viewMobileModal = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-center items-center ba h-100')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				$author$project$Modal$Buy$mobileModalStyles,
				_List_fromArray(
					[
						$author$project$Modal$Buy$viewChoices(model)
					]))
			]));
	var _v0 = model.cs;
	switch (_v0.$) {
		case 0:
			return viewMobileModal;
		case 1:
			return viewMobileModal;
		default:
			return viewMobileModal;
	}
};
var $author$project$Modal$Buy$toPx = function (num) {
	return $elm$core$String$fromInt(num) + 'px';
};
var $author$project$Modal$Buy$viewPrintFanOut = F5(
	function (model, width, height, index, tible) {
		var angle = $elm$core$String$fromFloat(1.5 * (1 + index));
		var transRotate = 'rotate(' + (angle + 'deg) translate(-50%, -50%) ');
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				$author$project$Type$Modal$cardStyle,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$Attributes$style,
						'width',
						$author$project$Modal$Buy$toPx(width)),
						A2(
						$elm$html$Html$Attributes$style,
						'height',
						$author$project$Modal$Buy$toPx(height)),
						A2($elm$html$Html$Attributes$style, 'transform', transRotate)
					])),
			_List_fromArray(
				[
					A2($author$project$View$Sticker$front, tible, _List_Nil)
				]));
	});
var $author$project$Modal$Buy$viewPrints = function (model) {
	var printsBehind = A2($elm$core$List$take, 2, model.aD);
	var activePrint = function () {
		var _v1 = model.aa;
		if (!_v1.$) {
			var printModalModel = _v1.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Modal$Buy$TibleModalMsg,
				$author$project$Modal$Tible$view(printModalModel));
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	var _v0 = $author$project$Type$Modal$toCardWidthHeight(model.cs);
	var cardWidth = _v0.a;
	var cardHeight = _v0.b;
	var fanOutItems = $elm$core$List$reverse(
		A2(
			$elm$core$List$indexedMap,
			A3($author$project$Modal$Buy$viewPrintFanOut, model, cardWidth, cardHeight),
			printsBehind));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_Utils_ap(
					fanOutItems,
					_List_fromArray(
						[activePrint])))
			]));
};
var $author$project$Page$viewSpinner = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('fixed w-100 tc'),
			A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue),
			A2($elm$html$Html$Attributes$style, 'top', '50%'),
			A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-50%)')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fas fa-spinner fa-10x fa-spin')
				]),
			_List_Nil)
		]));
var $author$project$Modal$Buy$view = function (model) {
	var _v0 = model.aC;
	switch (_v0) {
		case 0:
			return $author$project$Modal$Buy$viewBuyOption(model);
		case 1:
			return $author$project$Page$viewSpinner;
		default:
			return $author$project$Modal$Buy$viewPrints(model);
	}
};
var $author$project$Page$SetPacks$viewBuyModalOrNot = function (model) {
	var _v0 = model.D;
	if (!_v0.$) {
		var subModel = _v0.a;
		return A2(
			$author$project$Page$viewModalMask,
			model.cs,
			A2(
				$elm$html$Html$map,
				$author$project$Page$SetPacks$BuyModalMsg,
				$author$project$Modal$Buy$view(subModel)));
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Setting$maskBgColor = 'rgba(0,0,0,0.8)';
var $author$project$Page$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('absolute w-100 h-100'),
			A2($elm$html$Html$Attributes$style, 'top', '0'),
			A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Setting$maskBgColor)
		]),
	_List_fromArray(
		[$author$project$Page$viewSpinner]));
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {bM: decimals, b_: integers, b8: original, aO: prefix, aU: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.b_,
				$elm$core$List$singleton(formatted.bM))));
	return onlyZeros ? 1 : ((formatted.b8 < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.bM;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.bM;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = $cuducos$elm_format_number$FormatNumber$Parser$splitThousands(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{aO: locale.aM, aU: locale.bj});
			case 0:
				return _Utils_update(
					partial,
					{aO: locale.cd, aU: locale.ce});
			default:
				return _Utils_update(
					partial,
					{aO: locale.cM, aU: locale.cN});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.Q, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.V, formatted.b_);
		var decimals = stringfyDecimals(formatted.bM);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.aO, integers, decimals, formatted.aU]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$base = {
	Q: '.',
	bM: $cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	aM: '−',
	bj: '',
	cd: '',
	ce: '',
	V: '',
	cM: '',
	cN: ''
};
var $cuducos$elm_format_number$FormatNumber$Locales$usLocale = _Utils_update(
	$cuducos$elm_format_number$FormatNumber$Locales$base,
	{
		bM: $cuducos$elm_format_number$FormatNumber$Locales$Exact(2),
		V: ','
	});
var $author$project$Page$viewBackMenu = F2(
	function (title, link) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href(link),
					$elm$html$Html$Attributes$class('link dib flex justify-start items-end pv2 pl2 pointer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '1.2em'),
							A2($elm$html$Html$Attributes$style, 'height', '1.2em')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$chevronLeft($author$project$Setting$hiliteBlue)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pl1'),
							A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Page$SetPacks$viewMobileHeader = F2(
	function (model, credits) {
		var rightMenu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-column justify-center tr pv2 pr3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f6 tr'),
							A2($elm$html$Html$Attributes$style, 'color', $author$project$Setting$hiliteBlue)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(credits)
						]))
				]));
		var leftMenu = A2($author$project$Page$viewBackMenu, 'Market', '/market');
		return A4(
			$author$project$Page$viewMobileHeader,
			'Market',
			model.cs,
			0,
			_Utils_Tuple2(leftMenu, rightMenu));
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Page$SetPacks$OpenBuyModal = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$core$String$lines = _String_lines;
var $author$project$Page$SetPacks$viewEachPack = F4(
	function (model, user, set, pack) {
		var packNum = $elm$core$String$fromInt(pack.bI) + ' new randomly selected stickers for you';
		var titleAndImage = _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tl')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('f4 fw6')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(pack.ef)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('f5 fw2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(packNum)
							]))
					]))
			]);
		var oddDesc = A2(
			$elm$core$List$map,
			function (l) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f6 mb1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(l)
						]));
			},
			$elm$core$String$lines(pack.dK));
		var buyText = $elm$core$String$fromInt(pack.c0);
		var buyButton = function () {
			var _v0 = user.dw;
			if (!_v0.$) {
				var account = _v0.a;
				return A2(
					$author$project$Ox$Button$contained,
					buyText,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$Page$SetPacks$OpenBuyModal, pack, account)),
							A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(78, 192,227, 0.5)'),
							A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryDarkColor),
							A2($elm$html$Html$Attributes$style, 'padding-left', '20px'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '20px')
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var backgroundImageStr = 'url(' + (set.dj + ')');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex justify-between w-100 bb b--black-20 pb3'),
					A2($elm$html$Html$Attributes$style, 'gap', '20px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba flex items-center justify-center'),
									A2($elm$html$Html$Attributes$style, 'width', '140px'),
									A2($elm$html$Html$Attributes$style, 'height', '140px'),
									A2($elm$html$Html$Attributes$style, 'background-image', backgroundImageStr),
									A2($elm$html$Html$Attributes$style, 'background-size', 'cover')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fw6'),
											A2($elm$html$Html$Attributes$style, 'font-size', '64px')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(pack.bI))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pl3 br1 black tl')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex items-center')
										]),
									titleAndImage),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('f6 fw6')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Odds')
										])),
									A2($elm$html$Html$div, _List_Nil, oddDesc)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center jusitify-between')
						]),
					_List_fromArray(
						[buyButton]))
				]));
	});
var $author$project$Page$SetPacks$viewPacks = F3(
	function (model, user, set) {
		var coinsStr = function () {
			var _v0 = user.dw;
			if (!_v0.$) {
				var account = _v0.a;
				return 'Coins: ' + A2(
					$cuducos$elm_format_number$FormatNumber$format,
					_Utils_update(
						$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
						{
							bM: $cuducos$elm_format_number$FormatNumber$Locales$Exact(0)
						}),
					account.c0);
			} else {
				return 'Coins: 0';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tl mt3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex justify-between pb4')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Packs')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw6'),
									A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(coinsStr)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-wrap'),
							A2($elm$html$Html$Attributes$style, 'gap', '10px')
						]),
					A2(
						$elm$core$List$map,
						A3($author$project$Page$SetPacks$viewEachPack, model, user, set),
						model.bp))
				]));
	});
var $author$project$Page$SetPacks$viewSet = F3(
	function (model, user, set) {
		var variantStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				function (v) {
					return v.ef;
				},
				set.ek));
		var numberStr = $elm$core$String$fromInt(set.dt) + ' Stickers';
		var editionTypeStr = function () {
			var _v0 = set.c8;
			if (_v0 === 1) {
				return 'Limited Edition ';
			} else {
				return 'Standard Edition ';
			}
		}();
		var color = 'black';
		var borderRadius = '12px';
		var bgImageUrl = 'url(' + (set.dj + ')');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-column ma0 relative animate__animated animate__fadeIn animate__faster')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-column set')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'height', '240px'),
									$elm$html$Html$Attributes$class('ba'),
									A2($elm$html$Html$Attributes$style, 'border-radius', borderRadius),
									A2($elm$html$Html$Attributes$style, 'background-image', bgImageUrl),
									A2($elm$html$Html$Attributes$style, 'background-size', 'cover'),
									A2($elm$html$Html$Attributes$style, 'background-position', 'center')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tl mv3'),
									A2($elm$html$Html$Attributes$style, 'color', color)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mv1')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('f4 fw6 mb0')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(set.ef)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('f6 fw6 mv1 gray')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(editionTypeStr)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('f6 fw6 mv1 gray')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(numberStr)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tl mv2'),
									A2($elm$html$Html$Attributes$style, 'color', color)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('lh-copy f5 fw4')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(variantStr)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('lh-copy f5 fw4')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(set.c5)
										]))
								])),
							A3($author$project$Page$SetPacks$viewPacks, model, user, set)
						]))
				]));
	});
var $author$project$Page$SetPacks$viewSection = F3(
	function (model, user, set) {
		var tempCol = function () {
			var _v0 = model.cs;
			if (!_v0.$) {
				return 'repeat(auto-fill, minmax(290px, 1fr))';
			} else {
				return 'repeat(auto-fill, minmax(480px, 1fr))';
			}
		}();
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(' ma0 pv2 ph3')
				]),
			_List_fromArray(
				[
					A3($author$project$Page$SetPacks$viewSet, model, user, set)
				]));
	});
var $author$project$Page$SetPacks$viewMain = F3(
	function (model, user, set) {
		var emptyMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var coinsStr = function () {
			var _v0 = user.dw;
			if (!_v0.$) {
				var account = _v0.a;
				return 'Coins: ' + A2(
					$cuducos$elm_format_number$FormatNumber$format,
					_Utils_update(
						$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
						{
							bM: $cuducos$elm_format_number$FormatNumber$Locales$Exact(0)
						}),
					account.c0);
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pb5')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim,
							A3($author$project$Page$viewPageTitle, model.ef, model.cs, emptyMenu),
							A3($author$project$Page$SetPacks$viewSection, model, user, set),
							A2($author$project$Page$SetPacks$viewMobileHeader, model, coinsStr)
						]))
				]));
	});
var $author$project$Page$SetPacks$viewPacksOrLoading = function (model) {
	var _v0 = _Utils_Tuple2(model.d5, model.bg);
	if (_v0.b.$ === 1) {
		var _v1 = _v0.b;
		return _List_fromArray(
			[$author$project$Page$viewLoading]);
	} else {
		if (_v0.a.$ === 3) {
			var _v2 = _v0.a;
			var params = _v2.a;
			var user = _v2.b;
			var set = _v0.b.a;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					$author$project$Page$SetPacks$PageMsg,
					A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, $author$project$Route$Market, model.d5)),
					A4($elm$html$Html$Lazy$lazy3, $author$project$Page$SetPacks$viewMain, model, user, set),
					$author$project$Page$SetPacks$viewBuyModalOrNot(model)
				]);
		} else {
			return _List_Nil;
		}
	}
};
var $author$project$Page$SetPacks$view = function (model) {
	return _Utils_Tuple2(
		model.ef,
		$author$project$Page$SetPacks$viewPacksOrLoading(model));
};
var $author$project$Page$Stack$Delete = {$: 2};
var $author$project$Page$Stack$Edit = {$: 1};
var $author$project$Page$Stack$viewEditModal = function (model) {
	var _v0 = model.x;
	if (!_v0.$) {
		var editModalModel = _v0.a;
		return A2(
			$elm$html$Html$map,
			$author$project$Page$Stack$EditStackModalMsg,
			$author$project$Modal$Picker$view(editModalModel));
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Stack$viewTibleModal = function (model) {
	var _v0 = model.aa;
	if (!_v0.$) {
		var tibleModalModel = _v0.a;
		return A2(
			$author$project$Page$viewModalMask,
			model.cs,
			A2(
				$elm$html$Html$map,
				$author$project$Page$Stack$TibleModalMsg,
				$author$project$Modal$Tible$view(tibleModalModel)));
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Stack$viewModalOrNot = function (model) {
	return (!_Utils_eq(model.aa, $elm$core$Maybe$Nothing)) ? $author$project$Page$Stack$viewTibleModal(model) : ((!_Utils_eq(model.x, $elm$core$Maybe$Nothing)) ? $author$project$Page$Stack$viewEditModal(model) : A2($elm$html$Html$span, _List_Nil, _List_Nil));
};
var $author$project$Page$Stack$DragDropMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Stack$EnterItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Stack$LeaveItem = {$: 5};
var $author$project$Page$Stack$OpenTibleModal = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Type$Print$silloutteUrl = 'https://firebasestorage.googleapis.com/v0/b/cryptokitties-collecting-club.appspot.com/o/ck-silloutte.svg?alt=media&token=1189768f-9b96-4b20-9e40-0919f1b2b6a8';
var $author$project$Page$Stack$viewPrint = F3(
	function (_v0, index, thing) {
		switch (thing.$) {
			case 0:
				var tible = thing.a;
				return A2(
					$elm$html$Html$li,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Events$onMouseEnter(
								$author$project$Page$Stack$EnterItem(index)),
								$elm$html$Html$Events$onMouseLeave($author$project$Page$Stack$LeaveItem),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$Stack$OpenTibleModal, tible, index)),
								$elm$html$Html$Attributes$class('b--black-20 flex justify-between flex-column ma0 pv2 relative animate__animated animate__fadeIn animate__faster move')
							]),
						_Utils_ap(
							A2($norpan$elm_html5_drag_drop$Html5$DragDrop$draggable, $author$project$Page$Stack$DragDropMsg, index),
							A2($norpan$elm_html5_drag_drop$Html5$DragDrop$droppable, $author$project$Page$Stack$DragDropMsg, index))),
					_List_fromArray(
						[
							A2($author$project$View$Sticker$front, tible, _List_Nil)
						]));
			case 3:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('item'),
							A2($elm$html$Html$Attributes$style, 'display', 'hidden')
						]),
					_List_Nil);
			case 2:
				var order = thing.a;
				return A2(
					$elm$html$Html$li,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex items-end justify-center ma0 pa2 relative br3'),
								A2($elm$html$Html$Attributes$style, 'border', '2px dashed #aaa')
							]),
						A2($norpan$elm_html5_drag_drop$Html5$DragDrop$droppable, $author$project$Page$Stack$DragDropMsg, order)),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src($author$project$Type$Print$silloutteUrl),
									$elm$html$Html$Attributes$class('pt3 o-0')
								]),
							_List_Nil)
						]));
			default:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('item'),
							A2($elm$html$Html$Attributes$style, 'display', 'hidden')
						]),
					_List_Nil);
		}
	});
var $author$project$Page$Stack$viewDrawer = function (model) {
	var tempCol = function () {
		var _v0 = model.cs;
		switch (_v0.$) {
			case 0:
				return 'repeat(auto-fill, minmax(100px, 1fr))';
			case 1:
				return 'repeat(auto-fill, minmax(125px, 1fr))';
			default:
				return 'repeat(auto-fill, minmax(150px, 1fr))';
		}
	}();
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('bg-white br3 pt1 mb3 relative ph3 pb5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('list ma0 pa0'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', tempCol),
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-gap', '1em')
					]),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Page$Stack$viewPrint(model),
					model.cy)),
				$author$project$Page$Stack$viewModalOrNot(model)
			]));
};
var $author$project$Page$Stack$viewMobileHeader = function (model) {
	var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var leftMenu = A2($author$project$Page$viewBackMenu, 'My Room', '/home');
	return A4(
		$author$project$Page$viewMobileHeader,
		model.aB,
		model.cs,
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Page$Stack$viewMain = F2(
	function (model, _v0) {
		var menu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex justify-end')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center pointer gray pr3'),
							$elm$html$Html$Events$onClick($author$project$Page$Stack$Edit)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pr1')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('edit')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fas fa-pen fa-small')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center pointer gray pr3'),
							$elm$html$Html$Events$onClick($author$project$Page$Stack$Delete)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pr1')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('delete')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fas fa-trash')
								]),
							_List_Nil)
						]))
				]));
		return A2(
			$elm$html$Html$main_,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim,
							A3($author$project$Page$viewPageTitle, model.aB, model.cs, menu),
							$author$project$Page$Stack$viewDrawer(model),
							$author$project$Page$Stack$viewMobileHeader(model)
						]))
				]));
	});
var $author$project$Page$Stack$viewLoggedInOrGuest = function (model) {
	var _v0 = model.d5;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Stack$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$View$Navigation$view, params.d0, model.d5)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$Stack$viewMain, model, user)
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Stack$view = function (model) {
	return _Utils_Tuple2(
		model.ef,
		$author$project$Page$Stack$viewLoggedInOrGuest(model));
};
var $author$project$Page$viewError = function (code) {
	var message = function () {
		switch (code) {
			case 400:
				return 'Bad Request';
			case 401:
				return 'Unauthorized';
			case 402:
				return 'Payment Required';
			case 403:
				return 'Forbidden';
			case 404:
				return 'Not Found';
			default:
				return 'Oops! Something went wrong!';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pt5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f1 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(code))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]))
			]));
};
var $author$project$View$view = function (model) {
	var viewPage = F3(
		function (viewModel, pageView, toMsg) {
			var alertViews = $author$project$Ox$Alert$view(model.cQ);
			var newAlertViews = A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$Msg$AlertMsg),
				_List_fromArray(
					[alertViews]));
			var _v1 = pageView(viewModel);
			var title = _v1.a;
			var body = _v1.b;
			var newBody = A2(
				$elm$core$List$map,
				$elm$html$Html$map(toMsg),
				body);
			return {
				a1: _Utils_ap(newBody, newAlertViews),
				ef: title
			};
		});
	var _v0 = _Utils_Tuple2(model.d5, model.dQ);
	_v0$0:
	while (true) {
		switch (_v0.b.$) {
			case 2:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var getStarted = _v0.b.a;
					return A3(viewPage, getStarted, $author$project$Page$GetStarted$view, $author$project$Msg$GetStartedMsg);
				}
			case 0:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Home$view, $author$project$Msg$HomeMsg);
				}
			case 1:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Stack$view, $author$project$Msg$StackMsg);
				}
			case 3:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Collection$view, $author$project$Msg$CollectionMsg);
				}
			case 5:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Clubhouse$view, $author$project$Msg$ClubhouseMsg);
				}
			case 4:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Market$view, $author$project$Msg$MarketMsg);
				}
			case 6:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Room$view, $author$project$Msg$RoomMsg);
				}
			case 7:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Profile$view, $author$project$Msg$ProfileMsg);
				}
			case 8:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Message$view, $author$project$Msg$MessageMsg);
				}
			case 9:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$SetPacks$view, $author$project$Msg$SetPacksMsg);
				}
			default:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					return {
						a1: _List_fromArray(
							[
								$author$project$Page$viewError(404)
							]),
						ef: 'Not Found'
					};
				}
		}
	}
	return {
		a1: _List_fromArray(
			[$author$project$Page$viewLoading]),
		ef: 'tĭbles'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{dn: $author$project$Init$init, dL: $author$project$Msg$UrlChanged, dM: $author$project$Msg$LinkClicked, ed: $author$project$Subscriptions$subscriptions, eh: $author$project$Update$update, el: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));