import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './Js/serviceWorker';
import firebaseService from './Js/firebaseService';
import localService from './Js/localService';

var storageKey = "ccstore";
var storage = localStorage.getItem(storageKey);
var appId = "com.tibles.drseuss.testing";

var randomArray = new Uint32Array(1);
window.crypto.getRandomValues(randomArray);

let flags =
  { storage : storage
  , seed : randomArray[0]
  , appId : appId
  }

  
var app =
  Elm.Main.init({
  node: document.getElementById('root'),
  flags: flags
});

localService(app, storageKey);
firebaseService(app, appId);
serviceWorker.register();
